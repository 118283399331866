import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Label, Input, ModalBody } from "reactstrap";
import Icon from "react-icons-kit";
import { iosCloseEmpty, iosCheckmarkOutline } from "react-icons-kit/ionicons";
import { Formik } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import moment from "moment";
import _ from "lodash";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

/* IMPORT api_Req FOR API REQUEST */
import { api_Req, WithInterceptor } from "../../../common/services";
/* IMPORT actions FOR REDUX */
import { actions } from "../../../redux/actions/actions";

import { Button, Layout, PopUp, SortBar, Footer } from "../../../components";
import { inputData } from "../utilities/inputData";
import Error from "../utilities/errors";
import addnow from "../../../assets/icons/No-project.png";
import add from "../../../assets/icons/Add-project.png";
import unpin from "../../../assets/icons/Pin.png";
import pin from "../../../assets/icons/pin-highlight.png";
import upload from "../../../assets/icons/Upload-image.png";
import "./dashboard.scss";

const validationSchema = Yup.object().shape({
    projectName: Yup.string().required("Required"),
    clientName: Yup.string().required("Required"),
    companyName: Yup.string().required("Required"),
    maximumParticipant: Yup.number().required("Required").typeError('you must specify a number'),
    message: Yup.string().required("Required"),
    clientEmailAddress: Yup.string().email("Enter valid email").required("Required"),
    endDate: Yup.string().required("Required"),
});

const imageMaxSize = 220000000;
const acceptedFileTypes = "image/x-png, image/png, image/jpg, image/jpeg";

const sortByDropdown = [
    {
        label: "Most Recent",
        data: "createdTime,desc",
    },
    {
        label: "Pinned Project",
        data: "pinFlag,desc",
    },
    {
        label: "Active",
        data: "0",
    },
    {
        label: "Completed",
        data: "1",
    },
];

class AdminDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            focusedg: false,
            page: 0,
            sortDropdownVisible: false,
            sortBy: sortByDropdown[0],
            project: {},
            searchValue: "",
            uploadedImageSource: null,
            modal: false,
            successfullVisible: false,
            editPopupData: {},
            loading: false,
            currentActivePopupChangedPasswordFlag: {},
            invalidFileFormatErrorMessage: "",
        };
        this.loadMore = _.debounce(this.fetchWhichProjList, 300);
    }

    componentDidMount() {
        this.init();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    init = () => {
        this.getProfile();
        this.getProjectList();
    };

    handleScroll = () => {
        const { page, project } = this.state;
        const contentScrollView = document.getElementById("contentScrollView");
        if (this.isBottom(contentScrollView)) {
            this.setState(
                (prevState) => ({
                    page: prevState.page + 1,
                }),
                () => {
                    if (page >= project.totalPages) return;
                    this.loadMore();
                }
            );
        }
    };

    isBottom = (el) => {
        return window.innerHeight + Math.round(window.scrollY) >= el.scrollHeight;
    };

    getProfile = () => {
        api_Req.admin.get
            .profile()
            .then((resp) => this.props.setProfile(resp.data))
            .catch((err) => console.log(err));
    };

    getProjectList = (size, name) => {
        const { page, sortBy } = this.state;
        const sortData = sortBy.data;

        const params = {
            page: size ? 0 : page,
            size: size || 15,
        };

        if (name) params.name = name;
        if (sortData) params.sort = sortBy.data;

        api_Req.admin.get
            .projList(params)
            .then((resp) => {
                if (resp) {
                    if (size) {
                        this.setState({
                            project: resp.data,
                        });
                    } else {
                        // MERGE NEW AND OLD LIST
                        if (!resp.data.content) return;
                        this.setState((prevState) => ({
                            project: {
                                ...prevState.project,
                                ...resp.data,
                                content: prevState.project.content
                                    ? [...prevState.project.content, ...resp.data.content]
                                    : resp.data.content,
                            },
                        }));
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getCompletedProjList = (size, name) => {
        const { page, sortBy } = this.state;
        const params = {
            page: size ? 0 : page,
            size: size || 6,
            completed: sortBy.data,
        };

        if (name) params.name = name;

        api_Req.admin.get
            .projCompletedList(params)
            .then((resp) => {
                if (size) {
                    this.setState({
                        project: resp.data,
                    });
                } else {
                    this.setState((prevState) => ({
                        project: {
                            ...prevState.project,
                            ...resp.data,
                            content:
                                prevState.project.content && resp.data.content
                                    ? [...prevState.project.content, ...resp.data.content]
                                    : resp.data.content
                                    ? [...resp.data.content]
                                    : [],
                        },
                    }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    pinOrUnpinProj = (id) => {
        const { project } = this.state;
        const payload = { id };
        api_Req.admin.post
            .pinOrUnpin(payload)
            .then((resp) => {
                this.fetchWhichProjList(project.content.length);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    toggleSortingDropdown = (sortBy) => {
        const { sortBy: stateSortBy } = this.state;
        if (sortBy.data === stateSortBy.data) return;
        this.setState(
            (prevState) => ({
                sortDropdownVisible: !prevState.sortDropdownVisible,
                sortBy: sortBy,
                page: 0,
            }),
            () => {
                this.fetchWhichProjList(6);
            }
        );
    };

    fetchWhichProjList = (size, name) => {
        const { sortBy } = this.state;
        switch (sortBy.data) {
            case "0":
            case "1":
                this.getCompletedProjList(size, name);
                break;
            default:
                this.getProjectList(size, name);
                break;
        }
    };

    createProj = async (values, resetForm) => {
        this.setState({ invalidFileFormatErrorMessage: "" });
        const payload = {
            clientEmail: values.clientEmailAddress,
            clientName: values.clientName,
            companyName: values.companyName,
            maximumParticipant: values.maximumParticipant,
            message: values.message,
            projectName: values.projectName,
            endDate: moment(values.endDate._d).format("YYYY-MM-DD"),
            startDate: moment(values.startDate._d).format("YYYY-MM-DD"),
        };

        if (!values.projImage) {
            console.log("yes");
            payload.photoUrl = process.env.PUBLIC_URL + "no-photo-default.png";
        } else {
            try {
                const resp = await this.uploadProjImg(values.projImage);
                const projImageUrl = await this.getProjImageUrl(resp.data.id);
                payload.photoUrl = projImageUrl;
            } catch (err) {
                this.setState({
                    invalidFileFormatErrorMessage:
                        "You have uploaded an invalid image file type. ( Only jpg/jpeg, png, gif and svg files are allowed! )",
                });
            }
        }
        // payload.photoUrl = projImageUrl;
        api_Req.admin.post
            .createProj(payload)
            .then((resp) => {
                this.setState(
                    (prevState) => ({
                        successfullVisible: !prevState.successfullVisible,
                        modal: !prevState.modal,
                    }),
                    () => {
                        this.setState({ invalidFileFormatErrorMessage: "" });
                        this.fetchWhichProjList(6);
                        setTimeout(() => {
                            this.setState({
                                successfullVisible: false,
                            });
                        }, 3000);
                    }
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    editProj = async (values, resetForm) => {
        this.setState({ invalidFileFormatErrorMessage: "" });
        console.log(values.message, "alues.message");
        const { project } = this.state;
        const payload = {
            id: this.state.editPopupData && this.state.editPopupData.id,
            clientEmail: values.clientEmailAddress,
            clientName: values.clientName,
            companyName: values.companyName,
            maximumParticipant: values.maximumParticipant,
            message: values.message,
            projectName: values.projectName,
            endDate: moment(values.endDate._d).format("YYYY-MM-DD"),
            startDate: moment(values.startDate._d).format("YYYY-MM-DD"),
        };

        if (this.state.uploadedImageSource === this.state.editPopupData.photoUrl) {
            payload.photoUrl = this.state.editPopupData && this.state.editPopupData.photoUrl;
        } else {
            try {
                const resp = await this.uploadProjImg(values.projImage);
                const projImageUrl = await this.getProjImageUrl(resp.data.id);
                payload.photoUrl = projImageUrl;
            }
            catch(err) {
                this.setState({
                    invalidFileFormatErrorMessage:
                        "You have uploaded an invalid image file type. ( Only jpg/jpeg, png, gif and svg files are allowed! )",
                });
            }
        }
        console.log(payload, "payload");
        api_Req.admin.post
            .editProj(payload)
            .then((resp) => {
                this.fetchWhichProjList(project.content.length);
                this.setState(
                    (prevState) => ({
                        successfullVisible: !prevState.successfullVisible,
                        modal: !prevState.modal,
                    }),
                    () => {
                        this.setState({ invalidFileFormatErrorMessage: "" });
                        setTimeout(() => {
                            this.setState({
                                successfullVisible: false,
                            });
                        }, 3000);
                    }
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };

    uploadProjImg = async (file, callback) => {
        console.log(file, "file");
        const formData = new FormData();
        formData.append("file", file[0]);
        try {
            return await api_Req.admin.post.uploadFile(formData);
        } catch (err) {
            console.log(err);
        }
    };

    getProjImageUrl = async (projId) => {
        try {
            return await api_Req.admin.get.projImageUrl(projId);
        } catch (err) {
            console.log(err);
        }
    };

    toggle = (type, projectContent) => {
        switch (type) {
            case "edit":
                this.setState(
                    {
                        type: type,
                        editPopupData: projectContent,
                        uploadedImageSource: projectContent && projectContent.photoUrl,
                    },
                    () => {
                        this.setState((prevState) => ({
                            modal: !prevState.modal,
                        }));
                    }
                );

                break;

            case "add":
                this.setState(
                    {
                        type: type,
                        editPopupData: projectContent,
                        uploadedImageSource: projectContent && projectContent.photoUrl,
                    },
                    () => {
                        this.setState((prevState) => ({
                            modal: !prevState.modal,
                        }));
                    }
                );

                break;
            default:
                this.setState((prevState) => ({
                    modal: !prevState.modal,
                }));

                break;
        }
    };

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div
                style={{
                    marginRight: 7,

                    marginTop: -3,
                }}
            >
                <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
                    {moment.monthsShort().map((label, value) => (
                        <option value={value}>{label} </option>
                    ))}
                </select>
            </div>
            <div
                style={{
                    marginRight: 7,

                    marginTop: -3,
                }}
            >
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                    <option value={moment().year()}>{moment().year()}</option>
                    <option value={moment().year() + 1}>{moment().year() + 1}</option>
                    <option value={moment().year() + 2}>{moment().year() + 2}</option>
                    <option value={moment().year() + 3}>{moment().year() + 3}</option>
                    <option value={moment().year() + 4}>{moment().year() + 4}</option>
                    <option value={moment().year() + 5}>{moment().year() + 5}</option>
                    <option value={moment().year() + 6}>{moment().year() + 6}</option>
                </select>
            </div>
        </div>
    );
    handleOnDrop = (files, setFieldValue) => {
        if (files && files.length > 0) {
            const currentFile = files[0];
            const imageReader = new FileReader();
            imageReader.addEventListener(
                "load",
                () => {
                    this.setState({
                        uploadedImageSource: imageReader.result,
                    });
                    setFieldValue("projImage", files);
                },
                false
            );
            imageReader.readAsDataURL(currentFile);
        }
    };

    handleSearch = (val, e) => {
        const { project } = this.state;
        switch (e) {
            case "Enter":
                this.fetchWhichProjList(project.content.length, val);
                break;
            case "Backspace":
                if (val.length <= 1) {
                    this.fetchWhichProjList(6);
                }
                break;
            default:
                return;
        }
    };

    render() {
        const { uploadedImageSource, successfullVisible, project, editPopupData } = this.state;

        const editData = {
            projectName: editPopupData && editPopupData.projectName,
            clientName: editPopupData && editPopupData.clientName,
            companyName: editPopupData && editPopupData.companyName,
            maximumParticipant: editPopupData && editPopupData.maximumParticipant,
            message: editPopupData && editPopupData.message,
            clientEmailAddress: editPopupData && editPopupData.clientEmail,
            startDate: moment(editPopupData && editPopupData.startDate),
            endDate: moment(editPopupData && editPopupData.endDate),
            projImage: editPopupData && editPopupData.photoUrl,
        };
        return (
            <Layout disabledFooter>
                <div className="admin-dashboard-container">
                    <div className="admin-header">
                        <SortBar
                            dropdownData={sortByDropdown}
                            setDropdownValue={this.toggleSortingDropdown}
                            handleSearchKeyDown={this.handleSearch}
                            disabled={!project}
                        />
                        <img src={add} className="add-icon" onClick={() => this.toggle("add")} alt="add-icon" />
                    </div>
                    <div className="projects-container">
                        {!_.isEmpty(project) &&
                            project.content.map((o, i) => {
                                const startDate = o.startDate.split("-").reverse().join("-");
                                const endDate = o.endDate.split("-").reverse().join("-");
                                return (
                                    <Card
                                        key={`${o.id}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.toggle("edit", project.content[i]);
                                            this.setState({
                                                currentActivePopupChangedPasswordFlag: o.clientChangedPassword,
                                            });
                                        }}
                                    >
                                        <CardBody className="card-body project-body">
                                            <div className="project-content">
                                                <div
                                                    className="sime-img"
                                                    style={{ backgroundImage: `url(${o.photoUrl})` }}
                                                />
                                                <div className="project-description">
                                                    <p>{o.projectName}</p>
                                                    <p>{o.companyName}</p>
                                                    <p>{o.id}</p>
                                                    <p>
                                                        <span>{startDate} - </span>
                                                        <span>{endDate}</span>
                                                        <span
                                                            style={{
                                                                color: !o.isOverallCompleted ? "green" : "gray",
                                                            }}
                                                        >
                                                            {!o.isOverallCompleted ? "ACTIVE" : "COMPLETED"}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div
                                                    className={o.pinFlag ? "icon-container" : "icon-container-unpin"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        this.pinOrUnpinProj(o.id);
                                                    }}
                                                >
                                                    <img
                                                        alt="pin"
                                                        src={o.pinFlag ? pin : unpin}
                                                        className={o.pinFlag ? "pin-img" : "unpin-img"}
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                    </div>
                    {_.isEmpty(project) ? (
                        <div className="add-button">
                            <img src={addnow} className="add-img" alt="add-img" />
                            <p>No projects yet</p>
                            <Button title="Add Now" onClick={() => this.toggle("add")} />
                        </div>
                    ) : null}
                    <PopUp visible={successfullVisible} className="sucess-pop-up">
                        <Icon icon={iosCheckmarkOutline} size={45} style={{ color: "#8bc34a" }} />
                        <p>Project Successfully Added!</p>
                        <Icon
                            icon={iosCloseEmpty}
                            size={40}
                            className="close-icon"
                            onClick={() => {
                                this.setState({
                                    successfullVisible: false,
                                });
                            }}
                        />
                    </PopUp>
                    <PopUp
                        className="admin-dashboard-modal"
                        visible={this.state.modal}
                        toggle={this.toggle}
                        onClosed={() => this.setState({ currentActivePopupChangedPasswordFlag: 0 })}
                    >
                        {this.state.type === "edit" ? (
                            <div className="edit-popup-header">
                                <span>Edit Project</span>{" "}
                                <span>Project ID :{this.state.editPopupData && this.state.editPopupData.id}</span>
                            </div>
                        ) : (
                            <div className="popup-header">
                                <p>Add Project</p>
                            </div>
                        )}

                        <ModalBody className="modal-body">
                            <Formik
                                initialValues={
                                    this.state.type === "edit"
                                        ? editData
                                        : {
                                              projectName: "",
                                              clientName: "",
                                              companyName: "",
                                              maximumParticipant: "",
                                              clientEmailAddress: "",
                                              startDate: moment(),
                                              endDate: "",
                                              message: "",
                                          }
                                }
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setSubmitting(false);
                                    switch (this.state.type) {
                                        case "add":
                                            this.createProj(values, resetForm);
                                            break;
                                        case "edit":
                                            this.editProj(values, resetForm);
                                            break;
                                    }
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        {inputData.map((o, i) => {
                                            return (
                                                <div className="popUp-content" key={`popup${o.key}`}>
                                                    <Label>{o.label}</Label>
                                                    {o.name === "startDate" ? (
                                                        <div className="dateRangePicker-container">
                                                            <SingleDatePicker
                                                                renderMonthElement={this.renderMonthElement}
                                                                placeholder={moment()}
                                                                required
                                                                orientation={
                                                                    window.innerWidth < 768
                                                                        ? "verticalScrollable"
                                                                        : "horizontal"
                                                                }
                                                                id="startDate"
                                                                date={values.startDate}
                                                                onDateChange={(date) => setFieldValue("startDate", date)}
                                                                focusedInput={this.state.focusede} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired,
                                                                disabled={this.state.type === "edit" && "startDate"}
                                                                focused={this.state.focused}
                                                                displayFormat="DD MMM YYYY"
                                                                hideKeyboardShortcutsPanel
                                                                customArrowIcon="-"
                                                                noBorder={true}
                                                                minimumNights={0}
                                                                horizontalMargin={window.innerWidth > 768 ? 70 : 10}
                                                                numberOfMonths={1}
                                                            />
                                                            <div classname="dash">–</div>
                                                        </div>
                                                    ) : o.name === "endDate" ? (
                                                        <div className="dateRangePicker-container">
                                                            <SingleDatePicker
                                                                renderMonthElement={this.renderMonthElement}
                                                                placeholder={"Please select"}
                                                                required
                                                                orientation={
                                                                    window.innerWidth < 768
                                                                        ? "verticalScrollable"
                                                                        : "horizontal"
                                                                }
                                                                id="endDate"
                                                                date={values.endDate}
                                                                onDateChange={(date) => setFieldValue("endDate", date)}
                                                                focusedInput={this.state.focusedg} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                                onFocusChange={({ focused }) =>
                                                                    this.setState({ focusedg: focused })
                                                                }
                                                                focused={this.state.focusedg}
                                                                displayFormat="DD MMM YYYY"
                                                                hideKeyboardShortcutsPanel
                                                                customArrowIcon="-"
                                                                noBorder={true}
                                                                minimumNights={0}
                                                                horizontalMargin={window.innerWidth > 768 ? 70 : 10}
                                                                numberOfMonths={1}
                                                            />
                                                        </div>
                                                    ) : o.name === "message" ? (
                                                        <div className="input-error-container">
                                                            <textarea
                                                                style={{
                                                                    width: "100%",
                                                                    border: "solid 1px rgba(103, 103, 103, 0.3)",
                                                                    height: 100,
                                                                }}
                                                                name={o.name}
                                                                key={o.key}
                                                                placeholder={o.placeholder}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values[o.name]}
                                                                className={
                                                                    touched[o.name] && errors[o.name] ? "has-error" : null
                                                                }
                                                            />
                                                            <Error touched={touched[o.name]} message={errors[o.name]} />
                                                        </div>
                                                    ) : (
                                                        <div className="input-error-container">
                                                            <Input
                                                                disabled={
                                                                    o.name === "clientEmailAddress" &&
                                                                    this.state.currentActivePopupChangedPasswordFlag === 1
                                                                }
                                                                name={o.name}
                                                                key={o.key}
                                                                placeholder={o.placeholder}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values[o.name]}
                                                                className={
                                                                    touched[o.name] && errors[o.name] ? "has-error" : null
                                                                }
                                                            />
                                                            <Error touched={touched[o.name]} message={errors[o.name]} />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                        <div className="upload-image-container">
                                            <p>Project Icon (Optional)</p>

                                            <Dropzone
                                                onDrop={(file) => this.handleOnDrop(file, setFieldValue)}
                                                maxSize={imageMaxSize}
                                                multiple={false}
                                                accept={acceptedFileTypes}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div className="drag-drop-container" {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <div
                                                                className="image-container"
                                                                style={{
                                                                    border: !uploadedImageSource && "none",
                                                                }}
                                                            >
                                                                <img
                                                                    alt="upload-box"
                                                                    className="image-box"
                                                                    src={uploadedImageSource ? uploadedImageSource : upload}
                                                                />
                                                            </div>
                                                            {!uploadedImageSource ? (
                                                                <p>Drag & drop an image</p>
                                                            ) : (
                                                                <Fragment>
                                                                    <p style={{ color: "red" }}>
                                                                        {this.state.invalidFileFormatErrorMessage}
                                                                    </p>
                                                                    <div style={{ height: 20 }} />
                                                                </Fragment>
                                                            )}
                                                            <Button
                                                                outline
                                                                type="button"
                                                                className="default-button"
                                                                title="Choose file"
                                                            >
                                                                <p>Upload Icon</p>
                                                            </Button>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>

                                        <div className="popUp-button-container">
                                            <Button title="SAVE" type="submit" disabled={isSubmitting} />
                                            <Button outline title="CANCEL" type="button" onClick={this.toggle} />
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </ModalBody>
                    </PopUp>
                    <Footer />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.userProfileReducer.profile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setProfile: (data) => {
        dispatch({ type: actions.SET_USER_PROFILE, data });
    },
    alertStatus: (data) => dispatch({ type: actions.TOGGLE_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(AdminDashboardPage));
