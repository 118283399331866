export const actions = {
  /* ALERT */
  TOGGLE_ALERT: "TOGGLE_ALERT",
  HIDE_ALERT: "HIDE_ALERT",

  /* LOGIN */
  INIT_USER_PROFILE: "INIT_USER_PROFILE",
  FETCH_USER_PROFILE: "FETCH_USER_PROFILE",
  SET_USER_PROFILE_AUTH: "SET_USER_PROFILE_AUTH",
  SET_USER_PROFILE: "SET_USER_PROFILE",

  /* CLIENT */
  CLIENT_SET_PROJ_DETAILS: "SET_PROJ_DETAILS",

  /* PARTICIPANT */
  INIT_ADD_RATERS: "INIT_ADD_RATERS",
  SET_PROJ_DETAILS: "SET_PROJ_DETAILS",
  SET_PROJ_DETAILS_RATESELF:"SET_PROJ_DETAILS_RATESELF",
  SET_RATERS: "SET_RATERS",
  SET_COMPENTENCY: "SET_COMPENTENCY",
  SELECT_COMPLETED_RESULT: "SELECT_COMPLETED_RESULT",
  SET_COMPLETED_RESULT: "SET_COMPLETED_RESULT",

  /* RATER */
  INIT_RATER_RATING: "INIT_RATER_RATING",
  RATER_SELECT_PROJ: "RATER_SELECT_PROJ",

  /* LOGOUT */
  LOGOUT: "LOGOUT"
};
