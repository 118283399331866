import React, { Fragment, useEffect, useState } from "react";
import { Card } from "reactstrap";
import { HorizontalBar } from "react-chartjs-2";
import _ from "lodash";
import { connect } from "react-redux";

import { actions } from "../../../redux/actions/actions";
import {
  Button,
  BreadCrumb,
  Layout,
  TabSwitch,
  Table
} from "../../../components";
import { api_Req, WithInterceptor } from "../../../common/services";
import "./result-chart-list.scss";

export const gapData = {
  graphs: [
    {
      name: "comp 1",
      belowTargetScore: 5,
      competencies: [
        {
          targetLevel: 3,
          gapScores: [
            { gapScore: 2, noOfParticipants: 4 },
            { gapScore: 3, noOfParticipants: 5 },
            { gapScore: 4, noOfParticipants: 5 }
          ]
        },
        {
          targetLevel: 5,
          gapScores: [
            { gapScore: 2, noOfParticipants: 4 },
            { gapScore: 3, noOfParticipants: 5 }
          ]
        }
      ]
    },
    {
      name: "comp 2",
      belowTargetScore: 4,
      competencies: [
        {
          targetLevel: 4,
          gapScores: [
            { gapScore: 2, noOfParticipants: 4 },
            { gapScore: 3, noOfParticipants: 5 }
          ]
        },
        {
          targetLevel: 5,
          gapScores: [
            { gapScore: 2, noOfParticipants: 4 },
            { gapScore: 3, noOfParticipants: 5 }
          ]
        }
      ]
    },
    {
      name: "comp 3",
      belowTargetScore: 4,
      competencies: [
        {
          targetLevel: 4,
          gapScores: [
            { gapScore: 2, noOfParticipants: 4 },
            { gapScore: 3, noOfParticipants: 5 }
          ]
        },
        {
          targetLevel: 6,
          gapScores: [
            { gapScore: 2, noOfParticipants: 4 },
            { gapScore: 3, noOfParticipants: 5 }
          ]
        }
      ]
    }
  ]
};

export const dataTest = {
  totalRater: 5,
  maxScale: 6,

  competencyModel: [
    {
      name: "Leadership",
      average: 5.5,
      selfRating: 4,
      belowTarget: 3,
      raters: [
        { name: "boss", averageRating: 4 },
        { name: "Direct report", averageRating: 4 },
        { name: "Peer", averageRating: 3 },
        { name: "Others", averageRating: 4 }
      ]
    },
    {
      name: "Communication",
      average: 5,
      selfRating: 4,
      belowTarget: 4,
      raters: [
        { name: "boss", averageRating: 4 },
        { name: "Direct report", averageRating: 4 },
        { name: "Peer", averageRating: 4 },
        { name: "Others", averageRating: 4 }
      ]
    }
  ]
};

export const tHeader = [
  {
    label: "Name",
    key: "name"
  },
  {
    label: "gapScores",
    key: "gapScores"
  }
];

export const tHeaderAverageTab = [
  {
    label: "CompetencyName",
    key: "competencyName"
  },
  {
    label: "Average Scores",
    key: "averageScore"
  }
];
export const tBody = [
  {
    id: "05382f8b-5ac1-4474-b148-c581b8186743",
    name: "a name",
    gapScores: [
      { gapScore: 0, noOfParticipants: 3 },
      { gapScore: 1, noOfParticipants: 3 },
      { gapScore: 2, noOfParticipants: 3 },
      { gapScore: 3, noOfParticipants: 3 },
      { gapScore: 4, noOfParticipants: 3 },
      { gapScore: 5, noOfParticipants: 3 }
    ]
  },
  {
    id: "05382f8b-5ac1-4474-b148-c581b8186743",
    name: "b name",
    gapScores: [
      { gapScore: 0, noOfParticipants: 3 },
      { gapScore: 1, noOfParticipants: 3 },
      { gapScore: 2, noOfParticipants: 1 },
      { gapScore: 3, noOfParticipants: 1 },
      { gapScore: 4, noOfParticipants: 3 },
      { gapScore: 5, noOfParticipants: 1 }
    ]
  }
];

const InitialBreadCrumbData = [
  {
    title: "Project",
    routes: "dashboard"
  },
  {
    title: "Organisation Name",
    routes: "back"
  },
  {
    title: "",
    routes: "",
    active: true
  }
];

export const ClientResultChartListPage = ({
  history,
  resultDetails,

  projDetails,
  ...props
}) => {
  const historyState = history.location.state;
  const [current, setCurrent] = useState(1);
  const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);
  const [result, setResult] = useState();
  const [clientProjectResultData, setClientProjectResultData] = useState();
  const [gapResultRespData, setGapResultRespData] = useState();

  const [currentActiveTab, setCurrentActiveTab] = useState(0); // assuming 0 = participants ,1=competency modal tab

  useEffect(() => {
    if (!historyState) history.push("/dashboard");
    const breadCrumb = [...BreadCrumbData];
    breadCrumb[1].title = historyState.projectName;
    breadCrumb[2].title = `Results`;
    setBreadCrumbData(breadCrumb);
  }, []);

  // const addedAverageToRespData =
  //   clientProjectResultData &&
  //   clientProjectResultData.data.competencies.map(item => ({
  //     averageScore: `${item.score} / ${item.maxScore}`,
  //     competencyName: item.competencyName
  //   }));

  const getProjectGap = () => {
    const params = historyState.project.id;
    console.log(params, "params");
    api_Req.user.get
      .clientProjectResultGap(params)
      .then(resp => {
        setGapResultRespData(resp.data);
        console.log(resp, "resp");
      })
      .catch(err => {
        alert(err);
      });
  };
  const getProjectBarAverageResult = () => {
    const params = historyState.project.id;
    console.log(params, "params");
    api_Req.user.get
      .clientProjectResult(params)
      .then(resp => {
        console.log(resp, "resp2");
        setClientProjectResultData(resp.data);
      })
      .catch(err => {
        alert(err);
      });
  };

  useEffect(() => {
    if (!historyState) history.push("/dashboard");
    const breadCrumb = [...BreadCrumbData];
    breadCrumb[1].title = historyState.project.projectName;
    setBreadCrumbData(breadCrumb);
    getProjectGap();
    getProjectBarAverageResult();
  }, []);

  const toggleActiveTab = currentActiveTab => {
    setCurrentActiveTab(currentActiveTab);
  };

  // const competencyNameArray =
  //   clientProjectResultData &&
  //   clientProjectResultData.data.competencies.map(item => item.competencyName);

  // const numberOfGapRows = [
  //   ...Array(gapResultRespData && gapResultRespData.maxLevel).keys()
  // ];

  return (
    <Layout>
      <div className="client-chart-list-container">
        <div className="header-container">
          <BreadCrumb data={InitialBreadCrumbData} />
          <Button title="Export" onClick={window.print} />
        </div>
        <TabSwitch
          displayCurrentActiveTab={toggleActiveTab}
          customTabName={["Average Result", "Gap"]}
        />
        <Card>
          {currentActiveTab === 0 ? (
            clientProjectResultData && (
              <Fragment>
                <div className="graph-legend-row">
                  <div className="left">
                    <p>
                      Total of participants:{" "}
                      {clientProjectResultData.totalParticipants}
                    </p>
                    <p>
                      Total of raters: {clientProjectResultData.totalRaters}
                    </p>
                  </div>
                  <div className="header">
                    <div className="legend-green" />
                    <p>At or above target score</p>
                    <div className="legend-red" />
                    <p>Below target score</p>
                    <div className="legend-dotted" />
                    <p>Target Rating</p>
                    <div className="legend-diamond" />
                    <p>Self Rating</p>
                  </div>
                </div>
                <div className="card-row">
                  <div className="graph-component">
                    <div className="header">
                      <div className="row1">
                        {" "}
                        <div className="left">
                          {" "}
                          <p>No.</p> <p>Competency Model</p>
                          <p>Raters</p>
                          <p>Average Rating</p>{" "}
                        </div>
                        <div className="right">Ratings Scale</div>{" "}
                      </div>
                      <div className="row2">
                        <div className="left"></div>
                        <div className="right">
                          {[
                            ...Array(
                              clientProjectResultData.maxScore + 1
                            ).keys()
                          ]
                            // .slice(1)
                            .map((level, index) => {
                              return <p>{index }</p>;
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="graph-table">
                      {clientProjectResultData.competencies.length > 0 &&
                        clientProjectResultData.competencies.map(
                          (tableRow, indexx) => {
                            return (
                              <div className="table-row">
                                <div className="table-left">
                                  <p>{indexx + 1}</p> <p>{tableRow.name}</p>{" "}
                                  <p>
                                    <p className="rater-average">Average</p>
                                    {tableRow.raters.map((raters, indexxx) => {
                                      return (
                                        <p className="raters">
                                          {raters.roleName}
                                        </p>
                                      );
                                    })}
                                  </p>
                                  <p>
                                    <p
                                      style={{
                                        color:
                                          tableRow.averageScore <
                                          tableRow.averageTarget
                                            ? "#F05751"
                                            : "black"
                                      }}
                                      className="rater-average"
                                    >
                                      {tableRow.averageScore}
                                    </p>
                                    {tableRow.raters.map(
                                      (raters, indexxxxx) => {
                                        return (
                                          <p
                                            className="raters"
                                            style={{
                                              color:
                                                raters.averageRating <
                                                tableRow.averageTarget
                                                  ? "#F05751"
                                                  : "black"
                                            }}
                                          >
                                            {raters.averageRating}
                                          </p>
                                        );
                                      }
                                    )}
                                  </p>
                                </div>
                                <div className="table-right">
                                  <div
                                    className="dotted-below-target"
                                    style={{
                                      width: `calc( ${tableRow.averageTarget}/
                                         ${clientProjectResultData.maxScore+1} *
                                        100%)`
                                    }}
                                  />
                                  <p
                                    className="logo-rater-average"
                                    style={{
                                      left: `calc( ${tableRow.averageScore}/
                                         ${clientProjectResultData.maxScore+1} *
                                        100% )`,
                                      marginLeft: "-5px"
                                    }}
                                  >
                                    {" "}
                                  </p>
                                  <p
                                    className="graph"
                                    style={{
                                      // position: "relative",
                                      backgroundColor:
                                        tableRow.averageScore >=
                                        tableRow.averageTarget
                                          ? "#91BE5E"
                                          : "#F05751",

                                      width: `calc( ${tableRow.averageScore}/
                                         ${clientProjectResultData.maxScore+1} *
                                        100%)`
                                    }}
                                  >
                                    {/* {tableRow.average} */}
                                  </p>
                                  {tableRow.raters.map((raters, indexxxx) => {
                                    return (
                                      <p
                                        className="graph"
                                        style={{
                                          backgroundColor:
                                            raters.averageRating >=
                                            tableRow.averageTarget
                                              ? "#91BE5E"
                                              : "#F05751",
                                          // width:
                                          //   (raters.averageRating /
                                          //     dataTest.maxScale) *
                                          //   0.5
                                          width: `calc( ${raters.averageRating}/
                                         ${clientProjectResultData.maxScore+1} *
                                        100%)`
                                        }}
                                      >
                                        {/* {raters.averageRating} */}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        )}{" "}
                    </div>
                  </div>

                  {/* 
                  averageTarget=averagescore
                  targetArray=scoretarget
                

                scoreArray=selfrating */}
                </div>
              </Fragment>
            )
          ) : (
            <Fragment>
              <div className="header-gap">
                <div className="legend-black" />
                <p>Target Level</p>
                <div className="legend-purple" />
                <p>Gap</p>
                <div className="legend-orange" />
                <p>No. Of Participants</p>
              </div>
              <div className="graph-card-container">
                {gapResultRespData.map((graph, index) => {
                  return (
                    graph.competencies.length > 0 && (
                      <Card className="graph-card">
                        <div className="graph-card-header">
                          <p style={{ fontWeight: 500 }}>
                            {index + 1}){graph.competencyName}
                          </p>
                          <p style={{ display: "flex", flexDirection: "row" }}>
                            Below target score:
                            <p style={{ color: "#c49c5e", marginLeft: 4 }}>
                              {graph.belowTargetScore}
                            </p>
                          </p>
                        </div>
                        <div className="gap-table">
                          <div className="header">
                            <div className="header-left">Target Level</div>
                            <div className="header-right">
                              <div className="header-right-col1">Gap</div>
                              <div className="header-right-col2">
                                {[...Array(7).keys()].map((row, index) => {
                                  return (
                                    <div
                                      key={`${index}_gap_row`}
                                      className="header-right-col2-row"
                                    >
                                      {row}
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="header-right-col3">
                                No. Of Participants
                              </div>
                            </div>
                          </div>
                          <div className="table-body">
                            {// gapData.length > 0 &&
                            graph.competencies.map((row, indexi) => {
                              const smallestGapScore = _.minBy(
                                row.gapScores,
                                "gapScore"
                              ).gapScore;
                              const biggestGapScore = _.maxBy(
                                row.gapScores,
                                "gapScore"
                              ).gapScore;
                              const beforefiller = new Array(
                                smallestGapScore
                              ).fill({});
                              const afterFiller = new Array(
                                6 - biggestGapScore
                              ).fill({});

                              const afterFilledConcat = beforefiller.concat(
                                row.gapScores
                              );
                              const fullyfilledAndArrangedTargetGaps = afterFilledConcat.concat(
                                afterFiller
                              );
                              return (
                                <div
                                  className="competency-col"
                                  key={`${indexi}_gap_roww`}
                                >
                                  <div className="competency-model-row">
                                    {row.targetLevel}
                                  </div>

                                  <div className="gaps-row">
                                    {fullyfilledAndArrangedTargetGaps.map(
                                      (gapRows, indexx) => {
                                        return (
                                          <div
                                            className="gaps"
                                            key={`${indexx}_gap_rowwww`}
                                          >
                                            {gapRows.gapScore
                                              ? gapRows.noOfParticipants
                                              : "-"}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* </div> */}
                      </Card>
                    )
                  );
                })}
              </div>
            </Fragment>
          )}
          {/* ) : null} */}
        </Card>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    projDetails: state.clientReducer.projDetails
  };
};

const mapDispatchToProps = dispatch => ({
  alertStatus: data => dispatch({ type: actions.TOGGLE_ALERT, data })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithInterceptor(ClientResultChartListPage));
