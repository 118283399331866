import React, { useEffect } from "react";
import { connect } from "react-redux";
import { TopBar, Footer } from "..";
import "./layout.scss";

const Layout = (props) => {
    const bpmbTheme = (props.userProfile.profile && props.userProfile.profile.themeId === 1) || props.theme === 1;
    useEffect(() => {
        const content = document.getElementById("contentScrollView");
        const footer = document.getElementById("copyright-footer");
        const tab = document.getElementsByClassName("tab-content");

        if (tab[0]) {
            if (props.disabledFooter && tab[0].offsetHeight <= window.innerHeight) {
                footer.classList.add("fixed");
                return;
            }
        }

        if (content.firstElementChild.offsetHeight <= window.innerHeight - 85) {
            footer.classList.add("fixed");
            return;
        }

        footer.classList.remove("fixed");
    });

    return (
        <div className={bpmbTheme ? "bpmb-theme main-container" : "main-container"}>
            <TopBar theme={bpmbTheme ? "bpmb-theme" : ""} />
            <div className="content" id="contentScrollView">
                {props.children}
                {!props.disabledFooter ? <Footer /> : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfileReducer,
    };
};

export const LayoutComponent = connect(mapStateToProps, null)(Layout);
