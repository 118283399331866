import axios from "axios";
import { Store } from "../../redux/store/store";

export const axios_Services = {
  get: (url, params = {}, config = {}) => {
    return axios.get(url, { params: params, ...getHeaders(), ...config });
  },
  post: (url, body = {}, config = {}) => {
    return axios.post(url, body, getHeaders(config));
  },
  put: (url, body = {}) => {
    return axios.put(url, body, getHeaders());
  },
  patch: (url, body = {}) => {
    return axios.patch(url, body, getHeaders());
  },
  delete: (url, params = {}) => {
    return axios.delete(url, { params, ...getHeaders() });
  }
};

const getHeaders = config => {
  const userProfile = Store.getState().userProfileReducer;
  let headers = {
    "Content-Type": "application/json",
    ...config
  };
  if (userProfile.auth) {
    headers = Object.assign({}, headers, {
      Authorization: "Bearer " + userProfile.auth.token
    });
  }
  return { headers };
};
