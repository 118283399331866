import React, { PureComponent } from "react";
import "./tabSwitch.scss";

export default class tabSwitchComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentActiveTab: 0
    };
  }
  render() {
    const tabData = this.props.customTabName;
    const abc = () => {
      this.props.overwrite && this.setState({ currentActiveTab: 1 });
    };
    return (
      <div className="tabswitch-container">
        {abc()}
        {console.log(this.state.currentActiveTab, "curentatdasdtab")}{" "}
        {tabData &&
          tabData.map((tabs, index) => {
            return (
              <div>
                <div
                  className={
                    index === this.state.currentActiveTab ? "tab-active" : "tab"
                  }
                  onClick={() =>
                    !this.props.disable &&
                    (this.state.currentActiveTab !== index
                      ? this.state.currentActiveTab === 0
                        ? this.setState({ currentActiveTab: 1 }, () =>
                            this.props.displayCurrentActiveTab(
                              this.state.currentActiveTab
                            )
                          )
                        : this.state.currentActiveTab === 1
                        ? this.setState({ currentActiveTab: 0 }, () =>
                            this.props.displayCurrentActiveTab(
                              this.state.currentActiveTab
                            )
                          )
                        : null
                      : null)
                  }
                >
                  {tabs}
                </div>
                {index === this.state.currentActiveTab && (
                  <div className="purple-active-bar-indicator"></div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

//usage

{
  /* <TabSwitch displayCurrentActiveTab={displayCurrentActiveTab} /> */
}

// const displayCurrentActiveTab = currentActiveTab => {
//           console.log(currentActiveTab); shows current active tab index
//         };
