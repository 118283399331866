export const isLogin = () => {
  const rootLocalStorage = localStorage.getItem("persist:root");
  if (rootLocalStorage) {
    const LS = JSON.parse(rootLocalStorage);
    const userProfileReducer = LS.userProfileReducer;
    const userProfile = JSON.parse(userProfileReducer);
    if (userProfile.auth && userProfile.profile) {
      return true;
    } else {
      return false;
    }
  }
};
