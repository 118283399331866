import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { Icon } from "react-icons-kit";
import { sort } from "react-icons-kit/fa/sort";
import _ from "lodash";

import "./table.scss";

export const TableComponent = ({ tableTBodyData, tableTHeadData }) => {
  const [tableBodyData, setTableBodyData] = useState();
  const [sorting, setSorting] = useState();

  useEffect(() => {
    setTableBodyData(tableTBodyData);
  }, [tableTBodyData]);

  const renderTableBody = () => {
    if (!tableBodyData) return;
    return tableBodyData.map((data, i) => {
      const keys = Object.keys(data);
      const index = i + 1;
      return (
        <tr key={`${i}_tr`}>
          <td>{index}</td>
          {tableTHeadData.map((thead, j) => {
            const index = keys.indexOf(thead.key);
            if (thead.key === keys[index]) {
              return (
                <td key={`${thead.key}_${j}_${i}`} className={thead.key}>
                  {data[keys[index]]}
                </td>
              );
            } else {
              return null;
            }
          })}
        </tr>
      );
    });
  };

  const renderTableHead = () => {
    if (!tableTHeadData) return;

    return tableTHeadData.map((tHead, i) => {
      return (
        <th key={`${tHead.label}_${i}`}>
          {tHead.label}
          <Icon icon={sort} onClick={() => sortData(tHead.key)} />
        </th>
      );
    });
  };

  const sortData = type => {
    setSorting(!sorting);
    if (type === "score") {
      const data = _.orderBy(tableBodyData, Number, ["desc"]);
      setTableBodyData(data);
    } else {
      const data = _.orderBy(
        tableBodyData,
        [a => a[type].toLowerCase()],
        [sorting ? "asc" : "desc"]
      );
      setTableBodyData(data);
    }
  };

  return (
    <div className="table-wrapper">
      <Table>
        <thead>
          <tr>
            <th>No.</th>
            {renderTableHead()}
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
    </div>
  );
};
