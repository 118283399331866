import React, { useState, useEffect, createRef, useRef } from "react";
import { Card, Label } from "reactstrap";
import { connect } from "react-redux";
import { api_Req, WithInterceptor } from "../../../common/services";
import moment from "moment";
import xlsxIcon from "./../../../assets/icons/upload-excel.png";
import { Layout } from "../../../components";
import ReactDropzone from "react-dropzone";
import Icon from "react-icons-kit";
import { actions } from "../../../redux/actions/actions";
import { iosCloseEmpty } from "react-icons-kit/ionicons/iosCloseEmpty";
import downloadTemplate from "../../../assets/icons/dl-template.png";
import competencyError from "../../../assets/img/competencyError.jpeg";
import {
    Button,
    BreadCrumb,
    Table,
    ParticipantTable,
    CompetencyTable,
    TabSwitch,
    Pagination,
    PopUp,
} from "../../../components";
import "./add-participant-competency.scss";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { templates_URL } from "../../../common/services";

const InitialBreadCrumbData = [
    {
        title: "Project",
        routes: "dashboard",
    },
    {
        title: "Organisation Name",
        routes: "",
        active: true,
    },
];

export const participantCompetencyTargetLevelThead = [
    {
        label: "Competency Model",
        key: "projectCompetencyName",
    },
    {
        label: "Target Level",
        key: "score",
    },
];

export const participantsTableHeader = [
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Email",
        key: "email",
    },

    {
        label: "Title",
        key: "title",
    },
    {
        label: "Competency Target Lvl",
        key: "Competency Target Lvl",
    },

    {
        label: "checkbox",
        key: "active",
    },
    {
        label: "Status",
        key: "doneFlag",
    },
];
export const competencyModelsHeader = [
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Abbreviation",
        key: "abbreviation",
    },

    {
        label: "Level of Proficiency",
        key: "totalLevel",
    },
    {
        label: "Description of Lvl",
        key: "Description of Lvl",
    },

    {
        label: "checkbox",
        key: "active",
    },
];

export const competencyModels = [
    {
        name: "Analytical",
        abbreviation: "abbreviation",
        definition: "loremipdefination",
        levelOfProficiencyModels: [
            {
                description: "string",
                id: Math.random(),
                level: 0,
            },
            {
                description: "string",
                id: Math.random(),
                level: 0,
            },
            {
                description: "string",
                id: Math.random(),
                level: 0,
            },
            {
                description: "string",
                id: Math.random(),
                level: 0,
            },
            {
                description: "string",
                id: Math.random(),
                level: 0,
            },
            {
                description: "string",
                id: Math.random(),
                level: 0,
            },
        ],
    },
];

const ClientAddParticipantCompetencyPage = ({ history, projDetails, ...props }) => {
    const historyState = history.location.state;
    const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(null);
    const [focused, setfocuses] = useState();
    const [focusedInput, setFocusedInput] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentActiveTab, setCurrentActiveTab] = useState(0); // assuming 0 = participants ,1=competency modal tab
    const [modal, setModal] = useState(false);
    const [cantProceedWithoutCompetencyPopup, setCantProceedWithoutCompetencyPopup] = useState(false);
    const [participantCompetencyTargetLevel, setParticipantCompetencyTargetLevel] = useState(false);
    const [confirmSubmitPopup, setConfirmSubmitPopup] = useState(false);
    const [uploadCompetencyErrorPopup, setUploadCompetencyErrorPopup] = useState(false);
    const [confirmDisablePopup, setConfirmDisablePopup] = useState(false);
    const [ratingPeriodPopup, setRatingPeriodPopup] = useState(false);
    const [ratingStartDate, setRatingStartDate] = useState(moment());
    const [ratingEndDate, setRatingEndDate] = useState("");
    const [nominationFocused, setNominationFocused] = useState();
    const [nominationFocusedInput, setNominationFocusedInput] = useState();
    const [overwrite, setOverwrite] = useState(null);
    const [participantsTableBodyRespData, setParticipantsTableBodyRespData] = useState(null);
    const [partipantsFetchedData, setPartipantsFetchedData] = useState(null);
    const [fetchedProjectDetails, setFetchedProjectDetails] = useState(null);
    const [droppedParticipantFile, setDroppedParticipantFile] = useState(null);
    const [droppedCompetencyModelFile, setDroppedCompetencyModelFile] = useState(null);
    const [participantCheckAllUncheckAllFlag, setParticipantCheckAllUncheckAllFlag] = useState();
    const [competencyCheckAllUncheckAllFlag, setCompetencyCheckAllUncheckAllFlag] = useState();
    const [participantCheckboxChangesData, setParticipantCheckboxChangesData] = useState([]);
    const [competencyCheckboxChangesData, setCompetencyCheckboxChangesData] = useState([]);

    const [competencyModalPopupData, setCompetencyModalPopupData] = useState();
    const [wantResetExcelPopupData, setWantResetExcelPopupData] = useState();

    const [participantCompetencyTargetLevelData, setParticipantCompetencyTargetLevelData] = useState();

    const [competencyModelTableBodyRespData, setCompetencyModelTableBodyRespData] = useState(null);

    const [theInputKey, setTheInputKey] = useState("123");

    useEffect(() => {
        if (!historyState) history.push("/dashboard");
        const breadCrumb = [...BreadCrumbData];
        breadCrumb[1].title = historyState.project.projectName;
        setBreadCrumbData(breadCrumb);
        fetchProjDetails();
        fetchPagintedParticipants();
    }, []);

    useEffect(() => {
        setOverwrite(null);
    }, [overwrite]);

    useEffect(() => {
        fetchPagintedParticipants();
    }, [currentPage]);

    const fetchProjDetails = async () => {
        try {
            const resp = await api_Req.user.get.clientProjDetails(historyState.project.id);
            {
                console.log(resp, "clientProjectDetailsResp");
            }

            setFetchedProjectDetails(resp);
            setCompetencyModelTableBodyRespData(resp.data.competencyModels);

            if (resp) {
                if (resp.data.startNominationPeriod) {
                    setStartDate(moment(resp.data.startNominationPeriod));
                }
                if (resp.data.endNomiationPeriod) {
                    setEndDate(moment(resp.data.endNomiationPeriod));
                }
            }
        } catch (err) {
            // alert(err);
        }
    };

    const uploadParticipantFileApi = async (data) => {
        if (data) {
            let formData = new FormData();
            formData.append("file", data);
            try {
                const resp = await api_Req.user.post.clientUploadParticipantFile(formData, historyState.project.id);
                fetchPagintedParticipants();
                fetchProjDetails();
                console.log(resp);
            } catch (err) {
                if (err.response.data.exceptionMessage === "Exceeded configured maximum participant.") {
                    props.alertStatus({
                        failed: true,
                        msg: "Exceeded configured maximum participant.",
                    });
                } else {
                    toggleUploadCompetencyErrorPopup();
                }
            }
        } else {
            let formData = new FormData();
            formData.append("file", droppedParticipantFile[0]);
            try {
                const resp = await api_Req.user.post.clientUploadParticipantFile(formData, historyState.project.id);
                fetchPagintedParticipants();
                fetchProjDetails();
                console.log(resp);
            } catch (err) {
                if (err.response.data.exceptionMessage === "Exceeded configured maximum participant.") {
                    props.alertStatus({
                        failed: true,
                        msg: "Exceeded configured maximum participant.",
                    });
                } else {
                    toggleUploadCompetencyErrorPopup();
                }
            }
        }

        // console.log(droppedParticipantFile[0]);
    };
    const toggleWantResetExcelPopupData = () => {
        setWantResetExcelPopupData((wantResetExcelPopupData) => !wantResetExcelPopupData);
    };

    const uploadCompetencyFileApi = async (data) => {
        if (data) {
            let formData = new FormData();
            formData.append("file", data);

            try {
                const resp = await api_Req.user.post.clientUploadCompetencyFile(formData, historyState.project.id);
                fetchProjDetails();
                console.log(resp);
            } catch (err) {
                toggleUploadCompetencyErrorPopup();
            }
        } else {
            let formData = new FormData();
            formData.append("file", droppedCompetencyModelFile[0]);

            try {
                const resp = await api_Req.user.post.clientUploadCompetencyFile(formData, historyState.project.id);
                fetchProjDetails();
                console.log(resp);
            } catch (err) {
                toggleUploadCompetencyErrorPopup();
            }
        }
    };

    const publishApi = async () => {
        try {
            const resp = await api_Req.user.post.clientPublish(historyState.project.id);
            console.log(resp, "publish");
            fetchProjDetails();
            fetchPagintedParticipants();
        } catch (err) {
            console.log(err, " ERRROR");
        }
    };

    const saveDraftApi = async (isSubmit) => {
        const payload = {
            projectId: historyState.project.id,
            checkAll: participantCheckAllUncheckAllFlag === 1 ? 1 : 0,
            uncheckAll: participantCheckAllUncheckAllFlag === 0 ? 1 : 0,
            checkAllCompetency: competencyCheckAllUncheckAllFlag === 1 ? 1 : 0,
            uncheckAllCompetency: competencyCheckAllUncheckAllFlag === 0 ? 1 : 0,
            participantModels: participantCheckboxChangesData,
            competencyModels: competencyCheckboxChangesData,
            endNominationPeriod: moment(endDate).format("YYYY-MM-DD"),
            startNominationPeriod: moment(startDate).format("YYYY-MM-DD"),
        };

        try {
            const resp = await api_Req.user.post.clientSaveDraft(payload);
            console.log(resp, "respsavedraft");
            if (isSubmit === true) {
                console.log("submit");
                publishApi();
            } else {
                props.alertStatus({
                    failed: false,
                    msg: "Saved as draft",
                });
            }
        } catch (err) {
            console.log(err, " ERRROR");
        }
    };
    const disableApi = async () => {
        toggleConfirmDisablePopup();
        const projectId = historyState.project.id;

        try {
            const resp = await api_Req.user.post.clientDisableApi(projectId);
            fetchPagintedParticipants();
            fetchProjDetails();
        } catch (err) {
            console.log(err, " ERRROR");
        }
    };

    const fetchPagintedParticipants = async () => {
        const payload = {
            page: currentPage - 1,
            size: 17,
            status: 1,
        };

        try {
            const resp = await api_Req.user.get.clientPaginatedParticipants(historyState.project.id, payload);
            setPartipantsFetchedData(resp.data);
            setParticipantsTableBodyRespData(resp.data.content);
            console.log(resp, "paginatedparticipantRESP");
        } catch (err) {
            // alert(err);
        }
    };

    const toggleActiveTab = (a) => {
        setCurrentActiveTab(a);
    };

    const onChange = (page) => {
        setCurrentPage(page);
    };

    // var CompetencyIndex = 0; //needs integration

    const toggleCompetencyModalPopup = () => {
        setModal((prevModal) => !prevModal);
    };
    const toggleCantProceedWithoutCompetencyPopup = () => {
        setCantProceedWithoutCompetencyPopup((prevModal) => !prevModal);
    };
    const toggleConfirmSubmitPopup = () => {
        setConfirmSubmitPopup((prevModal) => !prevModal);
    };
    const toggleConfirmDisablePopup = () => {
        setConfirmDisablePopup((prevModal) => !prevModal);
    };
    const toggleRatingPeriodPopup = () => {
        setRatingPeriodPopup((prevModal) => !prevModal);
    };
    const toggleParticipantCompetencyTargetLevel = () => {
        setParticipantCompetencyTargetLevel((prevModal) => !prevModal);
    };
    const toggleUploadCompetencyErrorPopup = () => {
        setUploadCompetencyErrorPopup((prevModal) => !prevModal);
    };
    const navOverloadPage = () => {
        history.push("client-overload-list", {
            project: fetchedProjectDetails.data,
            projectName: historyState.project.projectName,
        });
    };
    const resultChartListPage = () => {
        history.push("client-result-chart-list", {
            project: historyState.project,
        });
    };

    const handleOnDrop = (file) => {
        if (file && file.length > 0) {
            if (currentActiveTab === 0) {
                setDroppedParticipantFile(file);
            } else if (currentActiveTab === 1) {
                setDroppedCompetencyModelFile(file);
            }
        }
    };

    const dropzoneRef = createRef();

    // const openDialog = () => {
    //     if (dropzoneRef.current) {
    //         dropzoneRef.current.open();
    //     }
    // };

    const checkIfCompetencyTableHasData = () => {
        return competencyModelTableBodyRespData && competencyModelTableBodyRespData.length > 0;
    };

    const checkIfCurrentActiveTabHasTableData = () => {
        switch (currentActiveTab) {
            case 0:
                return participantsTableBodyRespData && participantsTableBodyRespData.length > 0;

            case 1:
                return competencyModelTableBodyRespData && competencyModelTableBodyRespData.length > 0;

            default:
                break;
        }
    };
    const confirmStartRatingAndRefresh = () => {
        startApi();

        toggleRatingPeriodPopup();
    };
    const startApi = async () => {
        try {
            const resp = await api_Req.user.post.clientStart(
                historyState.project.id,
                moment(ratingStartDate).format("YYYY-MM-DD"),
                moment(ratingEndDate).format("YYYY-MM-DD")
            );
            fetchPagintedParticipants();
            fetchProjDetails();
            console.log(resp, "started");
        } catch (err) {
            console.log(err, " ERRROR");
        }
    };

    const cleanExcel = async () => {
        // const payload = {
        //   projectId: historyState.project.id,
        // };
        try {
            const resp = await api_Req.user.post.clientCleanExcel(historyState.project.id);
            fetchPagintedParticipants();
            fetchProjDetails();
        } catch (err) {
            fetchPagintedParticipants();
            fetchProjDetails();
        }
    };
    const saveDraftAndSubmit = () => {
        saveDraftApi(true);

        toggleConfirmSubmitPopup();
        fetchPagintedParticipants();
        fetchProjDetails();
    };
    const eyeOnClick = (data) => {
        if (currentActiveTab === 0) {
            toggleParticipantCompetencyTargetLevel();
            setParticipantCompetencyTargetLevelData(data);
            // console.log(data, "dataaaaaadasdjasiodjios");
        }

        if (currentActiveTab === 1) {
            toggleCompetencyModalPopup();
            // console.log(data, data.levelOfProficiencyModels, "dataaaa");
            setCompetencyModalPopupData(data);
        }
    };
    const setParticipantChangesCheckboxData = (checkboxChangesdata, CheckAllUncheckAllFlag) => {
        setParticipantCheckboxChangesData(checkboxChangesdata);
        setParticipantCheckAllUncheckAllFlag(CheckAllUncheckAllFlag);
    };
    const setCompetencyChangesCheckboxData = (checkboxChangesdata, CheckAllUncheckAllFlag) => {
        setCompetencyCheckboxChangesData(checkboxChangesdata);
        setCompetencyCheckAllUncheckAllFlag(CheckAllUncheckAllFlag);
    };

    const fetchedProjectDetailsWithData = fetchedProjectDetails && fetchedProjectDetails.data;

    const participantsTableHeaderWitoutStatus = participantsTableHeader.filter((item) => item.label !== "Status");

    const participantsTableHeaderWitoutCheckbox = participantsTableHeader.filter((item) => item.label !== "checkbox");
    const competencyModelsHeaderWithoutCheckbox = competencyModelsHeader.filter((item) => item.label !== "checkbox");
    // const upload = createRef();
    // const uploadClick = () => {
    //   if (upload.current) {
    //     upload.current.onClick();
    //   }
    // };
    const inputFile = useRef(null);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        var file = inputFile.current.click();
    };
    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];

        console.log(file, "file");
        if (currentActiveTab === 0) {
            uploadParticipantFileApi(file);
            setTheInputKey(Math.random());
        }
        if (currentActiveTab === 1) {
            uploadCompetencyFileApi(file);
            setTheInputKey(Math.random());
        }
        // if (currentActiveTab === 0) {
        //   setDroppedParticipantFile([file]);
        // } else if (currentActiveTab === 1) {
        //   setDroppedCompetencyModelFile([file]);
        // }

        // if (droppedParticipantFile && droppedParticipantFile[0]) {
        //   console.log(
        //     droppedParticipantFile && droppedParticipantFile[0],
        //     "droppedParticipantFile && droppedParticipantFile[0]droppedParticipantFile && droppedParticipantFile[0]"
        //   );
        // }

        // this.setState({ file }); /// if you want to upload latter
    };
    return (
        <Layout>
            {}{" "}
            <div className="add-participant-competency-container">
                <div className="header-container">
                    <BreadCrumb data={InitialBreadCrumbData} />
                    <div className="header-button-container">
                        {fetchedProjectDetailsWithData &&
                            fetchedProjectDetailsWithData.isDraft === 0 &&
                            fetchedProjectDetailsWithData.isOverallCompleted === 0 && (
                                <span className="header-status-nomination-or-rating-with-date">
                                    {fetchedProjectDetailsWithData &&
                                        fetchedProjectDetailsWithData.participantCompleted === 0 && (
                                            <span>
                                                <span style={{ color: "#79945A" }}>Nomination in progress</span>
                                                <span className="date-container">
                                                    {moment(
                                                        fetchedProjectDetailsWithData &&
                                                            fetchedProjectDetailsWithData.startNominationPeriod
                                                    ).format("DD MMM YYYY")}{" "}
                                                    {" - "}
                                                    {moment(
                                                        fetchedProjectDetailsWithData &&
                                                            fetchedProjectDetailsWithData.endNomiationPeriod
                                                    ).format("DD MMM YYYY")}
                                                </span>
                                            </span>
                                        )}
                                    {fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.isStart === 1 && (
                                        <span>
                                            <span style={{ color: "#79945A" }}>Ratings in progress</span>
                                            <span className="date-container" style={{ marginRight: 10 }}>
                                                {moment(
                                                    fetchedProjectDetailsWithData &&
                                                        fetchedProjectDetailsWithData.ratingStartDate
                                                ).format("DD MMM YYYY")}
                                                {" - "}
                                                {moment(
                                                    fetchedProjectDetailsWithData &&
                                                        fetchedProjectDetailsWithData.ratingEndDate
                                                ).format("DD MMM YYYY")}
                                            </span>
                                        </span>
                                    )}
                                    {fetchedProjectDetailsWithData &&
                                        fetchedProjectDetailsWithData.participantCompleted === 1 && (
                                            <span>
                                                <span style={{ color: "#9C9C9D" }}>
                                                    {/* Nomination ended */}
                                                    Nomination period
                                                </span>
                                                <span className="date-container">
                                                    {moment(
                                                        fetchedProjectDetailsWithData &&
                                                            fetchedProjectDetailsWithData.startNominationPeriod
                                                    ).format("DD MMM YYYY")}{" "}
                                                    {" - "}
                                                    {moment(
                                                        fetchedProjectDetailsWithData &&
                                                            fetchedProjectDetailsWithData.endNomiationPeriod
                                                    ).format("DD MMM YYYY")}
                                                </span>
                                            </span>
                                        )}
                                </span>
                            )}

                        {fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.isDraft === 1 && (
                            <Button primary="primaryS" title="Save Draft" onClick={() => saveDraftApi()} />
                        )}
                        {fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.isDraft === 1 && (
                            <Button
                                outline="outlineS"
                                title="Submit"
                                onClick={() =>
                                    checkIfCompetencyTableHasData() !== true
                                        ? toggleCantProceedWithoutCompetencyPopup()
                                        : toggleConfirmSubmitPopup()
                                }
                            />
                        )}
                        {fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.isOverallCompleted === 1 && (
                            <Button title="View Results" onClick={() => resultChartListPage()} />
                        )}
                        {fetchedProjectDetailsWithData &&
                            fetchedProjectDetailsWithData.isDraft === 0 &&
                            fetchedProjectDetailsWithData.isStart === 0 &&
                            fetchedProjectDetailsWithData.isOverallCompleted === 0 && (
                                <Button outline="outlineS" title="Disable" onClick={() => toggleConfirmDisablePopup()} />
                            )}
                    </div>
                </div>
                {fetchedProjectDetailsWithData &&
                    fetchedProjectDetailsWithData.isDraft === 0 &&
                    fetchedProjectDetailsWithData.participantCompleted === 1 &&
                    fetchedProjectDetailsWithData.isStart === 0 &&
                    fetchedProjectDetailsWithData.isOverallCompleted === 0 && (
                        <div
                            style={{
                                backgroundColor: "#B4AE32",
                            }}
                            className="nomination-period-status-start-rating"
                        >
                            <span className="start-rating-text">{/* Nomination period is end!{" "} */}</span>
                            <Button
                                buttonClasses="start-rating-button"
                                title="Start rating "
                                onClick={() => {
                                    toggleRatingPeriodPopup();
                                }}
                            />
                        </div>
                    )}
                {fetchedProjectDetailsWithData &&
                    fetchedProjectDetailsWithData.isDraft === 0 &&
                    fetchedProjectDetailsWithData.isStart === 0 &&
                    fetchedProjectDetailsWithData.participantCompleted === 1 &&
                    fetchedProjectDetailsWithData.isOverallCompleted === 0 &&
                    fetchedProjectDetailsWithData.hasFatiqueRater !== 0 && (
                        <div
                            style={{
                                backgroundColor: "#F65253",
                            }}
                            className="nomination-period-status-overloaded"
                        >
                            <span className="check-text">Nomination period is end! Some raters is overloaded. </span>
                            <Button
                                className="check-button"
                                title="Check"
                                onClick={() => {
                                    navOverloadPage();
                                }}
                            />
                        </div>
                    )}

                <PopUp
                    className="client-cant-proceed-without-competency-popup"
                    visible={wantResetExcelPopupData}
                    toggle={() => toggleWantResetExcelPopupData()}
                    buttonT1="Upload"
                    buttonT2="Reset Then Upload"
                    buttonT1Click={() => {
                        onButtonClick();
                    }}
                    buttonT2Click={() => {
                        cleanExcel();

                        onButtonClick();
                    }}
                    close
                >
                    <br />

                    <h3 style={{ fontSize: 16 }}>
                        Please choose 'Reset Then Upload' if you wish to upload a file with different competency fields
                    </h3>
                    <br />
                </PopUp>

                <PopUp className="client-competency-modal-popup" visible={modal} toggle={toggleCompetencyModalPopup}>
                    <h3 className="title">Competencies Model</h3>
                    {/* // competency index needs integration */}
                    <h3>
                        {competencyModalPopupData && competencyModalPopupData.name}(
                        {competencyModalPopupData && competencyModalPopupData.abbreviation})
                    </h3>
                    <h3> {competencyModalPopupData && competencyModalPopupData.definition}</h3>

                    {competencyModalPopupData &&
                        competencyModalPopupData.levelOfProficiencyModels.map((level, index) => {
                            return (
                                <div className="proficiency-level-container" key={level.id + index}>
                                    <span>{level.level}</span>
                                    <span>{level.description}</span>
                                </div>
                            );
                        })}
                </PopUp>
                <PopUp
                    className="client-participantCompetencyTargetLevel-popup"
                    visible={participantCompetencyTargetLevel}
                    toggle={toggleParticipantCompetencyTargetLevel}
                >
                    <p className="title">
                        {participantCompetencyTargetLevelData && participantCompetencyTargetLevelData.name}
                    </p>
                    <p>{participantCompetencyTargetLevelData && participantCompetencyTargetLevelData.title}</p>
                    <Table
                        tableTHeadData={participantCompetencyTargetLevelThead}
                        tableTBodyData={participantCompetencyTargetLevelData && participantCompetencyTargetLevelData.targets}
                    />
                </PopUp>
                <PopUp
                    className="client-uploadCompetencyErrorPopup-popup"
                    visible={uploadCompetencyErrorPopup}
                    toggle={toggleUploadCompetencyErrorPopup}
                    buttonT1="OK"
                    buttonT1Click={() => {
                        toggleUploadCompetencyErrorPopup();
                    }}
                >
                    <h3>
                        1. Please make sure the data of both participants and competency model are matched. An example is as
                        below:
                        <br />
                    </h3>
                    <div className="img-container">
                        <img src={competencyError} className="add-competency-error" alt="add-competency-error" />
                    </div>
                </PopUp>

                <PopUp
                    className="client-cant-proceed-without-competency-popup"
                    visible={cantProceedWithoutCompetencyPopup}
                    toggle={toggleCantProceedWithoutCompetencyPopup}
                    buttonT1="Upload"
                    buttonT2="Cancel"
                    buttonT1Click={() => {
                        toggleActiveTab();
                        toggleCantProceedWithoutCompetencyPopup();
                        setOverwrite(1);
                        setCurrentActiveTab(1);
                    }}
                    buttonT2Click={() => toggleCantProceedWithoutCompetencyPopup()}
                    close
                >
                    <h3 className="title">Can't proceed without competencies.</h3>
                    <h3>
                        Please upload data for competency models before proceed.
                        <br />
                        Thanks.
                    </h3>
                </PopUp>
                <PopUp
                    className="client-submit-confirmation-popup"
                    visible={confirmSubmitPopup}
                    toggle={toggleConfirmSubmitPopup}
                    buttonT1="Confirm"
                    buttonT2="Cancel"
                    buttonT1Click={() => (endDate ? saveDraftAndSubmit() : toggleConfirmSubmitPopup())}
                    buttonT2Click={() => toggleConfirmSubmitPopup()}
                    close
                >
                    <h3 className="title">Confirm to submit?</h3>
                    <h3>
                        Once confirmed, the information no-longer able to edit, and the invitation email will send to all the
                        participants.
                    </h3>
                </PopUp>
                <PopUp
                    className="client-confirm-disable-popup"
                    visible={confirmDisablePopup}
                    toggle={toggleConfirmDisablePopup}
                    buttonT1="Confirm"
                    buttonT2="Cancel"
                    buttonT1Click={() => disableApi()}
                    buttonT2Click={() => toggleConfirmDisablePopup()}
                    close
                >
                    <h3 className="title">Confirm to disable?</h3>
                    <h3>
                        Once confirmed, you and participants will no-longer able to proceed any action with this project.
                    </h3>
                </PopUp>
                <PopUp
                    className="client-rating-period-popup"
                    visible={ratingPeriodPopup}
                    toggle={toggleRatingPeriodPopup}
                    buttonT1="Confirm"
                    buttonT2="Cancel"
                    buttonT1Click={() => confirmStartRatingAndRefresh()}
                    buttonT2Click={() => toggleRatingPeriodPopup()}
                >
                    <h3 className="title">Please enter the rating period</h3>
                    <div className="start-end-date-label-container">
                        <Label for="nominationStartDate">Start Date </Label>
                        <Label for="nominationEndDate">End Date </Label>
                    </div>
                    <div className="dateRangePicker-container">
                        <DateRangePicker
                            startDatePlaceholderText={moment()}
                            endDatePlaceholderText="Please select"
                            required
                            orientation={window.innerWidth < 768 ? "verticalScrollable" : "horizontal"}
                            startDateId="nominationStartDate"
                            endDateId="nominationEndDate"
                            startDate={ratingStartDate}
                            endDate={ratingEndDate}
                            onDatesChange={({ startDate, endDate }) => {
                                if (startDate) {
                                    setRatingEndDate(null);
                                    setRatingStartDate(startDate);
                                }
                                if (endDate) {
                                    setRatingEndDate(endDate);
                                }
                            }}
                            focusedInput={nominationFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={(nominationFocusedInput) => setNominationFocusedInput(nominationFocusedInput)} // PropTypes.func.isRequired,
                            focused={nominationFocused}
                            displayFormat="DD MMM YYYY"
                            hideKeyboardShortcutsPanel
                            customArrowIcon="-"
                            noBorder={true}
                            minimumNights={0}
                            horizontalMargin={window.innerWidth > 768 ? 70 : 10}
                            numberOfMonths={window.innerWidth < 768 ? 3 : 2}
                        />
                    </div>
                    <h3>After confirmed, email will send to raters to start rating.</h3>
                </PopUp>
                <Card>
                    <div className="column">
                        {fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.isOverallCompleted === 0 ? (
                            <div className="card-row">
                                <h3 className="title">Nomination Period</h3>
                                <p className="desc">Period of nomination for participants.</p>
                                <div className="start-end-date-label-container">
                                    <Label for="startDate">Start Date </Label>
                                    <Label for="endDate">End Date </Label>
                                </div>
                                <div className="dateRangePicker-container">
                                    <DateRangePicker
                                        startDatePlaceholderText={moment()}
                                        endDatePlaceholderText="Please select"
                                        required
                                        disabled={
                                            fetchedProjectDetailsWithData
                                                ? fetchedProjectDetailsWithData.isDraft === 0
                                                    ? true
                                                    : false
                                                : false
                                        }
                                        orientation={window.innerWidth < 768 ? "verticalScrollable" : "horizontal"}
                                        startDateId="startDate"
                                        endDateId="endDate"
                                        startDate={
                                            // fetchedProjectDetails &&
                                            // fetchedProjectDetails.data.startNominationPeriod
                                            //   ? moment(fetchedProjectDetails.data.startNominationPeriod)
                                            //   :
                                            startDate
                                        }
                                        endDate={
                                            // fetchedProjectDetails &&
                                            // fetchedProjectDetails.data.endNomiationPeriod
                                            //   ? moment(fetchedProjectDetails.data.endNomiationPeriod)
                                            //   :
                                            endDate
                                        }
                                        onDatesChange={({ startDate, endDate }) => {
                                            if (startDate) {
                                                setEndDate(null);
                                                setStartDate(startDate);
                                            }
                                            if (endDate) {
                                                setEndDate(endDate);
                                            }
                                        }}
                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                                        focused={focused}
                                        displayFormat="DD MMM YYYY"
                                        hideKeyboardShortcutsPanel
                                        customArrowIcon="-"
                                        noBorder={true}
                                        minimumNights={0}
                                        horizontalMargin={window.innerWidth > 768 ? 70 : 10}
                                        numberOfMonths={window.innerWidth < 768 ? 3 : 2}
                                    />
                                    {!endDate && (
                                        <p
                                            className="no-end-date-error"
                                            style={{
                                                color: "red",
                                                marginBottom: 0,
                                                marginTop: 2,
                                                marginLeft: 30,
                                                fontSize: 11,
                                                textAlign: "end",
                                            }}
                                        >
                                            Input End Date to submit
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="result-nomination-and-rating-periods-card-row">
                                <div className="nomination-period-result-left">
                                    <p className="title">Nomination period</p>
                                    <p>Period of nomination for the participants.</p>
                                    <p>
                                        {" "}
                                        {moment(startDate).format("DD MMM YYYY")} {" - "}
                                        {moment(endDate).format("DD MMM YYYY")}
                                    </p>
                                </div>
                                <div className="rating-period-result-right">
                                    <p className="title">Rating Period</p>
                                    <p>
                                        {moment(
                                            fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.ratingStartDate
                                        ).format("DD MMM YYYY")}
                                        {" - "}
                                        {moment(
                                            fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.ratingEndDate
                                        ).format("DD MMM YYYY")}
                                    </p>
                                </div>
                            </div>
                        )}
                        {fetchedProjectDetailsWithData && fetchedProjectDetailsWithData.isDraft === 1 && (
                            <div className="card-row">
                                <div className="draft-template-draft-status">
                                    <div className="template-container">
                                        <img src={downloadTemplate} className="template-icon" />
                                        <a
                                            href={
                                                currentActiveTab === 0 ? templates_URL.PARTICIPANT : templates_URL.COMPETENCY
                                            }
                                            className="download-template"
                                        >
                                            Download Template
                                        </a>
                                    </div>
                                    <div className="line" />
                                    <div className="import-and-draft-status">
                                        <h3 className="title">
                                            Add {currentActiveTab === 0 ? "Participant" : "Competency"}
                                        </h3>
                                        {fetchedProjectDetailsWithData &&
                                            fetchedProjectDetailsWithData.lastUploadedParticipantDate && (
                                                <h5 className="desc-2">
                                                    Last imported :
                                                    {moment(
                                                        fetchedProjectDetailsWithData &&
                                                            fetchedProjectDetailsWithData.lastUploadedParticipantDate
                                                    ).format("DD MMM YYYY")}
                                                </h5>
                                            )}

                                        <div
                                            className="upload-icon-container"
                                            // onClick={() => onButtonClick()}

                                            // competencyModelTableBodyRespData&&competencyModelTableBodyRespData.length>0?console.log("want reset"):null
                                            onClick={() =>
                                                competencyModelTableBodyRespData &&
                                                competencyModelTableBodyRespData.length > 0
                                                    ? toggleWantResetExcelPopupData()
                                                    : onButtonClick()
                                            }
                                        >
                                            <input
                                                type="file"
                                                id="file"
                                                ref={inputFile}
                                                style={{ display: "none" }}
                                                onChange={onChangeFile}
                                                key={theInputKey || ""}
                                            />

                                            <img src={xlsxIcon} />
                                            <h3>Import</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {fetchedProjectDetailsWithData &&
                            fetchedProjectDetailsWithData.isDraft === 0 &&
                            fetchedProjectDetailsWithData.participantCompleted === 1 &&
                            fetchedProjectDetailsWithData.isOverallCompleted === 0 &&
                            fetchedProjectDetailsWithData.raters &&
                            fetchedProjectDetailsWithData.raters.length > 0 && (
                                <div className="card-row" style={{ justifyContent: "flex-end" }}>
                                    <Button
                                        outline="outlineS"
                                        className="check-button"
                                        title="View Raters"
                                        onClick={() => {
                                            navOverloadPage();
                                        }}
                                    />
                                </div>
                            )}
                    </div>

                    <div className="column">
                        <TabSwitch
                            overwrite={overwrite}
                            disable={participantsTableBodyRespData === null} //if no data cant switch tab to competency
                            displayCurrentActiveTab={toggleActiveTab}
                            customTabName={["Participants", "Competency Model"]}
                        />
                        {currentActiveTab === 0 && ( //pagination only for participant not competency
                            <Pagination
                                onChange={onChange}
                                current={currentPage}
                                total={partipantsFetchedData && partipantsFetchedData.totalElements}
                                pageSize={17}
                            />
                        )}
                    </div>

                    {checkIfCurrentActiveTabHasTableData() ? (
                        //if no participant data show import logo

                        <div className="column">
                            <div
                                style={{
                                    display: currentActiveTab === 1 ? "none" : "contents",
                                }}
                            >
                                <ParticipantTable
                                    onClick={eyeOnClick}
                                    participantChangesCheckboxData={setParticipantChangesCheckboxData}
                                    tableTHeadData={
                                        fetchedProjectDetailsWithData
                                            ? fetchedProjectDetailsWithData.isDraft === 0
                                                ? participantsTableHeaderWitoutCheckbox
                                                : participantsTableHeaderWitoutStatus
                                            : participantsTableHeaderWitoutCheckbox
                                    }
                                    tableTBodyData={participantsTableBodyRespData}
                                />
                            </div>
                            <div
                                style={{
                                    display: currentActiveTab === 0 ? "none" : "contents",
                                }}
                            >
                                <CompetencyTable
                                    onClick={eyeOnClick}
                                    competencyChangesCheckboxData={setCompetencyChangesCheckboxData}
                                    tableTHeadData={
                                        fetchedProjectDetailsWithData
                                            ? fetchedProjectDetailsWithData.isDraft === 0
                                                ? competencyModelsHeaderWithoutCheckbox
                                                : competencyModelsHeader
                                            : competencyModelsHeaderWithoutCheckbox
                                    }
                                    tableTBodyData={competencyModelTableBodyRespData}
                                />
                            </div>
                        </div>
                    ) : (
                        <ReactDropzone
                            ref={dropzoneRef}
                            // accept="image/jpeg, image/png"
                            onDrop={(file) => handleOnDrop(file)}
                        >
                            {({ getRootProps, getInputProps }) => {
                                const droppedFileParticipantOrCompetencyBasedCurrentTab =
                                    currentActiveTab === 0
                                        ? droppedParticipantFile && droppedParticipantFile[0]
                                        : droppedCompetencyModelFile && droppedCompetencyModelFile[0];
                                return (
                                    <div
                                        className="no-data-upload-icon-container"
                                        {...getRootProps({
                                            onClick: (event) => event.stopPropagation(),
                                            // if no participant drop file pull up file menu ,else upload
                                        })}
                                    >
                                        <input name="file" {...getInputProps()} />
                                        <div className="circle-container">
                                            {droppedFileParticipantOrCompetencyBasedCurrentTab && (
                                                <Icon
                                                    className="clear-dropped-files-icon"
                                                    icon={iosCloseEmpty}
                                                    onClick={() => {
                                                        setDroppedParticipantFile(null);
                                                        setDroppedCompetencyModelFile(null);
                                                    }}
                                                    size={32}
                                                />
                                            )}
                                            <img
                                                style={
                                                    droppedFileParticipantOrCompetencyBasedCurrentTab && {
                                                        filter: "none",
                                                        opacity: 1,
                                                    }
                                                }
                                                src={xlsxIcon}
                                            />
                                            {droppedFileParticipantOrCompetencyBasedCurrentTab && ( //display dropped participant file name
                                                <h3
                                                    style={
                                                        droppedFileParticipantOrCompetencyBasedCurrentTab && {
                                                            opacity: 1,
                                                            maxWidth: 70,
                                                            wordBreak: "break-all",
                                                            textAlign: "center",
                                                        }
                                                    }
                                                >
                                                    {droppedFileParticipantOrCompetencyBasedCurrentTab.name.length > 16
                                                        ? droppedFileParticipantOrCompetencyBasedCurrentTab.name.substring(
                                                              0,
                                                              16
                                                          ) + "..."
                                                        : droppedFileParticipantOrCompetencyBasedCurrentTab.name}
                                                </h3>
                                            )}
                                        </div>
                                        {!droppedFileParticipantOrCompetencyBasedCurrentTab && <h3>No Data</h3>}
                                        <Button
                                            primary="primaryS"
                                            title={
                                                droppedFileParticipantOrCompetencyBasedCurrentTab
                                                    ? "Confirm Upload"
                                                    : "Upload Now"
                                            }
                                            onClick={() =>
                                                droppedFileParticipantOrCompetencyBasedCurrentTab
                                                    ? currentActiveTab === 0
                                                        ? uploadParticipantFileApi()
                                                        : uploadCompetencyFileApi()
                                                    : onButtonClick()
                                            }
                                            type="button"
                                        />
                                    </div>
                                );
                            }}
                        </ReactDropzone>
                    )}
                </Card>
            </div>
            {console.log(
                competencyModelTableBodyRespData && competencyModelTableBodyRespData.length > 0,
                "competencyModelTableBodyRespData"
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        projDetails: state.clientReducer.projDetails,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertStatus: (data) => dispatch({ type: actions.TOGGLE_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(ClientAddParticipantCompetencyPage));
