import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { eye } from "react-icons-kit/ionicons/eye";
import queryString from "query-string";
import Icon from "react-icons-kit";

import ic_done from "../../assets/icons/ic_done.png";
import nhaLogo from "../../assets/img/nha-logo.png";
import { PopUp, Button } from "../../components";
import { api_Req } from "../../common/services";
import "./reset-password.scss";

export default class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eyePasswordHidden: true,
      visible: false
    };
  }

  componentDidMount() {
    localStorage.clear();
    const { location } = this.props;

    const parsed = queryString.parse(location.search);
    this.setState({ parsed });

    console.log(parsed, "parsed");
    console.log(parsed.isClient, "iscleint");
  }

  passwordVisibilityToggle = () => {
    this.setState(prevState => ({
      eyePasswordHidden: !prevState.eyePasswordHidden
    }));
  };

  successSignupPopUp = () => {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  };

  handleSubmit = async ({ password }) => {
    const { parsed } = this.state;
    const payload = {
      password: password,
      token: parsed.t
    };
    console.log(payload, "payload");

    try {
      const { history } = this.props;
      const resp = await api_Req.user.post.resetPassword(payload);

      this.setState({ Resetresp: resp }, () => {
        this.successSignupPopUp();
      });
      console.log(resp, "resp");

      //       if (resp) this.successSignupPopUp();
    } catch (err) {
      alert(err);
    }
  };

  render() {
    const { history } = this.props;
    const { visible, eyePasswordHidden, parsed } = this.state;
    const regexSpecialChar = /^(a-z|A-Z|0-9)*[^!@~#$%^&*()']*$/;
    const regexChar = /^.*[a-z].*$/i;
    const regexMobile = /^.*[0-9].*$/;
    const hidePassword = !eyePasswordHidden ? "active" : "";

    return (
      <div className="main-container">
        <div className="reset-password-container">
          <div className="logo">
            <img className="logo-img" src={nhaLogo} alt="nha" />
          </div>
          <div className="signup-card">
            <p>Reset Password</p>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required("Required")
                  .min(8, "")
                  .max(20, "")
                  .test("", "", function(value) {
                    if (value !== undefined) {
                      return (
                        value.match(regexChar) &&
                        value.match(regexMobile) &&
                        !value.match(regexSpecialChar)
                      );
                    }
                  }),

                confirmPassword: Yup.string()
                  .required("Required")
                  .min(6, "")
                  .max(20, "")
                  .test("passwords-match", "Passwords must match", function(
                    value
                  ) {
                    return this.parent.password === value;
                  })
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                this.handleSubmit(values);
              }}
            >
              {props => {
                const {
                  touched,
                  errors,
                  isSubmitting,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values
                } = props;
                return (
                  <form onSubmit={handleSubmit} className="formStyle">
                    <div className="icon_container">
                      <Icon
                        className={hidePassword}
                        onClick={this.passwordVisibilityToggle}
                        icon={eye}
                        size={25}
                      />
                      <input
                        id="password"
                        type={eyePasswordHidden ? "password" : "text"}
                        name="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        className={
                          errors.password && touched.password
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                    </div>
                    <div className="icon_container">
                      <Icon
                        className={hidePassword}
                        onClick={this.passwordVisibilityToggle}
                        icon={eye}
                        size={25}
                      />
                      <input
                        id="confirmPassword"
                        type={eyePasswordHidden ? "password" : "text"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                    </div>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="input-feedback">
                        {errors.confirmPassword}
                      </div>
                    )}
                    <div className="password-condition-validate">
                      <p
                        style={{
                          opacity: values.password.length > 0 ? 1 : 0.5
                        }}
                      >
                        Password hint:
                      </p>

                      <div className="condition-icon-container">
                        <p
                          style={{
                            color:
                              values.password.length >= 8
                                ? " #92CC4D"
                                : "#f4f0f0",
                            opacity: values.password.length >= 8 ? 1 : 0.5
                          }}
                        >
                          Must be 8 digits
                        </p>
                        {values.password.length >= 8 && (
                          <img src={ic_done} alt="done-icon" />
                        )}
                      </div>
                      <div className="condition-icon-container">
                        <p
                          style={{
                            color: values.password.match(regexChar)
                              ? " #92CC4D"
                              : "#f4f0f0",
                            opacity: values.password.match(regexChar) ? 1 : 0.5
                          }}
                        >
                          At least 1 alphabet (a-z)
                        </p>
                        {values.password.match(regexChar) && (
                          <img src={ic_done} alt="done-icon" />
                        )}
                      </div>
                      <div className="condition-icon-container">
                        <p
                          style={{
                            color: values.password.match(regexMobile)
                              ? " #92CC4D"
                              : "#f4f0f0",
                            opacity: values.password.match(regexMobile)
                              ? 1
                              : 0.5
                          }}
                        >
                          At least 1 number (0-9)
                        </p>
                        {values.password.match(regexMobile) && (
                          <img src={ic_done} alt="done-icon" />
                        )}
                      </div>
                      <div className="condition-icon-container">
                        <p
                          style={{
                            color: !values.password.match(regexSpecialChar)
                              ? " #92CC4D"
                              : "#f4f0f0",
                            opacity: !values.password.match(regexSpecialChar)
                              ? 1
                              : 0.5
                          }}
                        >
                          At least 1 non-alphanumeric symbol (e.g. @$%!)
                        </p>
                        {!values.password.match(regexSpecialChar) && (
                          <img src={ic_done} alt="done-icon" />
                        )}
                      </div>
                    </div>
                    <div className="button-container">
                      <Button
                        type="submit"
                        title="Reset Password"
                        disabled={isSubmitting}
                      />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          <PopUp
            className="participantSignup-popup-container"
            buttonT1="ok"
            buttonT1Click={() =>
              this.state.Resetresp.data.loginType === "Client"
                ? history.push("client/dashboard")
                : history.push("login")
            }
            visible={visible}
            toggle={this.successSignupPopUp}
          >
            <p className="pop-up-card-desc">
              Congratulations! You've succesfuly resetted your password
            </p>
          </PopUp>
        </div>
      </div>
    );
  }
}
