import React from "react";
import { Button } from "../";

import "./alertBar.scss";

export const AlertBarComponent = ({ buttonTitle, msg, onClick }) => {
  return (
    <div className="alert-bar-status">
      <p className="status-text">
        {msg}
      </p>
      <Button title={buttonTitle} onClick={onClick} />
    </div>
  );
};
