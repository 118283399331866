import React, { useEffect, useState } from "react";
import Icon from "react-icons-kit";
import { check, cross } from "react-icons-kit/metrize";
import { connect } from "react-redux";

import { actions } from "../../redux/actions/actions";
import { PopUp } from "../";
import "./statusPopUp.scss";

const StatusPopUpComponent = ({
  visible,
  msg,
  alertStatus,
  hideStatus,
  ...props
}) => {
  const [statusClasses, setStatusClasses] = useState("success");

  useEffect(
    () => {
      if (alertStatus.visible) {
        setTimeout(() => {
          hideStatus();
        }, 3000);

        alertStatus.failed
          ? setStatusClasses("error")
          : setStatusClasses("success");
      }
    },
    [alertStatus, hideStatus]
  );

  return (
    <PopUp
      className="status-pop-up"
      visible={alertStatus.visible}
      backdrop={false}
      transitionLeaveTimeout={200}
    >
      <div className={`status-wrapper ${statusClasses}`}>
        <Icon icon={alertStatus.failed ? cross : check} size={30} />
        <p>
          {alertStatus.msg || "Successfully Submitted"}
        </p>
      </div>
    </PopUp>
  );
};

const mapStateToProps = state => {
  return {
    alertStatus: state.alertStatusReducer
  };
};

const mapDispatchToProps = dispatch => ({
  hideStatus: data => dispatch({ type: actions.HIDE_ALERT, data })
});

export default connect(mapStateToProps, mapDispatchToProps)(
  StatusPopUpComponent
);
