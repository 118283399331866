import React from "react";
import { Icon } from "react-icons-kit";
import { iosArrowRight } from "react-icons-kit/ionicons/iosArrowRight";
import { withRouter } from "react-router-dom";

import "./breadCrumb.scss";

const BreadCrumbComponent = ({ data, history, ...props }) => {
  return (
    <div className="bread-crumb-container">
      {data.map((o, i) => {
        const params = o.params ? o.params : null;
        const activeClasses = o.active ? "active" : "";
        const icon =
          i + 1 === data.length
            ? null
            : <Icon icon={iosArrowRight} size={10} />;

        return (
          <div
            key={`${i}_${o.title}`}
            className={`bread-crumb-item ${activeClasses}`}
            onClick={() =>
              o.routes && o.routes !== "back"
                ? history.push(o.routes, { params })
                : history.goBack(null)}
          >
            {o.title}
            {icon}
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(BreadCrumbComponent);
