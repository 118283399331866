import React, { useState, useEffect, Fragment } from "react";
import { Card, Collapse } from "reactstrap";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { iosArrowDown, iosArrowUp } from "react-icons-kit/ionicons";
import _ from "lodash";

import { actions } from "../../../../redux/actions/actions";
import { formatDate } from "../../../../common/helper/helpers";
import { api_Req, WithInterceptor } from "../../../../common/services";
import { Layout, BreadCrumb, Button, PopUp } from "../../../../components";
import "./rate-yourself.scss";

const InitialBreadCrumbData = [
    {
        title: "Project",
        routes: "/dashboard",
    },
    {
        title: "Organisation Name",
        routes: "",
        active: true,
    },
];

export const RateYourselfConnectPage = ({ history, projDetails, projRaters, projResults, projCompetency, ...props }) => {
    const [submitted, setSubmitted] = useState("submitted");
    const [toggle, setToggle] = useState({});
    const [confirmPopUpVisible, setConfirmPopUpVisible] = useState(false);
    const [compentenciesRating, setCompentenciesRating] = useState([]);
    const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);

    useEffect(() => {
        if (_.isEmpty(projDetails)) {
            history.push("/dashboard");
        } else {
            const breadCrumb = [...BreadCrumbData];
            breadCrumb[1].title = projResults.projectName;
            setBreadCrumbData(breadCrumb);
            if (projDetails.finishSetupRater) {
                setSubmitted("submitted");
            } else {
                setSubmitted("");
            }
            if (projDetails.competencyModels && projDetails.rateSelf) {
                let draftCompentencies = {};
                const compentencyModels = Object.values(projDetails.competencyModels);

                compentencyModels.map((levelOfProficiencyModels, i) => {
                    projDetails.rateSelf.map((rateSelf) => {
                        if (levelOfProficiencyModels.id === rateSelf.projectCompetencyId) {
                            draftCompentencies[i] = rateSelf;
                        }
                    });
                    return null;
                });
                setCompentenciesRating(draftCompentencies);
                console.log(draftCompentencies, "draftCompentencies");
            } else {
                if (!_.isEmpty(projCompetency)) {
                    setCompentenciesRating(projCompetency);
                }
            }
        }
    }, []);

    const onToggleConfirmPopUp = () => {
        const rateCompleted = Object.keys(compentenciesRating).length === projDetails.competencyModels.length;
        const noRater = projRaters.length === 0;
        console.log(rateCompleted);

        if (rateCompleted && !noRater) {
            setConfirmPopUpVisible(!confirmPopUpVisible);
        }

        if (noRater) {
            props.alertStatus({ failed: true, msg: `Requires minimum 1 rater` });
            return;
        }

        if (!rateCompleted) {
            props.alertStatus({ failed: true, msg: `Requires fill up all the self rating` });
            return;
        }
    };
    const onToggleCollapse = (toggleKey) => {
        const closeAllToggleTab = _.mapValues(toggle, () => false);
        setToggle({
            ...closeAllToggleTab,
            [toggleKey]: !toggle[toggleKey],
        });
    };

    const onRateCompentencies = (data, i) => {
        const compentencies = { ...compentenciesRating, [i]: data };

        console.log(data, " RATE");
        console.log(i, "i");
        console.log(compentencies, "compentencies");

        setCompentenciesRating(compentencies);

        props.setCompentency(compentencies);
    };

    const saveAsDraft = async (callback) => {
        const raters = projRaters.map((o) => {
            return {
                email: o.email,
                name: o.name,
                otherDescription: o.description || null,
                roleId: o.category.id,
                roleName: o.category.value,
            };
        });
        const compentenciesRatingFlatten = Object.values(compentenciesRating);
        const rateSelf = compentenciesRatingFlatten.map((o) => {
            return {
                projectCompetencyId: o.projectCompetencyId,
                score: o.score,
            };
        });
        console.log(rateSelf, "rateSelf");
        const payload = {
            projectId: projDetails.projectId,
            rateSelf: rateSelf,
            raters: raters,
        };
        console.log(payload, "payload");
        try {
            const resp = await api_Req.user.post.participantSaveDraft(payload);
            if (resp) {
                setCompentenciesRating(rateSelf);
                // props.setProjDetails()
                props.setProjDetailsRateSelf(rateSelf);

                props.setCompentency(rateSelf);
            }
            if (resp)
                if (typeof callback === "function") {
                    callback();
                } else {
                    props.alertStatus({ msg: "Saved!" });
                }
        } catch (err) {
            props.alertStatus({ failed: true, msg: "Failed to save!" });
        }
    };

    const onSubmit = () => {
        onToggleConfirmPopUp();
        saveAsDraft(async () => {
            try {
                const resp = await api_Req.user.post.participantOnSubmit(projDetails.projectId);
                if (resp) {
                    props.alertStatus();
                    setTimeout(() => {
                        history.goBack(null);
                    }, 2500);
                }
            } catch (err) {
                props.alertStatus({ failed: true, msg: err });
            }
        });
    };

    return (
        <Layout>
            {console.log(projDetails, "projdetails")}
            {projDetails ? (
                <Fragment>
                    <div className="participant-rate-yourself-container">
                        <div className="header-container">
                            <BreadCrumb data={BreadCrumbData} />
                            <div className="header-btn-container">
                                <Button title="Save Draft" onClick={saveAsDraft} disabled={submitted} />
                                <Button outline title="Submit" onClick={onToggleConfirmPopUp} disabled={submitted} />
                            </div>
                        </div>
                        <Card>
                            <div className="card-row card-row-1">
                                <div className="column">
                                    <h3 className="title">Nomination Validity Period</h3>
                                    <p className="date">
                                        {" "}
                                        {formatDate(projDetails.startNominationPeriod)} -{" "}
                                        {formatDate(projDetails.endNomiationPeriod)}
                                    </p>
                                </div>
                                <div className="column">
                                    <ul className={`step-container ${submitted}`}>
                                        <li className="completed">Step 1</li>
                                        <div className="diviver" />
                                        <li className="active">Step 2</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="card-row">
                                <div className="column full-column">
                                    <p className="title">Self Ratings</p>
                                    {projDetails.competencyModels &&
                                        projDetails.competencyModels.map((o, i) => {
                                            const index = i + 1;
                                            const toggleKey = `${index}Collapse`;
                                            const arrowIcon = toggle[toggleKey] ? iosArrowUp : iosArrowDown;
                                            const lastChild = projDetails.competencyModels.length === i + 1;
                                            const rateLevel = o.totalLevel;
                                            let compentenciesDot = [];
                                            let collapseCompentenciesDesc = [];

                                            o.levelOfProficiencyModels.map((j, k) => {
                                                const compentenciesRated = compentenciesRating[i]
                                                    ? compentenciesRating[i]
                                                    : 0;

                                                const lvProficiencyIndex = k + 1;
                                                const active = compentenciesRated.score === j.level ? "active" : "";
                                                const projectCompetencyId = o.id;
                                                const score = j.level;
                                                const compentencyData = { projectCompetencyId, score };
                                                compentenciesDot.push(
                                                    <div
                                                        key={`${k}_proficiency_column_no`}
                                                        style={{
                                                            pointerEvents: submitted ? "none" : "auto",
                                                        }}
                                                        className={`compentencies-info-no ${active}`}
                                                        onClick={() => onRateCompentencies(compentencyData, i)}
                                                    >
                                                        {k + 1}
                                                    </div>
                                                );

                                                collapseCompentenciesDesc.push(
                                                    <div className="proficiency-container" key={`${k}_proficiency_column`}>
                                                        <div className="question-no">{lvProficiencyIndex}</div>
                                                        <div className="question">{j.description}</div>
                                                    </div>
                                                );
                                                return null;
                                            });

                                            return (
                                                <Fragment key={`${index}_collapse_column`}>
                                                    <div
                                                        onClick={() => onToggleCollapse(toggleKey)}
                                                        className={`compentencies-column ${lastChild ? "no-border" : ""}`}
                                                    >
                                                        <div className="compentencies-accordion-tab">
                                                            <div className="compentencies-info-wrapper">
                                                                <div className="desc">
                                                                    {o.name} ({o.abbreviation})
                                                                </div>
                                                                <div className="desc-2">{o.definition}</div>
                                                            </div>
                                                            <div className="compentencies-info-wrapper">
                                                                <div className="compentencies-rate-wrapper">
                                                                    {compentenciesDot}
                                                                </div>
                                                                <div className="compentencies-arrow">
                                                                    <Icon icon={arrowIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Collapse isOpen={toggle[toggleKey]} className="compentencies-collapse">
                                                        <div className="desc">Description of levels of Proficiency</div>
                                                        {collapseCompentenciesDesc}
                                                    </Collapse>
                                                </Fragment>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="footer-btn-container">
                                <Button
                                    title={submitted ? "View Added Raters" : "Back"}
                                    className={submitted ? "view-rating-btn" : ""}
                                    onClick={() => history.goBack()}
                                />
                            </div>
                        </Card>
                    </div>
                    <PopUp
                        buttonT1="Confirm"
                        buttonT2="Cancel"
                        className="participant-submit-pop-up-container"
                        visible={confirmPopUpVisible}
                        buttonT2Click={onToggleConfirmPopUp}
                        buttonT1Click={onSubmit}
                    >
                        <h1 className="title">Confirm to Submit</h1>
                        <p className="desc">
                            once confirmed, the information no-longer able to edit, and the invitation email will send to all
                            the raters.
                        </p>
                    </PopUp>
                </Fragment>
            ) : null}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        projDetails: state.participantReducer.projDetails,
        projResults: state.participantReducer.results,
        projRaters: state.participantReducer.raters,
        projCompetency: state.participantReducer.competency,
    };
};

const mapDispatchToProps = (dispatch) => ({
    initRaters: (data) => dispatch({ type: actions.INIT_ADD_RATERS, data }),
    setRaters: (data) => dispatch({ type: actions.SET_RATERS, data }),
    setProjDetails: (data) => dispatch({ type: actions.SET_PROJ_DETAILS, data }),
    setProjDetailsRateSelf: (data) => dispatch({ type: actions.SET_PROJ_DETAILS_RATESELF, data }),
    setCompentency: (data) => dispatch({ type: actions.SET_COMPENTENCY, data }),
    alertStatus: (data) => dispatch({ type: actions.TOGGLE_ALERT, data }),
});

export const ParticipantRateYourselfPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(WithInterceptor(RateYourselfConnectPage));
