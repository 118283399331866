import React from "react";
import { PopUp } from "../../../../components";
import "./firstTimePopUp.scss";

export const FirstTimePopUp = ({
  visible,
  rateNow,
  later,
  profile,
  project,
  message
}) => {
  if (profile) {
    return (
      <PopUp
        buttonT1="Add Rater Now"
        buttonT2="Later"
        className="dashboard-pop-up-container"
        visible={visible}
        toggle={later}
        buttonT2Click={later}
        buttonT1Click={rateNow}
      >
        <h1 className="title">Hi, {profile.firstName}</h1>
        <p className="desc">
          Thanks for accepting the invitation from {project.clientName} for{" "}
          {project.projectName}.
        </p>
        <p className="desc" style={{ wordBreak: " break-word" }}>
          {message}
          {/* Add the raters to provide the rating of your competencies. The rating
          results can helps to create development plan. */}
        </p>
        <p className="desc">Have a good day!</p>
      </PopUp>
    );
  } else {
    return null;
  }
};
