import React from "react";
import Icon from "react-icons-kit";
import { iosArrowDown } from "react-icons-kit/ionicons";

import { PopUp } from "../../../../components";
import "./guidePopUp.scss";

export const GuidePopUp = ({ visible, rateNow, later }) => {
  return (
    <PopUp
      buttonT1="Got it"
      className="dashboard-guide-pop-up-container"
      visible={visible}
      toggle={later}
      buttonT1Click={rateNow}
    >
      <p className="title">Few steps to complete the section.</p>
      <div className="sub-title-container">
        <p className="desc">Step 1</p>
        <p className="desc">
          Add rater entering the rater category, name, and email.
        </p>
      </div>
      <div className="desc-container active">
        <div className="step-no">1</div>
        <div className="box-wrapper">
          <div className="box-container">
            <div className="box-input">
              <p className="label">Category</p>
              <div className="box">
                Boss<Icon icon={iosArrowDown} />
              </div>
            </div>
          </div>
          <div className="box-container">
            <div className="box-input">
              <p className="label">Name</p>
              <div className="box">Ang Koh Hin</div>
            </div>
            <div className="box-input">
              <p className="label">Email</p>
              <div className="box">akh@gmail.com</div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-title-container">
        <p className="desc">Step 2</p>
        <p className="desc">Rate your self and submit</p>
      </div>
      <div className="desc-container">
        <div className="step-no">2</div>
        <div className="content-wrapper">
          <div className="content-container">
            <p className="title">
              Analytical (Analytical) <Icon icon={iosArrowDown} />
            </p>
            <p className="desc">
              Problem Analysis, Critical Thinking, Decision Making, Innovation &
              Professional Expertise.
            </p>
          </div>
          <div className="rating-container">
            <div className="step-no">1</div>
            <div className="step-no">2</div>
            <div className="step-no">3</div>
            <div className="step-no">4</div>
            <div className="step-no">5</div>
          </div>
        </div>
      </div>
    </PopUp>
  );
};
