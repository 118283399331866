import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { eye } from "react-icons-kit/entypo/eye";
import Icon from "react-icons-kit";
import queryString from "query-string";

/* IMPORT api_Req FOR API REQUEST */
import { api_Req, WithInterceptor } from "../../../common/services";
/* IMPORT actions FOR REDUX */
import { actions } from "../../../redux/actions/actions";
import { Button, PopUp } from "../../../components";
import nhaLogo from "../../../assets/img/nha-logo.png";
import "./login.scss";

class ClientLoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eyePasswordHidden: true,
            modal: false,
            successRequestResetPopup: false,
            invalidPassword: false,
        };
    }

    componentDidMount() {
        this.props.logout();
        localStorage.clear();
        const { location } = this.props;
        const parsed = queryString.parse(location.search);
        this.setState({ parsed });
    }

    handleSubmit = async (values) => {
        const { history } = this.props;
        this.setState({ invalidPassword: false });
        if (values.username && values.password) {
            const payload = {
                email: values.username,
                password: values.password,
                asClient: true,
            };
            try {
                const resp = await api_Req.user.post.login(payload);
                this.props.setProfileToken(resp.data);
                this.props.fetchProfile(() => {
                    setTimeout(() => {
                        history.push("client/dashboard");
                    }, 100);
                });
            } catch (err) {
                this.setState({ invalidPassword: true });
                // alert(err);
            }
        }
    };

    togglePassword = () => {
        this.setState((prevState) => ({
            eyePasswordHidden: !prevState.eyePasswordHidden,
        }));
    };

    modalPopup = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };
    resetLinkhandleSubmit = async (values) => {
        const { history } = this.props;

        const payload = {
            email: values.email,
        };
        try {
            const resp = await api_Req.user.post.requestResetPassword(payload);
            console.log(resp, "respreset");
            this.modalPopup();
            this.successRequestResetPopup();
        } catch (err) {
            alert(err);
        }
    };
    successRequestResetPopup = () => {
        this.setState((prevState) => ({
            successRequestResetPopup: !prevState.successRequestResetPopup,
        }));
    };
    render() {
        return (
            <div className="main-container">
                <div className="client-login-container">
                    <PopUp
                        className="client-login-forgot-password-popup"
                        visible={this.state.modal}
                        toggle={this.modalPopup}
                    >
                        <h3 className="title">Forgot your password?</h3>
                        <h3 className="desc">
                            Enter your email address below and we'll send
                            <br />
                            You a link to reset your password.
                        </h3>
                        <h3 className="desc">Email</h3>
                        <Formik
                            initialValues={{
                                email: "",
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email().label().required("Wrong email format"),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.resetLinkhandleSubmit(values);
                                console.log("submitted");
                            }}
                        >
                            {(props) => {
                                const {
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    values,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="formStyle">
                                        <div className="icon_container">
                                            <input
                                                id="email"
                                                type="text"
                                                name="email"
                                                placeholder="E.g. xxxxx@gmail.com"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                className={errors.email && touched.email ? "text-input error" : "text-input"}
                                            />
                                        </div>
                                        {errors.email && touched.email && (
                                            <div className="input-feedback">{errors.email}</div>
                                        )}
                                        <Button type="submit" title="Send reset link" disabled={isSubmitting} />
                                    </form>
                                );
                            }}
                        </Formik>
                    </PopUp>
                    <PopUp
                        visible={this.state.successRequestResetPopup}
                        toggle={this.successRequestResetPopup}
                        buttonT1="Ok"
                        buttonT1Click={() => {
                            this.successRequestResetPopup();
                        }}
                        className="success-request-reset-popup"
                    >
                        <h3 className="title"> Reset password</h3>
                        <h3 className="desc">
                            {" "}
                            Password reset request successful.
                            <br />
                            Please check your mailbox.
                        </h3>
                    </PopUp>
                    <div className="logo">
                        <img className="logo-img" src={nhaLogo} alt="nha" />
                    </div>
                    <div className="login-card">
                        <h4>Login to your account</h4>
                        <Formik
                            initialValues={{
                                username: "",
                                password: "",
                            }}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().label().min(5, "Minimum 5 characters").required("Wrong email format"),
                                password: Yup.string()
                                    .required("Invalid password")
                                    .min(6, "Minimum 6 character password")
                                    .max(20, "Try a shorter password."),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.handleSubmit(values);
                            }}
                        >
                            {(props) => {
                                const {
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    values,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="formStyle">
                                        <div className="icon_container">
                                            <input
                                                id="username"
                                                type="text"
                                                name="username"
                                                placeholder="Email"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                className={
                                                    errors.username && touched.username ? "text-input error" : "text-input"
                                                }
                                            />
                                        </div>
                                        {errors.username && touched.username && (
                                            <div className="input-feedback">{errors.username}</div>
                                        )}
                                        <div className="icon_container">
                                            <Icon icon={eye} size={20} onClick={this.togglePassword} />
                                            <input
                                                id="password"
                                                type={this.state.eyePasswordHidden ? "password" : "text"}
                                                name="password"
                                                placeholder="Password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                className={
                                                    errors.password && touched.password ? "text-input error" : "text-input"
                                                }
                                            />
                                        </div>
                                        {(errors.password && touched.password) || this.state.invalidPassword ? (
                                            <div className="input-feedback">{errors.password || "Invalid password"}</div>
                                        ) : null}

                                        <div
                                            className="forget-password-container"
                                            onClick={() => {
                                                this.modalPopup();
                                            }}
                                        >
                                            Forget password?
                                        </div>

                                        <div className="button-container">
                                            <Button type="submit" title="LOGIN" disabled={isSubmitting} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    setProfileToken: (data) => {
        dispatch({ type: actions.SET_USER_PROFILE_AUTH, data });
    },
    fetchProfile: (data) => {
        dispatch({ type: actions.FETCH_USER_PROFILE, data });
    },
    resetAllState: () => {
        dispatch({ type: actions.INIT_USER_PROFILE });
    },
    logout: () => dispatch({ type: actions.LOGOUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(ClientLoginPage));
