import React, { Component } from "react";

import { Card, CardBody } from "reactstrap";
import _ from "lodash";

/* IMPORT api_Req FOR API REQUEST */
import { actions } from "../../../redux/actions/actions";
import { api_Req, WithInterceptor } from "../../../common/services";
import { Layout, SortBar } from "../../../components";
import addnow from "../../../assets/icons/No-project.png";
import "./clientDashboard.scss";

const sortByDropdown = [
    {
        label: "Most Recent",
        data: "createdTime,desc",
    },

    {
        label: "New",
        data: "0",
    },
    {
        label: "Completed",
        data: "1",
    },
];

class ClientDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            sortDropdownVisible: false,

            project: {},
            dropdownOpen: false,
            setDropdownOpen: false,
            disabled: true,
            sortBy: sortByDropdown[0],
        };
    }

    componentDidMount() {
        this.getProjectList();
    }

    getProjectList = (size, name) => {
        const { page, sortBy } = this.state;
        const sortData = sortBy.data && sortBy.data === "mosecent";

        const params = {
            page: size ? 0 : page,
            size: size || 999995,
            sort: "createdTime,desc",
        };

        if (name) params.name = name;
        if (sortData) params.sort = sortBy.data;

        api_Req.user.get
            .clientprojList(params)
            .then((resp) => {
                if (size) {
                    this.setState({
                        project: resp.data,
                    });
                } else {
                    if (!resp.data.content) return;
                    this.setState((prevState) => ({
                        project: {
                            ...prevState.project,
                            ...resp.data,
                            content: prevState.project.content
                                ? [...prevState.project.content, ...resp.data.content]
                                : resp.data.content,
                        },
                    }));
                }
            })
            .catch((err) => {
                // alert(err);
            });
    };

    setDropdownValue = (values) => {
        console.log("[DROP DOWN VALUE]:", values);
        this.setState({ sortBy: values });
    };

    toggleSortingDropdown = (sortBy) => {
        const { sortBy: stateSortBy } = this.state;
        if (sortBy.data === stateSortBy.data) return;
        this.setState(
            (prevState) => ({
                sortDropdownVisible: !prevState.sortDropdownVisible,
                sortBy: sortBy,
                page: 0,
            }),
            () => {
                this.getProjectList(6);
            }
        );
    };
    handleSearch = (val, e) => {
        const { project } = this.state;
        switch (e) {
            case "Enter":
                this.getProjectList(project.content.length, val);
                break;
            case "Backspace":
                if (val.length <= 1) {
                    this.getProjectList(6);
                }
                break;
            default:
                return;
        }
    };

    render() {
        const { history } = this.props;
        const { project } = this.state;
        const sortedProjectContent =
            this.state.sortBy.label === "Most Recent"
                ? project && project.content
                : this.state.sortBy.label === "New"
                ? _.reverse(_.orderBy(project && project.content, Number, ["desc"]))
                : _.orderBy(project && project.content, Number, ["desc"]);

        return (
            <Layout>
                <div className="client-dashboard-container">
                    <SortBar
                        dropdownData={sortByDropdown}
                        setDropdownValue={this.toggleSortingDropdown}
                        handleSearchKeyDown={this.handleSearch}
                    />

                    <div className="card-container">
                        {!_.isEmpty(project) &&
                            sortedProjectContent.map((item, index) => {
                                const greenColor = "#488403";
                                const yellowColor = "#BEBD32";
                                const disabled = item.disabled;
                                const status = item.projectStatus;
                                const lessThan50_or_equal = item.projectPercent <= 50;
                                const completed = status === 2;
                                const projectStatus = disabled
                                    ? "Disabled"
                                    : status === 0
                                    ? "New"
                                    : status === 1
                                    ? "Ongoing"
                                    : "Completed";
                                const statusColor = disabled
                                    ? "gray"
                                    : status === 0
                                    ? "#F3C731"
                                    : status === 1
                                    ? greenColor
                                    : "gray";
                                const progressColor = lessThan50_or_equal ? yellowColor : greenColor;
                                const buttonStatus = completed ? "Result" : "Check";

                                // projectStatus (0 = New, 1 = Ongoing, 2 = Completed)
                                return (
                                    <Card
                                        key={`${index}-card`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            history.location.pathname === "/client"
                                                ? history.push("client/client-add-participant-competency", {
                                                      project: item,
                                                  })
                                                : history.push("client-add-participant-competency", {
                                                      project: item,
                                                  });
                                        }}
                                    >
                                        <CardBody className="card-body">
                                            <div className="card-content">
                                                <p style={{ color: statusColor }}>{projectStatus}</p>
                                                <p>{item.projectName}</p>

                                                {status !== 0 && (
                                                    <div className="existing-project-class">
                                                        <p>{item.totalParticipants} participants</p>
                                                        <div className="bar-container">
                                                            <div className="bar">
                                                                <div
                                                                    style={{
                                                                        width: `${item.projectPercent}%`,
                                                                        backgroundColor: progressColor,
                                                                    }}
                                                                    className="bar-progress"
                                                                />
                                                                {item.projectPercent >= 50 && (
                                                                    <div
                                                                        className="clickable-status"
                                                                        style={{ backgroundColor: progressColor }}
                                                                    >
                                                                        {buttonStatus}
                                                                    </div>
                                                                )}
                                                                <div className="start-vertical-line" />
                                                                <div className="center-vertical-line" />
                                                                <div className="end-vertical-line" />
                                                            </div>
                                                            <div className="bar-labels-container">
                                                                <span>
                                                                    Participants
                                                                    <br />
                                                                    nominate raters
                                                                </span>
                                                                <span>
                                                                    Rating
                                                                    <br />
                                                                    start
                                                                </span>
                                                                <span>Completed</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {status === 0 && (
                                                    <div className="new-project-class">
                                                        <img src={addnow} className="addProjectIcon" />
                                                        <p>Start Project</p>
                                                    </div>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default WithInterceptor(ClientDashboardPage);
