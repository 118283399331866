import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Icon from "react-icons-kit";
import { iosCloseEmpty } from "react-icons-kit/ionicons/iosCloseEmpty";

import { Button } from "../";
import "./popUp.scss";

export default class PopUp extends Component {
  static defaultProps = {
    buttonT1Click: () => {},
    buttonT2Click: () => {}
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      children,
      visible,
      toggle,
      className,
      buttonT1,
      buttonT2,
      close,
      onClosed,
      backdrop,
      buttonT1Click,
      buttonT2Click
    } = this.props;

    return (
      <Modal
        isOpen={visible}
        onClosed={onClosed}
        toggle={toggle}
        backdrop={backdrop !== undefined ? false : "static"}
        className={`nha-pop-up-container ${className}`}
        contentClassName="nha-pop-up-content"
      >
        {close ||
          (toggle &&
            <ModalHeader className="nha-pop-up-header">
              <Icon
                icon={iosCloseEmpty}
                onClick={() => toggle("close")}
                size={30}
              />
            </ModalHeader>)}
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter className="nha-pop-up-footer">
          {buttonT1 && <Button title={buttonT1} onClick={buttonT1Click} />}
          {buttonT2 &&
            <Button outline title={buttonT2} onClick={buttonT2Click} />}
        </ModalFooter>
      </Modal>
    );
  }
}
