import { combineReducers } from "redux";
import { actions } from "../actions/actions";
import { clientReducer } from "./clientReducer";

import { userProfileReducer } from "./userProfileReducer";
import { participantReducer } from "./participantReducer";
import { alertStatusReducer } from "./alertStatusReducer";
import { raterRatingReducer } from "./raterRatingReducer";

const AppReducer = combineReducers({
  clientReducer,
  userProfileReducer,
  participantReducer,
  alertStatusReducer,
  raterRatingReducer
});

const RootReducer = (state, action) => {
  if (action.type === actions.LOGOUT) {
    state = undefined;
  }
  return AppReducer(state, action);
};
export { RootReducer };
