import AdminLoginPage from "./admin/login/login";
import AdminDashboardPage from "./admin/dashboard/dashboard";
import AdminProfilePage from "./admin/profile/profile";
import LoginPage from "./login/login";
import ClientLoginPage from "./client/login/login";
import SignupPage from "./signup/signup";
import ResetPasswordPage from "./reset-password/reset-password";
import DashboardPage from "./user/dashboard/dashboard";
import ParticipantResultChartsPage from "./user/participant/results-chart/results-chart";
import ParticipantResultPage from "./user/participant/result-list/result-list";
import { ParticipantAddRatersPage } from "./user/participant/add-raters/add-raters";
import { ParticipantRateYourselfPage } from "./user/participant/rate-yourself/rate-yourself";
import RaterRatingPage from "./user/rater/rating/rating";
import { RaterThankYouPage } from "./user/rater/raterThanks/raterThanks";

/* HR */
import ClientDashboardPage from "./client/dashboard/clientDashboard";
import ClientResultChartListPage from "./client/result-chart-list/result-chart-list";
import ClientAddParticipantCompetencyPage from "./client/add-participant-competency/add-participant-competency";
import { ClientOverloadListPage } from "./client/overload-list/overload-list";

/* ADMIN */
const AdminLogin = AdminLoginPage;
const AdminDashboard = AdminDashboardPage;
const AdminProfile = AdminProfilePage;
const Signup = SignupPage;

/* CLIENT */
const ClientLogin = ClientLoginPage;
const Dashboard = DashboardPage;
const ClientDashboard = ClientDashboardPage;
const ClientResultChartList = ClientResultChartListPage;
const ClientAddParticipantCompetency = ClientAddParticipantCompetencyPage;
const ClientOverloadList = ClientOverloadListPage;

/* PARTICIPANT */
const ParticipantResultCharts = ParticipantResultChartsPage;
const ParticipantResult = ParticipantResultPage;
const ParicipantAddRaters = ParticipantAddRatersPage;
const ParticipantRateYourself = ParticipantRateYourselfPage;

/* RATER */
const RaterRating = RaterRatingPage;
const RaterThankYou = RaterThankYouPage;

/* PARTICIPANT AND RATER */
const Login = LoginPage;
/* PARTICIPANT AND RATER AND CLIENT */
const ResetPassword = ResetPasswordPage;

export {
    AdminLogin,
    AdminDashboard,
    AdminProfile,
    Login,
    Signup,
    ResetPassword,
    Dashboard,
    ClientDashboard,
    ClientLogin,
    ClientResultChartList,
    ClientAddParticipantCompetency,
    ClientOverloadList,
    ParticipantResultCharts,
    ParticipantResult,
    ParicipantAddRaters,
    ParticipantRateYourself,
    RaterRating,
    RaterThankYou,
};
