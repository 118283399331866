import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import {
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Icon from "react-icons-kit";
import { iosArrowDown } from "react-icons-kit/ionicons";
import { search } from "react-icons-kit/icomoon/search";
import sortby from "../../assets/icons/Sort-by.png";
import "./sortBar.scss";

const sortByDropdownData = [
  {
    label: "Most Recent",
    data: "most-recent"
  },
  {
    label: "Pinned Project",
    data: "pinFlag,desc"
  },
  {
    label: "Active",
    data: "0"
  },
  {
    label: "Completed",
    data: "1"
  }
];

export const SortBarComponent = forwardRef((props, ref) => {
  const dropdownData = props.dropdownData
    ? props.dropdownData
    : sortByDropdownData;
  const [searchValue, setSearchValue] = useState("");
  const [sortByData, setSortByData] = useState(dropdownData[0]);
  const [sortDropdown, setSortDropdown] = useState(false);

  useEffect(
    () => {
      props.setDropdownValue(sortByData);
    },
    [sortByData]
  );

  useImperativeHandle(ref, () => ({
    resetDropdown() {
      setSortByData(dropdownData[0]);
    }
  }));

  const toggleDropdown = e => {
    const name = e.currentTarget.name;
    if (!name) {
      setSortDropdown(!sortDropdown);
    } else {
      setSortDropdown(!sortDropdown);
      setSortByData(dropdownData.filter(o => o.data === name)[0]);
    }
  };

  const handleSearch = e => {
    const text = e.currentTarget.value;
    setSearchValue(text);
  };

  const handleSearchKeyDown = e => {
    if (typeof props.handleSearchKeyDown === "function") {
      props.handleSearchKeyDown(searchValue, e.key);
    }
  };

  return (
    <div className={`sort-bar-container ${props.disabled ? "disabled" : ""}`}>
      {!props.hideProjects && <p className="title">Projects</p>}
      <div className="search-container">
        <div className="sort-by-input">
          <Label>Sort By</Label>
          <img
            alt="sort"
            src={sortby}
            className="sort-by-icon"
            onClick={toggleDropdown}
          />
          <Dropdown isOpen={sortDropdown} toggle={toggleDropdown}>
            <DropdownToggle>
              {sortByData.label} <Icon icon={iosArrowDown} />
            </DropdownToggle>
            <DropdownMenu>
              {dropdownData.map((o, i) => {
                return (
                  <DropdownItem name={o.data} key={`${i}_dropdown`}>
                    {o.label}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="search-input">
          <Input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
          />
          <Icon icon={search} size={16} />
        </div>
        {/* <img
          src={add}
          className="add-icon"
          onClick={props.onAddProj}
          alt="add-icon"
        /> */}
      </div>
    </div>
  );
});
