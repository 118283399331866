import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { Icon } from "react-icons-kit";
import { sort } from "react-icons-kit/fa/sort";
import { ic_remove_red_eye } from "react-icons-kit/md/ic_remove_red_eye";
import _ from "lodash";

import "./participantTable.scss";

export const ParticipantTableComponent = ({
  tableTBodyData,
  tableTHeadData,
  onClick,
  participantChangesCheckboxData
}) => {
  const [tableBodyData, setTableBodyData] = useState();
  const [sorting, setSorting] = useState();

  const [checkedParticipantListData, setCheckedParticipantListData] = useState(
    []
  );

  const [checkedAllUncheckedAllflag, setCheckedAllUncheckedAllflag] = useState(
    2
  );

  const handleChangeAllFlag = (e, data) => {
    const isChecked = e.target.checked;
    if (isChecked === true) {
      setCheckedAllUncheckedAllflag(1);
    }
    if (isChecked === false) {
      setCheckedAllUncheckedAllflag(0);
    }
    setCheckedParticipantListData([]);
  };

  const handleChange = (e, data) => {
    const isChecked = e.target.checked;
    if (isChecked === true) {
      setCheckedParticipantListData(
        checkedParticipantListData.filter(
          item => item.participantId !== data.participantId
        )
      );
      setCheckedParticipantListData(checkedParticipantListData => [
        ...checkedParticipantListData,
        { participantId: data.participantId, active: 1 } //adds participant id into array
      ]);
    }
    if (isChecked === false) {
      setCheckedParticipantListData(
        checkedParticipantListData.filter(
          item => item.participantId !== data.participantId
        )
      );
      setCheckedParticipantListData(checkedParticipantListData => [
        ...checkedParticipantListData,
        { participantId: data.participantId, active: 0 } //adds participant id into array
      ]);
    }
  };

  useEffect(() => {
    setTableBodyData(tableTBodyData);
  }, [tableTBodyData]);

  const renderTableBody = () => {
    if (!tableBodyData) return;
    return tableBodyData.map((data, i) => {
      const keys = Object.keys(data);
      const index = i + 1;

      const showTrueFalseBasedOnFlag = data => {
        if (data === 0) {
          return false;
        }
        if (data === 1) {
          return true;
        }
      };
      return (
        <tr key={`${i}_tr`}>
          <td style={{ textAlign: "center" }}>{index}</td>

          {tableTHeadData.map((thead, j) => {
            const index = keys.indexOf(thead.key);
            if (thead.key === "Competency Target Lvl") {
              return (
                <td key={`${thead.key}-${j}`}>
                  <Icon
                    size={20}
                    className="participant-table-eye-icon"
                    icon={ic_remove_red_eye}
                    onClick={() => onClick(data)}
                  />
                </td>
              );
            }
            if (thead.key === keys[index]) {
              if (thead.key === "doneFlag") {
                if (data[keys[index]] === 0) {
                  return (
                    <td key={`${thead.key}-${j}`} style={{ color: "#D9AD6F" }}>
                      Ongoing
                    </td>
                  );
                }
                if (data[keys[index]] === 1) {
                  return (
                    <td key={`${thead.key}-${j}`} style={{ color: "#79945A" }}>
                      Done
                    </td>
                  );
                }
                if (data[keys[index]] === 2) {
                  return (
                    <td key={`${thead.key}-${j}`} style={{ color: "9C9C9D" }}>
                      Pending
                    </td>
                  );
                }
              }
              if (thead.key === "active") {
                const isChangesArrayEmpty =
                  checkedParticipantListData.length < 1;

                return (
                  <td
                    key={`${thead.key}_${j}_${i}_${data.participantId}`}
                    className={`${thead.key}_class`}
                    style={{ textAlign: "end" }}
                  >
                    <input
                      className={`${thead.key}_${j}_${i}`}
                      type="checkbox"
                      checked={
                        checkedAllUncheckedAllflag === 2
                          ? isChangesArrayEmpty
                            ? showTrueFalseBasedOnFlag(data[keys[index]])
                            : checkedParticipantListData.find(
                                x => x.participantId === data.participantId
                              )
                            ? checkedParticipantListData.find(
                                x => x.participantId === data.participantId
                              ).active
                            : showTrueFalseBasedOnFlag(data[keys[index]])
                          : isChangesArrayEmpty
                          ? checkedAllUncheckedAllflag
                          : checkedParticipantListData.find(
                              x => x.participantId === data.participantId
                            )
                          ? checkedParticipantListData.find(
                              x => x.participantId === data.participantId
                            ).active
                          : checkedAllUncheckedAllflag
                      }
                      onChange={e => handleChange(e, data)}
                    />
                  </td>
                );
              } else
                return (
                  <td
                    key={`${thead.key}_${j}_${i}`}
                    className={`${thead.key}_class`}
                  >
                    {data[keys[index]]}
                  </td>
                );
            } else {
              return null;
            }
          })}
        </tr>
      );
    });
  };

  const renderTableHead = () => {
    if (!tableTHeadData) return;

    return tableTHeadData.map((tHead, i) => {
      if (tHead.label === "checkbox") {
        return (
          <th
            key={`${tHead.label}_${i}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            Select&nbsp;
            <input
              type="checkbox"
              checked={
                checkedAllUncheckedAllflag === 2
                  ? true
                  : checkedAllUncheckedAllflag === 1
                  ? true
                  : false
              }
              onChange={e => handleChangeAllFlag(e)}
            />
          </th>
        );
      }

      return (
        <th key={`${tHead.label}_${i}`}>
          {tHead.label}
          {tHead.label !== "Competency Target Lvl" && (
            <Icon icon={sort} onClick={() => sortData(tHead.key)} />
          )}
        </th>
      );
    });
  };

  const sortData = type => {
    setSorting(!sorting);
    const data = _.orderBy(
      tableBodyData,
      [a => a[type].toLowerCase()],
      [sorting ? "asc" : "desc"]
    );
    setTableBodyData(data);
  };

  return (
    <div className="table-wrapper">
      <Table>
        <thead>
          {participantChangesCheckboxData(
            checkedParticipantListData,
            checkedAllUncheckedAllflag
          )}

          <tr>
            <th>No.</th>
            {renderTableHead()}
            {/* <th>eye</th> */}
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
    </div>
  );
};
