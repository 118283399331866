import React, { PureComponent } from "react";
import _ from "lodash";
import "./button.scss";

export default class Button extends PureComponent {
  static defaultProps = {
    onClick: () => {}
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { type, title, disabled, outline, onClick } = this.props;
    const typeSubmit = type !== "submit";
    const outlineS = outline ? "outline" : "";
    const buttonClasses = `default-button ${outlineS}`;

    return (
      <button
        type={type}
        className={buttonClasses}
        disabled={disabled}
        onClick={
          typeSubmit
            ? _.debounce(onClick, 300)
            : typeSubmit && onClick ? onClick : undefined
        }
      >
        {title}
      </button>
    );
  }
}
