import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Card, Collapse } from "reactstrap";
import { Icon } from "react-icons-kit";
import { connect } from "react-redux";
import { iosArrowDown, iosArrowUp } from "react-icons-kit/ionicons";
import _ from "lodash";

import { api_Req, WithInterceptor } from "../../../../common/services";
import { actions } from "../../../../redux/actions/actions";
import { formatDate } from "../../../../common/helper/helpers";
import { Layout } from "../../../../components";
import { Button, BreadCrumb, Table } from "../../../../components";
import "./result-list.scss";

const InitialBreadCrumbData = [
  {
    title: "Project",
    routes: "/dashboard"
  },
  {
    title: "Organisation Name",
    routes: "",
    active: true
  }
];

export const tHeader = [
  {
    label: "Name",
    key: "name"
  },
  {
    label: "Email",
    key: "email"
  },
  {
    label: "Category",
    key: "roleName"
  }
];

const ParticipantResultPage = ({ history, selectProj, ...props }) => {
  const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);
  const [projDetails, setProjDetails] = useState();
  const [toggle, setToggle] = useState({});

  useEffect(() => {
    const breadCrumb = [...BreadCrumbData];
    breadCrumb[1].title = selectProj.projectName;
    setBreadCrumbData(breadCrumb);
    fetchProjDetails();
    console.log(selectProj);
  }, []);

  const fetchProjDetails = useCallback(
    async () => {
      try {
        const resp = await api_Req.user.get.participantProjResult(
          selectProj.id
        );
        props.setResultsDetails(resp.data);
        setProjDetails(resp.data);
      } catch (err) {
        history.push("/dashboard");
      }
    },
    [selectProj]
  );

  const onToggleCollapse = toggleKey => {
    const closeAllToggleTab = _.mapValues(toggle, () => false);
    setToggle({
      ...closeAllToggleTab,
      [toggleKey]: !toggle[toggleKey]
    });
  };

  return (
    <Layout>
      {projDetails
        ? <div className="participant-result-container">
            <div className="header-container">
              <BreadCrumb data={InitialBreadCrumbData} />
              <Button
                title="View Results"
                onClick={() => history.push("result-chart")}
              />
            </div>
            <Card>
              <div className="card-row">
                <div className="column">
                  <h3 className="title">Nomination Period</h3>
                  <p className="desc">
                    Period of normination for participants.
                  </p>
                  <p className="date">
                    {formatDate(projDetails.startNominationPeriod)}
                    {" - "} {formatDate(projDetails.endNominationPeriod)}
                  </p>
                </div>
                <div className="column">
                  <div className="column-header">
                    <div className="header-content">
                      <h3 className="title">Rater</h3>
                      <p className="desc">
                        The priority of raters will be follow the sequence you
                        added. (1 = Most prioritized)
                      </p>
                    </div>
                    <div className="header-content">
                      <p className="date">
                        Rating Period: {"     "}
                        {formatDate(projDetails.ratingStartDate)}
                        {"  -  "}
                        {formatDate(projDetails.ratingEndDate)}
                      </p>
                    </div>
                  </div>
                  <Table
                    tableTHeadData={tHeader}
                    tableTBodyData={projDetails.raters}
                  />
                </div>
              </div>
              <div className="card-row">
                <div className="column">
                  <p className="title">Self Ratings</p>
                  {projDetails.competencyModels &&
                    projDetails.competencyModels.map((o, i) => {
                      const index = i + 1;
                      const toggleKey = `${index}Collapse`;
                      const arrowIcon = toggle[toggleKey]
                        ? iosArrowUp
                        : iosArrowDown;
                      const lastChild =
                        projDetails.competencyModels.length === i + 1;
                      return (
                        <Fragment key={`${index}_collapse_column`}>
                          <div
                            className={`compentencies-column ${lastChild
                              ? "no-border"
                              : ""}`}
                            onClick={() => onToggleCollapse(toggleKey)}
                          >
                            <div className="compentencies-accordion-tab">
                              <div className="compentencies-info-wrapper">
                                <div className="desc">
                                  {o.name} ({o.abbreviation})
                                </div>
                                <div className="desc-2">
                                  {o.definition}
                                </div>
                              </div>
                              <div className="compentencies-info-wrapper">
                                <div className="compentencies-rate-wrapper">
                                  {o.levelOfProficiencyModels.map((j, k) => {
                                    const active =
                                      Math.round(o.selfRater.score) === k + 1
                                        ? "active"
                                        : "";
                                    return (
                                      <div
                                        key={`compentencies-info-no-${k}`}
                                        className={`compentencies-info-no ${active}`}
                                      >
                                        {k + 1}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="compentencies-arrow">
                                  <Icon icon={arrowIcon} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Collapse
                            isOpen={toggle[toggleKey]}
                            className="compentencies-collapse"
                          >
                            <div className="desc">
                              Description of levels of Proficiency
                            </div>
                            {o.levelOfProficiencyModels.map((j, k) => {
                              const lvProficiencyIndex = k + 1;
                              return (
                                <div
                                  className="proficiency-container"
                                  key={`${k}_proficiency_column`}
                                >
                                  <div className="question-no">
                                    {lvProficiencyIndex}
                                  </div>
                                  <div className="question">
                                    {j.description}
                                  </div>
                                </div>
                              );
                            })}
                          </Collapse>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            </Card>
          </div>
        : null}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    selectProj: state.participantReducer.results
  };
};

const mapDispatchToProps = dispatch => ({
  setResultsDetails: data =>
    dispatch({ type: actions.SET_COMPLETED_RESULT, data })
});

export default connect(mapStateToProps, mapDispatchToProps)(
  WithInterceptor(ParticipantResultPage)
);
