import React from "react";

const Error = ({ touched, message }) => {
  if (!touched) {
    return (
      <div
        style={{ color: "red", fontSize: 10 }}
        className="form-message invalid"
      >
        &nbsp;
      </div>
    );
  }
  if (message) {
    return (
      <div
        style={{ color: "red", fontSize: 10 }}
        className="form-message invalid"
      >
        {message}
      </div>
    );
  }
  return (
    <div
      style={{ color: "green", fontSize: 10 }}
      className="form-message valid"
    >
      Valid
    </div>
  );
};

export default Error;
