import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { Icon } from "react-icons-kit";
import { sort } from "react-icons-kit/fa/sort";
import _ from "lodash";
import { ic_remove_red_eye } from "react-icons-kit/md/ic_remove_red_eye";

import "./competencyTable.scss";

export const CompetencyTableComponent = ({
  tableTBodyData,
  tableTHeadData,
  onClick,
  competencyChangesCheckboxData
}) => {
  const [tableBodyData, setTableBodyData] = useState();
  const [sorting, setSorting] = useState();

  const [checkedParticipantListData, setCheckedParticipantListData] = useState(
    []
  );

  const [checkedAllUncheckedAllflag, setCheckedAllUncheckedAllflag] = useState(
    2
  );

  const handleChangeAllFlag = (e, data) => {
    const isChecked = e.target.checked;
    if (isChecked === true) {
      setCheckedAllUncheckedAllflag(1);
    }
    if (isChecked === false) {
      setCheckedAllUncheckedAllflag(0);
    }
    setCheckedParticipantListData([]);
  };

  const handleChange = (e, data) => {
    const isChecked = e.target.checked;
    if (isChecked === true) {
      setCheckedParticipantListData(
        checkedParticipantListData.filter(item => item.id !== data.id)
      );
      setCheckedParticipantListData(checkedParticipantListData => [
        ...checkedParticipantListData,
        { id: data.id, active: 1 } //adds participant id into array
      ]);
    }
    if (isChecked === false) {
      setCheckedParticipantListData(
        checkedParticipantListData.filter(item => item.id !== data.id)
      );
      setCheckedParticipantListData(checkedParticipantListData => [
        ...checkedParticipantListData,
        { id: data.id, active: 0 } //adds participant id into array
      ]);
    }
  };

  useEffect(() => {
    setTableBodyData(tableTBodyData);
  }, [tableTBodyData]);

  useEffect(() => {}, [checkedParticipantListData]);

  const renderTableBody = () => {
    if (!tableBodyData) return;
    return tableBodyData.map((data, i) => {
      const keys = Object.keys(data);
      const index = i + 1;

      const showTrueFalseBasedOnFlag = data => {
        if (data === 0) {
          return false;
        }
        if (data === 1) {
          return true;
        }
      };
      return (
        <tr key={`${i}_tr`}>
          <td style={{ textAlign: "center" }}>{index}</td>

          {tableTHeadData.map((thead, j) => {
            const index = keys.indexOf(thead.key);
            if (thead.key === "Description of Lvl") {
              return (
                <td key={`${thead.key}-${j}`}>
                  <Icon
                    size={20}
                    className="participant-table-eye-icon"
                    style={{ cursor: "pointer" }}
                    icon={ic_remove_red_eye}
                    onClick={() => onClick(data)}
                  />
                </td>
              );
            }
            if (thead.key === keys[index]) {
              if (thead.key === "active") {
                const isChangesArrayEmpty =
                  checkedParticipantListData.length < 1;

                return (
                  <td
                    key={`${thead.key}_${j}_${i}_${data.participantId}`}
                    className={thead.key}
                    style={{ textAlign: "end" }}
                  >
                    <input
                      className={`${thead.key}_${j}_${i}`}
                      type="checkbox"
                      checked={
                        checkedAllUncheckedAllflag === 2
                          ? isChangesArrayEmpty
                            ? showTrueFalseBasedOnFlag(data[keys[index]])
                            : checkedParticipantListData.find(
                                x => x.id === data.id
                              )
                            ? checkedParticipantListData.find(
                                x => x.id === data.id
                              ).active
                            : showTrueFalseBasedOnFlag(data[keys[index]])
                          : isChangesArrayEmpty
                          ? checkedAllUncheckedAllflag
                          : checkedParticipantListData.find(
                              x => x.id === data.id
                            )
                          ? checkedParticipantListData.find(
                              x => x.id === data.id
                            ).active
                          : checkedAllUncheckedAllflag
                      }
                      onChange={e => handleChange(e, data)}
                    />
                  </td>
                );
              } else
                return (
                  <td key={`${thead.key}_${j}_${i}`} className={thead.key}>
                    {data[keys[index]]}
                  </td>
                );
            } else {
              return null;
            }
          })}
        </tr>
      );
    });
  };

  const renderTableHead = () => {
    if (!tableTHeadData) return;

    return tableTHeadData.map((tHead, i) => {
      if (tHead.label === "checkbox") {
        return (
          <th
            key={`${tHead.label}_${i}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            Select&nbsp;
            <input
              type="checkbox"
              checked={
                checkedAllUncheckedAllflag === 2
                  ? true
                  : checkedAllUncheckedAllflag === 1
                  ? true
                  : false
              }
              onChange={e => handleChangeAllFlag(e)}
            />
          </th>
        );
      }

      return (
        <th key={`${tHead.label}_${i}`}>
          {tHead.label}
          {tHead.label !== "Description of Lvl" && (
            <Icon icon={sort} onClick={() => sortData(tHead.key)} />
          )}
        </th>
      );
    });
  };

  const sortData = type => {
    setSorting(!sorting);
    if (type === "totalLevel") {
      const data = _.orderBy(tableBodyData, Number, ["desc"]);
      setTableBodyData(data);
    } else {
      const data = _.orderBy(
        tableBodyData,
        [a => a[type].toLowerCase()],
        [sorting ? "asc" : "desc"]
      );
      setTableBodyData(data);
    }
  };

  return (
    <div className="table-wrapper">
      <Table>
        <thead>
          {competencyChangesCheckboxData(
            checkedParticipantListData,
            checkedAllUncheckedAllflag
          )}

          <tr>
            <th>No.</th>
            {renderTableHead()}
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
    </div>
  );
};
