import React from "react";
import { Layout, Button } from "../../../../components";
import queryString from "query-string";
import "./raterThanks.scss";

export const RaterThankYouPage = ({ history, location }) => {
  const parsed = queryString.parse(location.search);
  console.log(parsed);
  return (
    <Layout>
      <div className="rater-thanks-container">
        <h1 className="title">Thanks for the rating submission!</h1>
        <p className="desc">
          Complete the registration by adding the password to the account. So
          that you can always check back the details of previous or new rating
          requests.
        </p>
        {parsed.token && parsed.token !== "undefined"
          ? <Button
              title="Register Now"
              onClick={() =>
                history.push(
                  `/signup?token=${parsed.token}&isClient=false&name=${parsed.name}`
                )}
            />
          : null}
      </div>
    </Layout>
  );
};
