import React, { Component, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import PrivateRoute from "./privateRoute";
import { history } from "../common/helper/history";
import {
    AdminLogin,
    AdminDashboard,
    AdminProfile,
    ResetPassword,
    Signup,
    Login,
    Dashboard,
    ClientLogin,
    ClientDashboard,
    ClientResultChartList,
    ClientOverloadList,
    ClientAddParticipantCompetency,
    ParticipantResultCharts,
    ParticipantResult,
    ParicipantAddRaters,
    ParticipantRateYourself,
    RaterRating,
    RaterThankYou,
} from "../pages";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { profile } = this.props;

        return (
            <Router history={history}>
                <Switch>
                    <Route path="/login" component={Login} exact profile={profile} />
                    <Route path="/reset-password" component={ResetPassword} exact />

                    <Route path="/signup" component={Signup} exact />
                    <PrivateRoute path="/dashboard" component={Dashboard} exact />
                    <Route
                        path="/participant"
                        render={({ match: { path } }) => (
                            <Fragment>
                                <Route path={`${path}/add-raters`} component={ParicipantAddRaters} exact />
                                <Route path={`${path}/rate-yourself`} component={ParticipantRateYourself} exact />
                                <Route path={`${path}/result`} component={ParticipantResult} exact />
                                <Route path={`${path}/result-chart`} component={ParticipantResultCharts} exact />
                            </Fragment>
                        )}
                    />
                    <Route
                        path="/rater"
                        render={({ match: { path } }) => (
                            <Fragment>
                                <Route path={`${path}`} component={RaterRating} exact />
                                <Route path={`${path}/thank-you`} component={RaterThankYou} exact />
                            </Fragment>
                        )}
                    />
                    <Route
                        path="/admin"
                        render={({ match: { path } }) => (
                            <Fragment>
                                <Route path={`${path}/dashboard`} component={AdminDashboard} exact />
                                <Route path={`${path}/profile`} component={AdminProfile} exact />
                                <Route path={`${path}/`} component={AdminLogin} exact />
                            </Fragment>
                        )}
                    />
                    <Route
                        path="/client"
                        render={({ match: { path } }) => (
                            <Fragment>
                                <Route path={`${path}/dashboard`} component={ClientDashboard} exact />
                                <Route path={`${path}/client-result-chart-list`} component={ClientResultChartList} exact />
                                <Route path={`${path}/client-overload-list`} component={ClientOverloadList} exact />
                                <Route
                                    path={`${path}/client-add-participant-competency`}
                                    component={ClientAddParticipantCompetency}
                                    exact
                                />
                                <Route path={`${path}/`} component={ClientLogin} profile={profile} exact />
                            </Fragment>
                        )}
                    />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.userProfileReducer,
    };
};


export default connect(mapStateToProps, null)(Navigation);
