import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, Row, Col } from "reactstrap";

/* IMPORT api_Req FOR API REQUEST */
import { api_Req, WithInterceptor } from "../../../common/services";
import { actions } from "../../../redux/actions/actions";
import { Layout, SortBar, Footer } from "../../../components";
import { FirstTimePopUp, GuidePopUp, ResultCard } from "../components";
import "./dashboard.scss";

const dropdownData = [
  {
    label: "Most Recent",
    data: "most-recent"
  },
  {
    label: "New",
    data: "new"
  },
  {
    label: "On Going",
    data: "onGoing"
  },
  {
    label: "Completed",
    data: "completed"
  }
];

const DashboardPage = ({ history, userProfile, initRaters, ...props }) => {
  const sortBarRef = useRef();
  const [activeTab, setActiveTab] = useState("1");
  const [fTLoginPopUp, setFirstTimeLoginPopUp] = useState(false);
  const [guidePopUp, setGuidePopUp] = useState(false);
  const [sortBy, setSortBy] = useState(dropdownData[0]);
  const [project, setProject] = useState(false);
  const [fullListProject, setFullListProject] = useState(false);
  const [participantProject, setParticipantProject] = useState(false);

  useEffect(() => {
    const initial = () => {
      // const raterRole = userProfile.profile.hasRaterRole;
      const participantRole = userProfile.profile.hasParticipantRole;
      if (userProfile.profile.hasRaterRole) {
        if (!participantRole) {
          getRaterProjList();
          setActiveTab("2");
        }
      }
      if (participantRole) {
        getParticipantProjList();
      }
      initRaters();
    };
    initial();
  }, [initRaters]);

  useEffect(() => {
    firstTimeLoginPopUp();

    const proj = project && project.content && project.content.length > 0;

    if (proj && userProfile.profile) {
      // if (
      //   participantProject.content &&
      //   participantProject.content.filter(x => x.messageFlag === 0).length > 0
      // ) {
      console.log(
        participantProject.content &&
          participantProject.content.filter(x => x.messageFlag === 0).length >
            0,
        "testttt"
      );
      firstTimeLoginPopUp();
      // }

      // const firstTime = userProfile.profile.isFirstLoginFlag && proj;
      // if (firstTime) {
      //   firstTimeLoginPopUp();
      //   completedFirstTime();
      // }
    }
  }, []);

  useEffect(() => {
    const cloneProj = { ...fullListProject };
    switch (sortBy.data) {
      case "most-recent":
        setProject(fullListProject);
        break;
      case "new":
        const newProj = fullListProject.content.filter(
          o => o.projectStatus === 0
        );
        cloneProj.content = newProj;
        setProject(cloneProj);
        break;
      case "onGoing":
        const onGoingProj = fullListProject.content.filter(
          o => o.projectStatus === 1
        );
        cloneProj.content = onGoingProj;
        setProject(cloneProj);
        break;
      case "completed":
        const completedProj = fullListProject.content.filter(
          o => o.projectStatus === 2
        );
        cloneProj.content = completedProj;
        setProject(cloneProj);
        break;
      default:
        break;
    }
  }, [sortBy, fullListProject]);

  const firstTimeLoginPopUp = () => setFirstTimeLoginPopUp(!fTLoginPopUp);

  const showGuidePopUp = () => setGuidePopUp(!guidePopUp);

  const setDropdownValue = values => setSortBy({ ...values });

  const viewResult = c => {
    props.selectRatingResult(c);
    history.push("participant/result");
  };

  const addRaters = c => {
    props.selectRatingResult(c);
    history.push("participant/add-raters");
  };

  const rateNow = c => {
    props.resetRaterSelectedProj();
    props.selectRatingProj(c);
    history.push("rater");
  };

  const setTab = v => {
    switch (v) {
      case "2":
        getRaterProjList();
        break;
      default:
        getParticipantProjList();
        break;
    }
    setProject(false);
    sortBarRef.current.resetDropdown();
    setActiveTab(v);
  };

  const completedFirstTime = async () => {
    try {
      const resp = await api_Req.user.post.completedFirstTimeLogin();
      console.log(resp);
      props.fetchProfile();
    } catch (err) {
      console.log(err);
    }
  };

  const updateFirstTimeMessageFlag = async projId => {
    try {
      const resp = await api_Req.user.post.updateFirstTimeLogin(projId);
      console.log(resp);
      console.log("update");
      props.fetchProfile();
    } catch (err) {
      console.log(err);
    }
  };

  const getParticipantProjList = async (size, name) => {
    const params = {
      page: 0,
      size: size || 999999999
    };

    try {
      const resp = await api_Req.user.get.participantProjList(params);
      if (resp) {
        setProject(resp.data);
        setFullListProject(resp.data);
        setParticipantProject(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRaterProjList = async (size, name) => {
    const params = {
      page: 0,
      size: size || 999999
    };
    try {
      const resp = await api_Req.user.get.raterProjList(params);
      if (resp) {
        console.log(resp.data, " RATER DATA");
        setProject(resp.data);
      }
      setFullListProject(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (values, key) => {
    const cloneProj = { ...fullListProject };
    switch (key) {
      case "Backspace":
      case "Enter":
        if (!values || values.length === 1) {
          setProject(cloneProj);
        } else {
          const searchProjectItem = project.content.filter(
            o => o.projectName.toLowerCase().indexOf(values.toLowerCase()) >= 0
          );
          cloneProj.content = searchProjectItem;
          setProject(cloneProj);
        }
        break;
      default:
        break;
    }
  };

  const RenderTabs = () => {
    const { profile } = userProfile;
    if (profile) {
      const { hasParticipantRole, hasRaterRole } = profile;
      if (!hasParticipantRole || !hasRaterRole) {
        return null;
      } else {
        return (
          <Nav tabs style={{ marginTop: 70 }}>
            <NavItem
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setTab("1")}
            >
              As Participant
            </NavItem>
            <NavItem
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setTab("2")}
            >
              As Rater
            </NavItem>
          </Nav>
        );
      }
    } else {
      return null;
    }
  };

  const { profile } = userProfile;

  const { hasParticipantRole, hasRaterRole } = profile;
  return (
    <Layout disabledFooter>
      {console.log(project, "project")}
      <div className="participantdashboard-container">
        <SortBar
          ref={sortBarRef}
          dropdownData={dropdownData}
          setDropdownValue={setDropdownValue}
          handleSearchKeyDown={handleSearch}
          disabled={!project.content}
        />
        <RenderTabs />
        <TabContent
          activeTab={activeTab}
          style={{ marginTop: !hasParticipantRole || !hasRaterRole ? 70 : 0 }}
        >
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                {project &&
                  project.content.map((c, i) => {
                    return (
                      <ResultCard
                        key={`${c.id}_${i}`}
                        {...c}
                        viewResult={viewResult}
                        addRaters={addRaters}
                        role="participant"
                      />
                    );
                  })}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                {project &&
                  project.content.map((c, i) => {
                    return (
                      <ResultCard
                        key={`${c.id}_${i}`}
                        {...c}
                        rateNow={rateNow}
                        role="rater"
                      />
                    );
                  })}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        <Footer />
      </div>
      {participantProject &&
      participantProject.content &&
      participantProject.content.length > 0 &&
      participantProject.content &&
      participantProject.content.filter(x => x.messageFlag === 0).length > 0 ? (
        // participantProject.content.filter(x=>x.messageFlage===0).length >0?
        //need filter for  messageflag=0

        <FirstTimePopUp
          visible={fTLoginPopUp}
          later={() => {
            firstTimeLoginPopUp();
            updateFirstTimeMessageFlag(
              participantProject.content &&
                participantProject.content.filter(x => x.messageFlag === 0)[0]
                  .id
            );
          }}
          rateNow={() => {
            firstTimeLoginPopUp();
            if (userProfile.profile.isFirstLoginFlag === 1) {
              showGuidePopUp();
            } else {
              addRaters(
                participantProject.content &&
                  participantProject.content.filter(x => x.messageFlag === 0)[0]
              );
            }
            completedFirstTime();

            updateFirstTimeMessageFlag(
              participantProject.content &&
                participantProject.content.filter(x => x.messageFlag === 0)[0]
                  .id
            );
          }}
          profile={userProfile.profile}
          project={
            participantProject.content &&
            participantProject.content.filter(x => x.messageFlag === 0)[0]
          }
          message={
            participantProject.content &&
            participantProject.content.filter(x => x.messageFlag === 0)[0]
              .message
          }
        />
      ) : null}
      <GuidePopUp
        visible={guidePopUp}
        later={showGuidePopUp}
        rateNow={() => {
          showGuidePopUp();
          addRaters(participantProject.content[0]);
        }}
      />
      {console.log(
        participantProject.content &&
          participantProject.content.filter(x => x.messageFlag === 0)[0],
        // participantProject.content.filter(x => x.messageFlag === 0).length,
        "participantProject.content.filter(x=>x.messageFlage===0) and length"
      )}
    </Layout>
  );
};
const mapStateToProps = state => {
  return {
    userProfile: state.userProfileReducer
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProfile: data => dispatch({ type: actions.FETCH_USER_PROFILE, data }),
  initRaters: data => dispatch({ type: actions.INIT_ADD_RATERS, data }),
  resetRaterSelectedProj: data =>
    dispatch({ type: actions.INIT_RATER_RATING, data }),
  selectRatingProj: data => dispatch({ type: actions.RATER_SELECT_PROJ, data }),
  selectRatingResult: data =>
    dispatch({ type: actions.SELECT_COMPLETED_RESULT, data })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithInterceptor(DashboardPage));
