import React from "react";
import { connect } from "react-redux";
import { Navbar } from "reactstrap";
import Icon from "react-icons-kit";
import { withRouter, Link } from "react-router-dom";

import { actions } from "../../redux/actions/actions";
import nhalogo from "../../assets/img/nhalogo.png";
import bpmblogo from "../../assets/img/bpmp_logo.png";
import { logIn, logOut } from "react-icons-kit/feather";
import "./topBar.scss";

const TopBarComponent = ({ profile, history, ...props }) => {
    const isAdminRole = profile && profile.hasAdminRole;
    const logout = () => {
        const pathArray = history.location.pathname.split("/");
        const adminPath = pathArray.includes("admin");
        const clientPath = pathArray.includes("client");
        props.logout();
        localStorage.clear();
        if (adminPath) {
            history.replace("/admin");
        } else if (clientPath) {
            history.replace("/client");
        } else {
            history.replace("/login");
        }
    };

    const login = () => {
        history.replace("/login");
    };

    return (
        <Navbar className="nav-bar-container" id="sticky-header">
            <div className="nav-bar">
                {isAdminRole ? (
                    <Link
                        to={{
                            pathname: "/admin/dashboard",
                            state: { fromDashboard: true },
                        }}
                    >
                        <img src={props.theme === "bpmb-theme" ? bpmblogo : nhalogo} className="logo-img" alt="logo" />
                    </Link>
                ) : (
                    <img src={props.theme === "bpmb-theme" ? bpmblogo : nhalogo} className="logo-img" alt="logo" />
                )}
            </div>
            {profile ? (
                <div className="nav-bar">
                    <p className="profile-name">{profile.firstName}</p>
                    {isAdminRole ? (
                        <Link
                            to={{
                                pathname: "/admin/profile",
                                state: { fromDashboard: true },
                            }}
                        >
                            <p className="profile">Profile</p>
                        </Link>
                    ) : null}
                    <div className="logout-container" onClick={logout}>
                        <Icon icon={logOut} size={20} />
                        <p>Logout</p>
                    </div>
                </div>
            ) : (
                <div className="nav-bar">
                    <div className="logout-container" onClick={login}>
                        <Icon icon={logIn} size={20} />
                        <p>Login</p>
                    </div>
                </div>
            )}
        </Navbar>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.userProfileReducer.profile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch({ type: actions.LOGOUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBarComponent));
