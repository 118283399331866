import ButtonComponent from "./button/button";
import { LayoutComponent } from "./layout/layout";
import TopBarComponent from "./topBar/topBar";
import PopUpComponent from "./popUp/popUp";
import BreadCrumbComponent from "./breadCrumb/breadCrumb";
import { FooterComponent } from "./footer/footer";
import ResultTableComponent from "./resultTable/resultTable";
import { TableComponent } from "./table/table";
import { SortBarComponent } from "./sortBar/sortBar";
import PaginationComponent from "./pagination/pagination";
import TabSwitchComponent from "./tabSwitch/tabSwitch";
import StatusPopUpComponent from "./statusPopUp/statusPopUp";
import { LoadingComponent } from "./loading/loading";
import { AlertBarComponent } from "./alertBar/alertBar";
import { ParticipantTableComponent } from "./participantTable/participantTable";
import { CompetencyTableComponent } from "./competencyTable/competencyTable";

const Button = ButtonComponent;
const Layout = LayoutComponent;
const TopBar = TopBarComponent;
const PopUp = PopUpComponent;
const BreadCrumb = BreadCrumbComponent;
const Footer = FooterComponent;
const ResultTable = ResultTableComponent;
const Table = TableComponent;
const SortBar = SortBarComponent;
const Pagination = PaginationComponent;
const TabSwitch = TabSwitchComponent;
const StatusPopUp = StatusPopUpComponent;
const Loading = LoadingComponent;
const AlertBar = AlertBarComponent;
const ParticipantTable = ParticipantTableComponent;
const CompetencyTable = CompetencyTableComponent;

export {
  Loading,
  Button,
  Layout,
  TopBar,
  SortBar,
  PopUp,
  BreadCrumb,
  Footer,
  ResultTable,
  Table,
  TabSwitch,
  Pagination,
  StatusPopUp,
  AlertBar,
  ParticipantTable,
  CompetencyTable
};
