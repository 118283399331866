import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Label, Input } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

/* IMPORT api_Req FOR API REQUEST */
import { api_Req, WithInterceptor } from "../../../common/services";
/* IMPORT actions FOR REDUX */
import { actions } from "../../../redux/actions/actions";

import { Button, Layout, Footer } from "../../../components";
import { profileInputData } from "../utilities/inputData";
import Error from "../utilities/errors";
import "./profile.scss";

const validationSchema = Yup.object().shape({
    email_address: Yup.string().email("Please enter a valid email").required("This field is required"),
    email_password: Yup.string().required("This field is required"),
    confirm_email_password: Yup.string()
        .oneOf([Yup.ref("email_password"), null], "Passwords must match")
        .required("This field is required"),
});

class AdminProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultEmail: "",
        };
    }

    componentDidMount() {
        this.getUpdatedProfile();
    }

    getUpdatedProfile = async () => {
        try {
            const response = await api_Req.admin.get.updatedProfile({ key: "email_address" });
            this.setState({
                defaultEmail: response.data,
            });
        } catch (error) {
            console.log(error);
        }
    };

    updateProfile = async (values) => {
        console.log(values);
        try {
            await api_Req.admin.post.updateProfile({ key: "email_address", value: values.email_address });
            await api_Req.admin.post.updateProfile({ key: "email_password", value: values.email_password });
            this.props.alertStatus();
        } catch (error) {
            this.props.alertStatus({
                failed: true,
                msg: "Failed to update email profile",
            });
            console.log(error);
        }
    };

    render() {
        return (
            <Layout disabledFooter>
                <div className="admin-profile">
                    <p className="title">Email Profile</p>
                    <Card>
                        <CardBody className="card-body admin-profile__content">
                            <Formik
                                enableReinitialize
                                initialValues={{ email_address: this.state.defaultEmail, email_password: "" }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    this.updateProfile(values);
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        {profileInputData.map((o, i) => {
                                            return (
                                                <div className="admin-profile__inputs" key={`popup${o.key}`}>
                                                    <Label>{o.label}</Label>
                                                    <Input
                                                        name={o.name}
                                                        key={o.key}
                                                        placeholder={o.placeholder}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[o.name]}
                                                        type={o.name === "email_address" ? "text" : "password"}
                                                        className={touched[o.name] && errors[o.name] ? "has-error" : null}
                                                    />
                                                    <Error touched={touched[o.name]} message={errors[o.name]} />
                                                </div>
                                            );
                                        })}

                                        <Button title="SAVE" type="submit" />
                                    </form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                    <Footer />
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.userProfileReducer.profile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertStatus: (data) => dispatch({ type: actions.TOGGLE_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(AdminProfilePage));
