import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { api_Req } from "../../../common/services";

import _ from "lodash";
import { trashO } from "react-icons-kit/fa/trashO";
import { Icon } from "react-icons-kit";

import { Layout } from "../../../components";
import "./overload-list.scss";

import { BreadCrumb, SortBar, PopUp } from "../../../components";

const InitialBreadCrumbData = [
  {
    title: "Project",
    routes: "dashboard"
  },
  {
    title: "Organisation Name",
    routes: "back"
  },
  {
    title: "",
    routes: "",
    active: true
  }
];

const dropdownData = [
  {
    label: "Name of participants",
    data: "name-of-participant"
  },
  {
    label: "Overloaded Raters",
    data: "overloaded-raters"
  }
];

const OverloadDataByParticipant = [
  {
    name: "Joce asdas asdas asdasd",
    raters: [
      {
        name: "ANg dasd asdas",
        roleName: "Boss",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 2 asdas",
        roleName: "Peer",
        email: "asdasda@gmail.com",
        isFatigue: 1
      },
      {
        name: "ANg 3 asdas",
        roleName: "Direct report",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 3 asdas",
        roleName: "Customer",
        email: "asdasda@gmail.com",
        isFatigue: 0
      }
    ]
  },
  {
    name: "Joce asdas asdas asdasd",
    raters: [
      {
        name: "ANg dasd asdas",
        roleName: "Boss",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 2 asdas",
        roleName: "Peer",
        email: "asdasda@gmail.com",
        isFatigue: 1
      },
      {
        name: "ANg 3 asdas",
        roleName: "Direct report",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 3 asdas",
        roleName: "Customer",
        email: "asdasda@gmail.com",
        isFatigue: 0
      }
    ]
  },
  {
    name: "Joce asdas asdas asdasd",
    raters: [
      {
        name: "ANg dasd asdas",
        roleName: "Boss",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 2 asdas",
        roleName: "Peer",
        email: "asdasda@gmail.com",
        isFatigue: 1
      },
      {
        name: "ANg 3 asdas",
        roleName: "Direct report",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 3 asdas",
        roleName: "Customer",
        email: "asdasda@gmail.com",
        isFatigue: 0
      }
    ]
  },
  {
    name: "Joce asdas asdas asdasd",
    raters: [
      {
        name: "ANg dasd asdas",
        roleName: "Boss",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 2 asdas",
        roleName: "Peer",
        email: "asdasda@gmail.com",
        isFatigue: 1
      },
      {
        name: "ANg 3 asdas",
        roleName: "Direct report",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 3 asdas",
        roleName: "Customer",
        email: "asdasda@gmail.com",
        isFatigue: 0
      }
    ]
  },
  {
    name: "Joce asdas asdas asdasd",
    raters: [
      {
        name: "ANg dasd asdas",
        roleName: "Boss",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 2 asdas",
        roleName: "Peer",
        email: "asdasda@gmail.com",
        isFatigue: 1
      },
      {
        name: "ANg 3 asdas",
        roleName: "Direct report",
        email: "asdasda@gmail.com",
        isFatigue: 0
      },
      {
        name: "ANg 3 asdas",
        roleName: "Customer",
        email: "asdasda@gmail.com",
        isFatigue: 0
      }
    ]
  }
];

export const ClientOverloadListPage = ({ history, ...rest }) => {
  const [sortBy, setSortBy] = useState({});
  const historyState = history.location.state;
  const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [
    deleteParticipantPayloadValue,
    setDeleteParticipantPayloadValue
  ] = useState();
  const [deleteRaterPayloadValue, setDeleteRaterPayloadValue] = useState();

  useEffect(() => {
    setProjectDetails(historyState.project);
    if (!historyState) history.push("/dashboard");
    const breadCrumb = [...BreadCrumbData];
    breadCrumb[1].title = historyState.projectName;
    breadCrumb[2].title = `Raters`;
    setBreadCrumbData(breadCrumb);

    fetchProjDetails();
  }, []);

  const setDropdownValue = values => setSortBy(values);

  const handleSearch = (values, key) => {
    switch (key) {
      case "Enter":
        console.log(values, key);
        break;
      case "Backspace":
        console.log(values, key);
        break;
    }
  };

  const toggleConfirmDeletePopup = (pId, rId) => {
    setDeleteParticipantPayloadValue(pId);
    setDeleteRaterPayloadValue(rId);

    setConfirmDeletePopup(prevModal => !prevModal);
  };

  const fetchProjDetails = async () => {
    try {
      const resp = await api_Req.user.get.clientProjDetails(
        historyState.project.projectId
      );
      setProjectDetails(resp.data);
      console.log(resp, "fetchprojdetailsresp");
    } catch (err) {
      // alert(err);
    }
  };

  const deletePost = async () => {
    try {
      const resp = await api_Req.user.post.clientDelete(
        projectDetails.projectId,
        deleteParticipantPayloadValue,
        deleteRaterPayloadValue
      );
      fetchProjDetails();
      console.log(resp, "respdelete");
    } catch (err) {
      alert(err);
    }
  };
  const checkRaterOrParticipantCard =
    sortBy.data === "name-of-participant"
      ? projectDetails && projectDetails.participants
      : projectDetails && projectDetails.raters;

  return (
    <Layout>
      <div className="client-overload-list-container">
        <PopUp
          className="client-confirm-delete-popup"
          visible={confirmDeletePopup}
          toggle={toggleConfirmDeletePopup}
          buttonT1="Confirm"
          buttonT2="Cancel"
          buttonT1Click={() => {
            deletePost();
            toggleConfirmDeletePopup();
          }}
          buttonT2Click={() => toggleConfirmDeletePopup()}
          close
        >
          <h3 className="title">Confirm to delete?</h3>

          <h3>
            Once confirmed, the rater's will be removed from the participant's
            raters list
          </h3>
        </PopUp>
        <div className="header-container">
          <BreadCrumb data={InitialBreadCrumbData} />

          <SortBar
            hideProjects
            dropdownData={dropdownData}
            setDropdownValue={setDropdownValue}
            handleSearchKeyDown={handleSearch}
          />
        </div>

        <div className="card-container">
          {projectDetails &&
            checkRaterOrParticipantCard.map((card, cardIndex) => {
              return (
                <Card>
                  <h3 className="title">
                    {sortBy.data === "name-of-participant"
                      ? "Participant"
                      : "Rater"}
                  </h3>
                  <h3 className="title">{card.name}</h3>
                  <h3 className="title">
                    {sortBy.data === "name-of-participant"
                      ? "Raters"
                      : "participants"}
                  </h3>
                  <h3>
                    Order of number are follow the priority set by participant.
                  </h3>
                  {(sortBy.data === "name-of-participant"
                    ? card.raters
                    : card.participants
                  ).map((raterOrParticipant, raterOrParticipantIndex) => {
                    return (
                      <div
                        className={
                          raterOrParticipant.isFatigue === 0
                            ? "raters-container"
                            : sortBy.data === "name-of-participant"
                            ? "raters-container-red"
                            : "raters-container"
                        }
                      >
                        <div className="left-container">
                          <span>{raterOrParticipantIndex + 1}</span>
                          <span>{raterOrParticipant.name}</span>
                          {sortBy.data === "name-of-participant" && (
                            <span>({raterOrParticipant.roleName})</span>
                          )}
                        </div>
                        <div className="right-container">
                          <span>{raterOrParticipant.email}</span>
                          <Icon
                            style={{ color: "red" }}
                            onClick={() => {
                              if (sortBy.data === "name-of-participant") {
                                toggleConfirmDeletePopup(
                                  card.participantId,
                                  raterOrParticipant.id
                                );
                              } else {
                                toggleConfirmDeletePopup(
                                  raterOrParticipant.participantId,
                                  raterOrParticipant.raterId
                                );
                              }
                            }}
                            icon={trashO}
                            size={24}
                          ></Icon>
                        </div>
                      </div>
                    );
                  })}
                </Card>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};
