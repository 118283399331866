import { actions } from "../actions/actions";

const initialState = {};

export const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_USER_PROFILE:
      return initialState;
    case actions.SET_USER_PROFILE_AUTH:
      return {
        ...state,
        auth: { ...action.data }
      };
    case actions.SET_USER_PROFILE:
      return {
        ...state,
        profile: { ...action.data }
      };
    default:
      return state;
  }
};
