import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { actions } from "../../redux/actions/actions";
import { StatusPopUp, Loading } from "../../components";

export const WithInterceptor = (WrappedComponent) => {
    class WrappedHOC extends Component {
        constructor(props) {
            super(props);
            this._isUnmount = false;
            this.state = {
                loading: false,
                mounted: false,
            };
        }

        componentDidMount() {
            this._isUnmount = true;
            this.setState({ mounted: true });

            this.createAxiosInterceptor();
            console.log("Interceptor Did Mount :)");
        }

        componentWillUnmount() {
            this.setState({ mounted: false });
            axios.interceptors.request.eject(this.requestInterceptor);
            axios.interceptors.response.eject(this.responseInteceptor);
            console.log("Interceptor unMount :(");
        }

        throwError = (error, errorData) => {
            const { userProfile, history, logout } = this.props;
            console.log(userProfile);
            console.log(error);
            if (
                errorData &&
                errorData.code !== 500 &&
                errorData.exceptionMessage &&
                errorData.exceptionMessage !== "Exceeded configured maximum participant."
            ) {
                alert(JSON.stringify(errorData.exceptionMessage));
            }
            console.log(userProfile && userProfile.profile);
            if (userProfile && userProfile.profile) {
                const { profile } = userProfile;
                if (profile) {
                    switch (error) {
                        case 403:
                            const { hasAdminRole, hasClientRole } = profile;
                            const userType = hasAdminRole ? "admin" : hasClientRole ? "client" : "user";

                            logout();
                            localStorage.clear();
                            switch (userType) {
                                case "admin":
                                    history.push("/admin");
                                    break;
                                case "client":
                                    history.push("/client");
                                    break;
                                default:
                                    history.push("/login");
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                switch (error) {
                    case 403:
                        const pathArray = history.location.pathname.split("/");
                        const adminPath = pathArray.includes("admin");
                        const clientPath = pathArray.includes("client");
                        if (adminPath) {
                            history.replace("/admin");
                        } else if (clientPath) {
                            history.replace("/client");
                        } else {
                            history.replace("/login");
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        createAxiosInterceptor = () => {
            this.requestInterceptor = axios.interceptors.request.use(
                (config) => {
                    console.log("%c REQUEST! ", "padding: 5px 10px; background: #512DA8; color: #ffffff");
                    console.log(config.url);
                    this.setState({ loading: true });
                    return config;
                },
                (error) => {
                    console.log("%c ERROR! ", "padding: 5px 10px; background: #C2185B; color: #ffffff");
                    console.log(error);
                    this.setState({ loading: false });
                    return Promise.reject(error);
                }
            );
            this.responseInteceptor = axios.interceptors.response.use(
                (response) => {
                    console.log("%c RESPONSE! ", "padding: 5px 10px; background: #43A047; color: #ffffff");
                    this.setState({ loading: false });
                    return response;
                },
                (error) => {
                    this.setState({ loading: false });
                    console.log("%c ERROR! ", "padding: 5px 10px; background: #C2185B; color: #ffffff");
                    if (error.response) {
                        this.throwError(error.response.status, error.response.data);
                    }
                    return Promise.reject(error);
                }
            );
        };

        render() {
            const { loading, mounted } = this.state;
            return (
                <Fragment>
                    <StatusPopUp />
                    {loading ? <Loading /> : null}
                    {mounted ? <WrappedComponent {...this.props} /> : null}
                </Fragment>
            );
        }
    }
    const mapDispatchToProps = (dispatch) => ({
        logout: () => dispatch({ type: actions.LOGOUT }),
    });

    const mapStateToProps = (state) => {
        return {
            userProfile: state.userProfileReducer,
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(WrappedHOC);
};
