import React, { useState, useEffect } from "react";
import { Card, Carousel, CarouselItem } from "reactstrap";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { chevronRight, chevronLeft, iosSearchStrong } from "react-icons-kit/ionicons";
import _ from "lodash";
import queryString from "query-string";

import { actions } from "../../../../redux/actions/actions";
import { api_Req, WithInterceptor } from "../../../../common/services";
import { Layout, BreadCrumb, Button, PopUp, AlertBar } from "../../../../components";
import "./rating.scss";

const breadCrumbData = [
    {
        title: "Project",
        routes: "/dashboard",
    },
    {
        title: "Organisation Name",
        routes: "",
        active: true,
    },
];

export const RaterRatingPage = ({ location, history, ratingProj, ...props }) => {
    const parsed = queryString.parse(location.search);
    const [breadCrumb, setBreadCrumb] = useState(breadCrumbData);
    const [ratingQuestion, setRatingQuestion] = useState();
    const [commentsPopUp, setCommentsPopUp] = useState(false);
    const [error, setError] = useState();
    const [userRating, setUserRating] = useState({});
    const [userComments, setUserComments] = useState({});
    const [activeIndex, setActiveIndex] = useState({});
    const [theme, setTheme] = useState();
    const completedRating = ratingQuestion && ratingQuestion.ratingCompleted;

    useEffect(() => {
        if (parsed.t) {
            props.logout();
        }

        if (_.isEmpty(ratingProj)) {
            const fetchRatingList = async () => {
                try {
                    const resp = await api_Req.user.get.raterPublicRatingList(parsed.t);
                    if (resp.data.themeId) {
                        setTheme(resp.data.themeId);
                    }
                    if (resp.data.ratingCompleted) {
                        history.push(`rater/thank-you?token=${parsed.resetToken}&isClient=false&name=${parsed.name}`);
                    } else {
                        setRatingQuestion(resp.data);
                        initialPrevRateData(resp.data);
                    }
                } catch (err) {
                    history.push("/dashboard");
                    console.log(err);
                }
            };
            fetchRatingList();
        } else {
            const fetchRatingList = async () => {
                try {
                    const resp = await api_Req.user.get.raterRatingList(ratingProj.project.projectId);
                    if (resp.data.themeId) {
                        setTheme(resp.data.themeId);
                    }
                    setRatingQuestion(resp.data);
                    initialPrevRateData(resp.data);
                } catch (err) {
                    history.push("/dashboard");
                    console.log(err, " ERRR");
                }
            };
            const newBCData = [...breadCrumb];
            newBCData[1].title = ratingProj.project.projectName;
            setBreadCrumb(newBCData);
            fetchRatingList();
        }
    }, []);

    const initialPrevRateData = (resp) => {
        let data = { ...resp };
        let competencies = {};
        let comments = [];
        data.competencies.map((o, p) => {
            o.participants.map((q) => {
                if (!competencies[p]) {
                    competencies[p] = {};
                    competencies[p].id = o.id;
                    competencies[p].participants = [];
                }

                if (q.score) {
                    competencies[p].participants.push({
                        participantId: q.participantId,
                        score: q.score,
                    });
                }
                return;
            });
            return;
        });

        data.comments.map((o) => {
            comments.push({
                participantId: o.participantId,
                comment: o.comment,
            });
            return;
        });

        console.log(competencies);

        setUserComments(comments);
        setUserRating(competencies);
    };

    const handleUserComment = (e) => {
        const prevComments = [...userComments];
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        const commentIndex = userComments.findIndex((x) => x.participantId === name);
        prevComments[commentIndex].comment = value;

        setUserComments(prevComments);
    };

    const handleUserRating = (participant, score, question, order) => {
        const state = { ...userRating };
        const existID = state[order];

        const initialNewObj = () => {
            state[order] = {};
            state[order].id = question.id;
            state[order].participants = [];
            state[order].participants.push({
                participantId: participant.participantId,
                score: score,
            });
        };

        if (existID) {
            state[order].participants = state[order].participants.filter(
                (o) => o.participantId !== participant.participantId
            );
            state[order].participants.push({
                participantId: participant.participantId,
                score: score,
            });
        } else {
            initialNewObj();
        }
        setUserRating(state);
    };

    const handleSubmit = (proceedWithoutComment, submit) => {
        setCommentsPopUp(false);
        const finalRating = Object.values(userRating);
        if (finalRating.length) {
            const error = syncError();
            setError(error);

            const finalError = _.compact(error);
            if (finalError.length) {
                navigateToErrorSlide(finalError);
                props.alertStatus({ failed: true, msg: "All field are mandatory" });
            } else {
                const notEmpty = userComments.some((o) => o.comment.trim().length > 0);
                if (!notEmpty && !proceedWithoutComment) {
                    setCommentsPopUp(true);
                } else {
                    setCommentsPopUp(false);
                    saveAsDraft(submit, proceedWithoutComment);
                }
            }
        } else {
            props.alertStatus({ failed: true, msg: "All field are mandatory" });
        }
    };

    const saveAsDraft = async (submit, proceedWithoutComment) => {
        const emptyComment = userComments.map((i) => {
            return { ...i, comment: "" };
        });
        if (parsed.t) {
            try {
                const payload = {
                    comments: proceedWithoutComment ? emptyComment : userComments,
                    projectId: ratingQuestion.projectId,
                    competencies: Object.values(userRating),
                    token: parsed.t,
                };
                const resp = await api_Req.user.post.raterPublicSaveAsDraft(payload);
                if (submit) {
                    submitRating();
                } else {
                    props.alertStatus({ failed: false, msg: "Successfully Saved" });
                }
            } catch (err) {
                props.alertStatus({ failed: true, msg: "Failed to save" });
            }
        } else {
            try {
                const payload = {
                    comments: proceedWithoutComment ? emptyComment : userComments,
                    projectId: ratingQuestion.projectId,
                    competencies: Object.values(userRating),
                };
                const resp = await api_Req.user.post.raterSaveAsDraft(payload);
                if (submit) {
                    submitRating();
                } else {
                    props.alertStatus();
                }
            } catch (err) {
                props.alertStatus({ failed: true, msg: "Failed to save" });
            }
        }
    };

    const submitRating = async () => {
        if (parsed.t) {
            try {
                const payload = {
                    projectId: ratingQuestion.projectId,
                    token: parsed.t,
                };
                const resp = await api_Req.user.post.raterPublicSubmit(payload);
                props.alertStatus();
                setTimeout(() => {
                    history.push(`rater/thank-you?token=${parsed.resetToken}&isClient=false&name=${parsed.name}`);
                }, 1000);
            } catch (err) {
                props.alertStatus({ failed: true, msg: "Failed to submit" });
            }
        } else {
            try {
                const payload = {
                    projectId: ratingQuestion.projectId,
                };
                const resp = await api_Req.user.post.raterSubmit(payload);
                setTimeout(() => {
                    history.push("rater/thank-you");
                }, 1000);
                props.alertStatus();
            } catch (err) {
                props.alertStatus({ failed: true, msg: "Failed to submit" });
            }
        }
    };

    const navigateToErrorSlide = (error) => {
        const compactError = error;
        let errorActiveIndex = {};
        compactError.map((o) => (errorActiveIndex[o.question] = o.page ? o.page - 1 : 0));
        setActiveIndex({ ...activeIndex, ...errorActiveIndex });
    };

    const syncError = () => {
        const errorMsg = ratingQuestion.competencies.map((o, p) => {
            const totalParticipants = o.participants;
            let ratedParticipantId = [];

            if (userRating[p]) {
                if (userRating[p].participants.length !== totalParticipants.length) {
                    const err = totalParticipants.map((q, r) => {
                        const page = Math.ceil((r + 1) / 6);
                        return userRating[p].participants.map((m) => {
                            if (q.participantId !== m.participantId) {
                                return {
                                    question: p,
                                    page: page,
                                    participantId: q.participantId,
                                };
                            }
                            if (q.participantId === m.participantId) {
                                ratedParticipantId.push(m.participantId);
                            }
                        });
                    });

                    const finalError = { noRateId: [] };
                    const flattenArray = _.compact(_.flatten(err)).filter((o) => {
                        return ratedParticipantId.findIndex((e) => o.participantId === e) < 0;
                    });
                    flattenArray.map((q) => {
                        finalError.question = flattenArray[0].question;
                        finalError.page = flattenArray[0].page;
                        finalError.noRateId.push(q.participantId);
                        return null;
                    });

                    return finalError;
                } else {
                    return null;
                }
            }
            return {
                question: p,
                page: 1,
                noRateId: totalParticipants.map((j) => j.participantId),
            };
        });

        return errorMsg;
    };

    const next = (i, totalParticipant) => {
        let nextIndex = { ...activeIndex };
        if (totalParticipant === (nextIndex[i] + 1 || 1)) return;
        if (activeIndex[i]) {
            nextIndex[i] = nextIndex[i] + 1;
        } else {
            nextIndex[i] = 1;
        }
        setActiveIndex(nextIndex);
    };

    const previous = (i) => {
        let nextIndex = { ...activeIndex };
        if (activeIndex[i]) {
            nextIndex[i] = nextIndex[i] - 1;
        } else {
            nextIndex[i] = 0;
        }
        setActiveIndex(nextIndex);
    };

    const toggleCommentsPopUp = () => {
        setCommentsPopUp(!commentsPopUp);
    };
    return (
        <Layout theme={theme}>
            <div className="rater-rating-container">
                {parsed.resetToken && _.isEmpty(ratingProj) ? (
                    <AlertBar
                        msg="Don't have account yet?"
                        buttonTitle="Register Now"
                        onClick={() => history.push(`signup?token=${parsed.resetToken}&isClient=false&name=${parsed.name}`)}
                    />
                ) : null}
                <div className="header-container">
                    <div className="header-left-container">
                        {!_.isEmpty(ratingProj) ? <BreadCrumb data={breadCrumb} /> : null}
                        <div
                            className="header-comment-btn-container"
                            style={{ marginLeft: !_.isEmpty(ratingProj) ? 20 : 0 }}
                        >
                            <Button
                                title="Add Comments/Remarks"
                                outline
                                onClick={toggleCommentsPopUp}
                                disabled={completedRating}
                            />
                        </div>
                        {completedRating ? (
                            <div
                                className="header-comment-btn-container"
                                style={{ marginLeft: !_.isEmpty(ratingProj) ? 20 : 0 }}
                            >
                                <Button title="View Comments/Remarks" outline onClick={toggleCommentsPopUp} />
                            </div>
                        ): null}
                    </div>
                    <div className="header-btn-container">
                        <Button title="Save Draft" onClick={() => saveAsDraft()} disabled={completedRating} />
                        <Button title="Submit" outline onClick={() => handleSubmit(null, true)} disabled={completedRating} />
                    </div>
                </div>
                <div className="rating-list-container">
                    {ratingQuestion &&
                        ratingQuestion.competencies &&
                        ratingQuestion.competencies.length &&
                        ratingQuestion.competencies.map((o, i) => {
                            const key = `${o.id}_${i}`;
                            const proficiencyModels = o.levelOfProficiencyModels;
                            const totalParticipants = o.participants;
                            const prevBtnDisabled = !activeIndex[i] ? "disabled" : "";
                            const totalSlide = Math.ceil(totalParticipants.length / 6);
                            const nextBtnDisabled =
                                (activeIndex[i] && activeIndex[i] + 1 === totalSlide) || totalSlide === 1 ? "disabled" : "";
                            const ratingData = proficiencyModels.map((a) => proficiencyModels.length);
                            const errorRatingCardClass =
                                error && error[i] && error[i].question === i ? "rating-card-error" : "";
                            return (
                                <Card key={key} className={`rating-card ${errorRatingCardClass}`}>
                                    <div className="card-row">
                                        <div className="card-row-compentency-question">
                                            <h2 className="question">
                                                {o.name} ({o.abbreviation})
                                            </h2>
                                            <p className="question-definition">{o.definition}</p>
                                        </div>
                                        <div className="card-row-compentency-level">
                                            {proficiencyModels.map((j, k) => {
                                                const proficiencyModelsItemKey = `${k}_proficiency_column`;
                                                return (
                                                    <div className="proficiency-container" key={proficiencyModelsItemKey}>
                                                        <div className="question-no">{j.level}</div>
                                                        <div className="question">{j.description}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="card-row">
                                        <Carousel
                                            activeIndex={activeIndex && activeIndex[i] ? activeIndex[i] : 0}
                                            next={next}
                                            previous={previous}
                                            interval={false}
                                        >
                                            <div
                                                className={`prev-btn ${prevBtnDisabled}`}
                                                onClick={() => previous(i, totalSlide)}
                                            >
                                                <Icon icon={chevronLeft} size={20} />
                                            </div>
                                            {[...Array(totalSlide).keys()].map((j, k) => {
                                                const startIndex = k * 6;
                                                const carouselItemKey = `${k}_carousel_item`;

                                                return (
                                                    <CarouselItem key={carouselItemKey}>
                                                        <div className="question-rater-list-container">
                                                            {totalParticipants.map((p, q) => {
                                                                const endingIndex = (k + 1) * 6;
                                                                const ItemCardkey = `${p.participantId}_${p.email}_${q}`;
                                                                const errorParticipantCard =
                                                                    error &&
                                                                    error[i] &&
                                                                    error[i].noRateId.includes(p.participantId)
                                                                        ? "participant-card-error"
                                                                        : "";

                                                                if (startIndex <= q && q + 1 <= endingIndex) {
                                                                    return (
                                                                        <Card
                                                                            key={ItemCardkey}
                                                                            className={errorParticipantCard}
                                                                            style={{
                                                                                pointerEvents:
                                                                                    ratingQuestion &&
                                                                                    ratingQuestion.ratingCompleted
                                                                                        ? "none"
                                                                                        : "",
                                                                            }}
                                                                        >
                                                                            <p className="name">{p.participantName}</p>
                                                                            <p className="email">{p.email}</p>
                                                                            <div className="proficiency-container">
                                                                                {ratingData.map((m, n) => {
                                                                                    const score = n + 1;
                                                                                    const active =
                                                                                        userRating[i] &&
                                                                                        userRating[i].participants.some(
                                                                                            (a) =>
                                                                                                a.participantId ===
                                                                                                    p.participantId &&
                                                                                                a.score === score
                                                                                        );

                                                                                    return (
                                                                                        <div
                                                                                            className={`question-no ${
                                                                                                active ? "active" : ""
                                                                                            }`}
                                                                                            key={`${n}_question_no_${i}`}
                                                                                            onClick={() =>
                                                                                                handleUserRating(
                                                                                                    p,
                                                                                                    score,
                                                                                                    o,
                                                                                                    i
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {score}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </Card>
                                                                    );
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </div>
                                                    </CarouselItem>
                                                );
                                            })}
                                            <div
                                                className={`next-btn ${nextBtnDisabled}`}
                                                onClick={() => next(i, totalSlide)}
                                            >
                                                <Icon icon={chevronRight} size={20} />
                                            </div>
                                        </Carousel>
                                    </div>
                                </Card>
                            );
                        })}
                </div>
            </div>
            <PopUp
                visible={commentsPopUp}
                toggle={toggleCommentsPopUp}
                buttonT1={completedRating ? "" : "Submit"}
                buttonT2={completedRating ? "" : "Proceed without comment"}
                buttonT1Click={() => handleSubmit(null, true)}
                buttonT2Click={() => handleSubmit(true, true)}
                className="add-comments-pop-up"
            >
                <h2 className="title">
                    {completedRating ? "View Comments & Remarks" : "Add Comments & Remarks before submit?"}
                </h2>
                {completedRating ? null : (
                    <p className="desc">
                        Add the comments and the remarks for the participant to help them to create the development plans.
                        (e.g. Strength, Development opportunities)
                    </p>
                )}
                {/* <div className="search-container">
                    <input className="search-comments-input" placeholder="Search" />
                    <Icon icon={iosSearchStrong} size={25} />
                </div> */}
                {ratingQuestion ? (
                    <div className="comments-container">
                        {!_.isEmpty(userComments) &&
                            ratingQuestion.comments.map((o, i) => {
                                const disabled = ratingQuestion && ratingQuestion.ratingCompleted;
                                const prevComment =
                                    ratingQuestion.comments[i].comment === "" ? " " : ratingQuestion.comments[i].comment;
                                const comment = userComments.filter((x) => x.participantId === o.participantId)[0];
                                return (
                                    <div className="comment-input" key={`${o.participantId}_${i}`}>
                                        <div className="label-container">
                                            <p className="name">{o.name}</p>
                                            <p className="email">{o.email}</p>
                                        </div>
                                        <textarea
                                            name={o.participantId}
                                            placeholder="Enter Comments / Remarks."
                                            value={comment.comment || prevComment}
                                            onChange={handleUserComment}
                                            disabled={disabled}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                ) : null}
            </PopUp>
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        ratingProj: state.raterRatingReducer,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertStatus: (data) => dispatch({ type: actions.TOGGLE_ALERT, data }),
    logout: () => dispatch({ type: actions.LOGOUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(RaterRatingPage));
