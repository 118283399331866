import { actions } from "../actions/actions";

const initialState = {
  visible: false,
  msg: "",
  failed: false
};

export const alertStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_ALERT:
      return {
        ...state,
        msg: action.data ? action.data.msg : "",
        failed: action.data ? action.data.failed : false,
        visible: !state.visible
      };
    case actions.HIDE_ALERT:
      return {
        ...state,
        visible: false
      };
    default:
      return state;
  }
};
