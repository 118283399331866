import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Navigation from "./navigation/navigation";
import { Store, Presistor } from "./redux/store/store";
import "./app.scss";

function App() {
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={Presistor}>
        <Navigation />
      </PersistGate>
    </Provider>
  );
}

export default App;
