export const inputData = [
    {
        label: "Project Name",
        name: "projectName",
        key: "projectName",
        placeholder: "Please enter",
    },
    {
        label: "Company Name",
        name: "companyName",
        key: "companyName",
        placeholder: "Please enter",
    },
    {
        label: "Maximum Participant",
        name: "maximumParticipant",
        key: "maximumParticipant",
        placeholder: "Please enter",
    },
    {
        label: "Client Name",
        name: "clientName",
        key: "ClientName",
        placeholder: "Please enter",
    },
    {
        label: "Client Email Address",
        name: "clientEmailAddress",
        key: "ClientEmailAddress",
        placeholder: "e.g. xxx@gmail.com",
    },
    {
        label: "Start Date",
        name: "startDate",
        key: "startDate",
        placeholder: "22/09/2019",
    },
    {
        label: "End Date",
        name: "endDate",
        key: "endDate",
        placeholder: "22/09/2019",
    },
    {
        label: "Welcome message to participants",
        name: "message",
        key: "message",
        placeholder: "",
    },
];

export const profileInputData = [
    {
        label: "Email address",
        name: "email_address",
        key: "email_address",
        placeholder: "Please enter your email address",
    },
    {
        label: "Email Password",
        name: "email_password",
        key: "email_password",
        placeholder: "Please enter your email password",
    },
    {
        label: "Confirm Email Password",
        name: "confirm_email_password",
        key: "confirm_email_password",
        placeholder: "Please enter your confirm email password",
    },
];
