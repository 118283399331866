import React from "react";
import "./loading.scss";

export const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
