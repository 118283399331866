import { call, put, takeLatest } from "redux-saga/effects";
import { api_Req } from "../../common/services";
import { actions } from "../actions/actions";

function* fetchUserProfile(action) {
  try {
    const resp = yield call(api_Req.user.get.profile);
    yield put({ type: actions.SET_USER_PROFILE, data: resp.data });
    if (typeof action.data === "function") yield call(action.data);
  } catch (err) {
    console.log(err);
  }
}

function* profileSaga() {
  yield takeLatest(actions.FETCH_USER_PROFILE, fetchUserProfile);
}

export default profileSaga;
