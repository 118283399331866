import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootSaga from "../saga";

import { RootReducer } from "../reducers";

const persistConfig = {
  key: "root",
  storage
};
const persistedReducer = persistReducer(persistConfig, RootReducer);
const sagaMiddleware = createSagaMiddleware();
const Store = createStore(
  persistedReducer,
  applyMiddleware(logger, sagaMiddleware)
);
const Presistor = persistStore(Store);

sagaMiddleware.run(rootSaga);

export { Store, Presistor };
