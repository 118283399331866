import { api_URL, axios_Services } from "./";

const api_Req = {
    admin: {
        post: {
            login: (payload) => {
                const resp = axios_Services.post(api_URL.ADMIN_LOGIN, payload);
                return resp;
            },
            updateProfile: (payload) => {
                const resp = axios_Services.post(api_URL.ADMIN_UPDATE_PROFILE, payload);
                return resp;
            },
            pinOrUnpin: (payload) => {
                const resp = axios_Services.post(api_URL.ADMIN_PIN_PROJ, payload);
                return resp;
            },
            createProj: (payload) => {
                const resp = axios_Services.post(api_URL.ADMIN_CREATE_PROJ, payload);
                return resp;
            },
            editProj: (payload) => {
                const resp = axios_Services.post(api_URL.ADMIN_EDIT_PROJ, payload);
                return resp;
            },
            uploadFile: (payload) => {
                const config = {
                    "Content-Type": " multipart/form-data",
                };
                const resp = axios_Services.post(api_URL.ADMIN_UPLOAD_PROJ_IMG, payload, config);
                return resp;
            },
        },
        get: {
            profile: () => {
                const resp = axios_Services.get(api_URL.ADMIN_PROFILE);
                return resp;
            },
            updatedProfile: (params) => {
                const resp = axios_Services.get(api_URL.ADMIN_GET_UPDATED_PROFILE, params);
                return resp;
            },
            projList: (params) => {
                const resp = axios_Services.get(api_URL.ADMIN_PROJECT_LIST, params);
                return resp;
            },
            projCompletedList: (params) => {
                const resp = axios_Services.get(api_URL.ADMIN_PROJECT_LIST_COMPLETED, params);
                return resp;
            },
            projImageUrl: (projImgId) => {
                return `${api_URL.ADMIN_GET_PROJ_IMG_URL}/${projImgId}`;
            },
        },
        put: {},
        delete: {},
    },
    user: {
        post: {
            signUp: (payload) => {
                const resp = axios_Services.post(api_URL.SIGNUP, payload);
                return resp;
            },
            login: (payload) => {
                const resp = axios_Services.post(api_URL.LOGIN, payload);
                return resp;
            },
            resetPassword: (payload) => {
                const resp = axios_Services.post(api_URL.RESET_PASSWORD, payload);
                return resp;
            },
            requestResetPassword: (payload) => {
                const resp = axios_Services.post(api_URL.REQUEST_RESET_PASSWORD, payload);
                return resp;
            },
            updateFirstTimeLogin: (payload) => {
                const resp = axios_Services.post(`${api_URL.FIRST_TIME_UPDATE}/${payload}`);
                return resp;
            },
            completedFirstTimeLogin: (payload = 0) => {
                const resp = axios_Services.post(`${api_URL.FIRST_TIME_LOGIN}/${payload}`);
                return resp;
            },
            participantSaveDraft: (payload) => {
                const resp = axios_Services.post(api_URL.PARTI_PROJ_SAVE_DRAFT, payload);
                return resp;
            },

            clientDelete: (projId, participantId, raterId) => {
                const resp = axios_Services.post(
                    `${api_URL.CLIENT_DELETE}/${projId}/participant/${participantId}/delete/${raterId}`
                );
                return resp;
            },
            clientUploadParticipantFile: (payload, id) => {
                const config = { "Content-Type": "multipart/form-data" };
                const resp = axios_Services.post(
                    `${api_URL.CLIENT_UPLOAD_PARTICIPANT_FILE}?projectId=${id}`,
                    payload,
                    config
                );
                return resp;
            },
            clientUploadCompetencyFile: (payload, id) => {
                const config = { "Content-Type": "multipart/form-data" };
                const resp = axios_Services.post(
                    `${api_URL.CLIENT_UPLOAD_COMPETENCY_FILE}?projectId=${id}`,
                    payload,
                    config
                );
                return resp;
            },
            clientSaveDraft: (payload) => {
                const resp = axios_Services.post(api_URL.CLIENT_SAVE_DRAFT, payload);
                return resp;
            },
            clientCleanExcel: (payload) => {
                const resp = axios_Services.post(
                    // api_URL.CLIENT_CLEAN_EXCEL,payload); ?projectId=
                    `${api_URL.CLIENT_CLEAN_EXCEL}?projectId=${payload}`
                );
                return resp;
            },
            clientDisableApi: (payload) => {
                const resp = axios_Services.post(`${api_URL.CLIENT_DISABLE}/${payload}`);
                return resp;
            },
            clientPublish: (payload) => {
                const resp = axios_Services.post(`${api_URL.CLIENT_PUBLISH}/${payload}`);
                return resp;
            },
            clientStart: (projectId, startDate, endDate) => {
                const resp = axios_Services.post(`${api_URL.CLIENT_START}/${projectId}/${startDate}/${endDate}`);
                return resp;
            },
            participantOnSubmit: (projId) => {
                const resp = axios_Services.post(`${api_URL.PARTI_PROJ_SUBMIT}/${projId}`);
                return resp;
            },
            raterSaveAsDraft: (payload) => {
                const resp = axios_Services.post(api_URL.RATER_SAVE_AS_DRAFT, payload);
                return resp;
            },
            raterSubmit: (payload) => {
                const resp = axios_Services.post(api_URL.RATER_SUBMIT, payload);
                return resp;
            },
            raterPublicSaveAsDraft: (payload) => {
                const resp = axios_Services.post(api_URL.RATER_PUBLIC_SAVE_AS_DRAFT, payload);
                return resp;
            },
            raterPublicSubmit: (payload) => {
                const resp = axios_Services.post(api_URL.RATER_PUBLIC_SUBMIT, payload);
                return resp;
            },
        },
        get: {
            profile: () => {
                const resp = axios_Services.get(api_URL.PROFILE);
                return resp;
            },
            dropdownCategory: () => {
                const resp = axios_Services.get(api_URL.DROPDOWN_CATEGORY);
                return resp;
            },
            clientprojList: (params) => {
                const resp = axios_Services.get(api_URL.CLIENT_PROJ_LIST, params);
                return resp;
            },
            clientProjectResultGap: (params) => {
                const resp = axios_Services.get(`${api_URL.CLIENT_PROJ_RESULT_GAP}/${params}`);
                return resp;
            },
            clientProjectResult: (params) => {
                const resp = axios_Services.get(`${api_URL.CLIENT_PROJ_RESULT}/${params}`);
                return resp;
            },
            clientProjDetails: (params) => {
                const resp = axios_Services.get(`${api_URL.CLIENT_PROJ_DETAILS}/${params}`);
                return resp;
            },
            clientPaginatedParticipants: (projId, params) => {
                const resp = axios_Services.get(
                    `${api_URL.CLIENT_PAGINATED_PARTICIPANTS}/${projId}/partcipant/pages`,
                    params
                );
                return resp;
            },
            participantProjList: (params) => {
                const resp = axios_Services.get(api_URL.PARTI_PROJ_LIST, params);
                return resp;
            },
            participantProjDetails: (params) => {
                const resp = axios_Services.get(`${api_URL.PARTI_PROJ_DETAILS}/${params}`);
                return resp;
            },
            participantProjResult: (params) => {
                const resp = axios_Services.get(`${api_URL.PARTI_PROJ_RESULT}/${params}`);
                return resp;
            },
            participantExportResult: (params) => {
                const resp = axios_Services.get(`${api_URL.PARTI_PROJ_EXPORT}/${params}`);
                return resp;
            },
            raterProjList: (params) => {
                const resp = axios_Services.get(api_URL.RATER_PROJ_LIST, params);
                return resp;
            },
            raterRatingList: (projId) => {
                const resp = axios_Services.get(`${api_URL.RATER_RATING_LIST}/${projId}/list`);
                return resp;
            },
            raterPublicRatingList: (token) => {
                const resp = axios_Services.get(`${api_URL.RATER_PUBLIC_LIST}/${token}/list`);
                return resp;
            },
        },
        put: {},
        delete: {},
    },
};

export { api_Req };
