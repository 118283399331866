import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { eye } from "react-icons-kit/entypo/eye";
import Icon from "react-icons-kit";
import queryString from "query-string";

/* IMPORT api_Req FOR API REQUEST */
import { api_Req, WithInterceptor } from "../../../common/services";
/* IMPORT actions FOR REDUX */
import { actions } from "../../../redux/actions/actions";
import { Button } from "../../../components";
import nhaLogo from "../../../assets/img/nha-logo.png";
import "./login.scss";

class AdminLoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eyePasswordHidden: true,
            invalidPassword: false,
        };
    }

    componentDidMount() {
        localStorage.clear();
        this.props.logout();
        const { location } = this.props;
        const parsed = queryString.parse(location.search);
        this.setState({ parsed });
    }

    handleSubmit = (values) => {
        const { parsed } = this.state;
        const { history } = this.props;
        this.setState({ invalidPassword: false });
        if (values.username && values.password) {
            const asClient = parsed ? (parsed.isClient ? true : false) : false;
            const payload = {
                email: values.username,
                password: values.password,
                asClient: asClient,
            };

            api_Req.admin.post
                .login(payload)
                .then((resp) => {
                    console.log(resp, "resp");
                    this.props.setProfileToken(resp.data);
                    localStorage.setItem("nha-user-token", JSON.stringify(resp.data));
                    history.push("admin/dashboard");
                })
                .catch((err) => {
                    this.setState({ invalidPassword: true });
                    localStorage.clear();
                });
        }
    };

    togglePassword = () => {
        this.setState((prevState) => ({
            eyePasswordHidden: !prevState.eyePasswordHidden,
        }));
    };

    render() {
        return (
            <div className="main-container">
                <div className="login-container">
                    <div className="logo">
                        <img className="logo-img" src={nhaLogo} alt="nha" />
                    </div>
                    <div className="login-card">
                        <h4>Login to your account</h4>
                        <Formik
                            initialValues={{
                                username: "",
                                password: "",
                            }}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().label().min(5, "Minimum 5 characters").required("Required"),
                                password: Yup.string()
                                    .required("Invalid password")
                                    .min(6, "Minimum 6 character password")
                                    .max(20, "Try a shorter password."),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.handleSubmit(values);
                            }}
                        >
                            {(props) => {
                                const {
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    values,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="formStyle">
                                        <div className="icon_container">
                                            <input
                                                id="username"
                                                type="text"
                                                name="username"
                                                placeholder="Email"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                className={
                                                    errors.username && touched.username ? "text-input error" : "text-input"
                                                }
                                            />
                                        </div>
                                        {errors.username && touched.username && (
                                            <div className="input-feedback">{errors.username}</div>
                                        )}
                                        <div className="icon_container">
                                            <Icon icon={eye} size={20} onClick={this.togglePassword} />
                                            <input
                                                id="password"
                                                type={this.state.eyePasswordHidden ? "password" : "text"}
                                                name="password"
                                                placeholder="Password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                className={
                                                    errors.password && touched.password ? "text-input error" : "text-input"
                                                }
                                            />
                                        </div>
                                        {(errors.password && touched.password) || this.state.invalidPassword ? (
                                            <div className="input-feedback">{errors.password || "Invalid password"}</div>
                                        ) : null}
                                        <div className="button-container">
                                            <Button type="submit" title="LOGIN" disabled={isSubmitting} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    setProfileToken: (data) => {
        dispatch({ type: actions.SET_USER_PROFILE_AUTH, data });
    },
    resetAllState: () => {
        dispatch({ type: actions.INIT_USER_PROFILE });
    },
    logout: () => dispatch({ type: actions.LOGOUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(AdminLoginPage));
