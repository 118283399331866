import React, { PureComponent, Fragment } from "react";
import "./resultTable.scss";

export default class ResultTableComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tHeadWidth: 0,
      tbodyRowHeight: null,
      tableBodyFullRowHeight: 88
    };
  }

  componentDidMount() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getHeight);
  }

  renderTableBody = () => {
    const { tableTBodyData, tableTHeadData } = this.props;
    const { tHeadWidth, tbodyRowHeight, tableBodyFullRowHeight } = this.state;
    return tableTBodyData.map((data, i) => {
      const keys = Object.keys(data);
      const even = i % 2 ? "even" : "";
      const bodyHeightRow_1 = tbodyRowHeight ? tbodyRowHeight[i] : "auto";
      return (
        <Fragment key={`${i}_tr`}>
          <div className={`table-body-row ${even}`}>
            {tableTHeadData.map((thead, j) => {
              const bodyRow_2 = tHeadWidth && j === "1" ? tHeadWidth : "0";
              const index = keys.indexOf(thead.key);
              if (thead.key === keys[index]) {
                return (
                  <div
                    data-content={data[keys[index]]}
                    className={`table-body-item ${even}`}
                    key={`${thead.key}_${j}_${i}`}
                    style={{
                      position: j === 0 ? "absolute" : "inherit",
                      height: bodyHeightRow_1,
                      minWidth: tHeadWidth ? tHeadWidth : "auto",
                      marginLeft: bodyRow_2,
                      paddingBottom: data.comments ? 0 : 15
                    }}
                  >
                    {data[keys[index]]}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {data.comments &&
            <div
              className={`table-body-row-full ${even}`}
              style={{
                height: tableBodyFullRowHeight ? tableBodyFullRowHeight : 88
              }}
            >
              <div className={`table-body-item`}>
                <div className="table-comment">
                  Remarks/{"\n"}Comments
                </div>
                <div className="table-comment">
                  {data.comments}
                </div>
              </div>
            </div>}
        </Fragment>
      );
    });
  };

  getHeight = () => {
    const tableHeadRow = document.getElementsByClassName("table-head-item");
    const tableBodyRow = document.getElementsByClassName("table-body-row");
    const tableBodyFullRow = document.getElementsByClassName(
      "table-body-row-full"
    );
    let maxWidth = 0;
    let tbodyRowHeight = [];
    let tableBodyFullRowHeight = 0;

    Object.keys(tableHeadRow).forEach(key => {
      const offsetWidth = tableHeadRow[key].offsetWidth;
      maxWidth = offsetWidth > maxWidth ? offsetWidth : maxWidth;
    });

    Object.keys(tableBodyRow).forEach(key => {
      tbodyRowHeight.push(tableBodyRow[key].firstElementChild.offsetHeight);
    });

    Object.keys(tableBodyFullRow).forEach(key => {
      tableBodyFullRowHeight =
        tableBodyFullRow[key].firstElementChild.offsetHeight + 30;
    });

    this.setState({
      tHeadWidth: maxWidth,
      tbodyRowHeight,
      tableBodyFullRowHeight
    });
  };

  render() {
    const { tHeadWidth } = this.state;
    const { tableTHeadData } = this.props;
    return (
      <div className="table-container" id="table-container">
        <div className="table-head" id="table-head">
          {tableTHeadData &&
            tableTHeadData.map((tHead, i) => {
              const headRow_2 = tHeadWidth && i === "1" ? tHeadWidth : "0";

              return (
                <div
                  className="table-head-item"
                  key={`${tHead.label}_${i}`}
                  style={{
                    position: i === 0 ? "absolute" : "inherit",
                    minWidth: tHeadWidth ? tHeadWidth : "auto",
                    marginLeft: headRow_2
                  }}
                >
                  {tHead.label}
                </div>
              );
            })}
        </div>
        <div className="table-body">
          {this.renderTableBody()}
        </div>
      </div>
    );
  }
}
