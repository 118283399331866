import React from "react";
import moment from "moment";
import "./footer.scss";

export const FooterComponent = props => {
  return (
    <footer className="footer-container" id="copyright-footer">
      © NHA {moment().format("YYYY")}
    </footer>
  );
};
