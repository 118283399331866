import React, { useState, useEffect, useRef, Fragment } from "react";
import { Card } from "reactstrap";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { iosArrowDown, iosCloseEmpty } from "react-icons-kit/ionicons";

import { formatDate } from "../../../../common/helper/helpers";
import { actions } from "../../../../redux/actions/actions";
import empty_icon from "../../../../assets/icons/add-empty.png";
import { api_Req, WithInterceptor } from "../../../../common/services";
import { Layout, BreadCrumb, Button, PopUp } from "../../../../components";
import "./add-raters.scss";

const initialValues = {
    raters: [
        {
            name: "",
            email: "",
            category: "",
            description: "",
        },
    ],
};
const InitialBreadCrumbData = [
    {
        title: "Project",
        routes: "/dashboard",
    },
    {
        title: "Organisation Name",
        routes: "",
        active: true,
    },
];
const validationSchema = yup.object({
    raters: yup.array().of(
        yup.object({
            email: yup.string().email("Please insert a valid email").required("Please insert an email"),
            name: yup.string().required("Please insert a name"),
            category: yup.object().required("Please select a category").typeError("Please select a category"),
            description: yup.string().when("category", {
                is: (val) => val && val.value === "OTHER",
                then: yup.string().required("Please insert category description"),
            }),
        })
    ),
    // .min(1)
    // .required()
});

const AddRatersConnectPage = ({ history, projDetails, projRaters, selectProj, ...props }) => {
    const formikRef = useRef(null);
    const [submitted, setSubmitted] = useState("submitted");
    const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);
    const [items, setItems] = useState([{}]);
    const [categoryList, setCategoryList] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({ 0: false });
    const [popup, setPopup] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const breadCrumb = [...BreadCrumbData];
        breadCrumb[1].title = selectProj.projectName;
        setBreadCrumbData(breadCrumb);
        fetchParticipantData();
    }, []);

    const fetchParticipantData = async () => {
        try {
            const participantDetails = api_Req.user.get.participantProjDetails(selectProj.id);
            const dropdownCategory = api_Req.user.get.dropdownCategory();
            Promise.all([participantDetails, dropdownCategory])
                .then(([participantDetails, dropdownCategory]) => {
                    props.setProjDetails(participantDetails.data);
                    setCategoryList(dropdownCategory.data);
                    setInitialFormValue(participantDetails.data, dropdownCategory.data);

                    if (participantDetails.data.finishSetupRater) {
                        setSubmitted("submitted");
                    } else {
                        setSubmitted("");
                    }
                })
                .catch((err) => {
                    history.push("/dashboard");
                });
        } catch (err) {
            console.log(err);
        }
    };

    const setInitialFormValue = (participantDetails, categoryData) => {
        let raters = [];
        if (participantDetails.raters && participantDetails.raters.length > 0 && projRaters.length === 0) {
            participantDetails.raters.map((o) => {
                const category = categoryData.filter((c) => c.id === o.roleId)[0];
                raters.push({
                    name: o.name,
                    email: o.email,
                    category: category,
                    description: o.otherDescription || "",
                });
                return null;
            });
        } else {
            if (projRaters && projRaters.length) {
                projRaters.map((o) => {
                    raters.push({
                        name: o.name,
                        email: o.email,
                        category: o.category,
                        description: o.description || "",
                    });
                    return null;
                });
            }
        }
        formikRef.current.setFieldValue("raters", raters);
        if (!raters.length) {
            setItems([...initialValues.raters]);
        } else {
            setItems(raters);
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }, { values, setFieldValue }) => {
        setFieldValue("raters", arrayMove(values.raters, oldIndex, newIndex));
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    const toggleDropdownOpen = (i, formProps) => {
        const newDropdownOpen = { ...dropdownOpen };
        newDropdownOpen[i] = !newDropdownOpen[i];
        setDropdownOpen(newDropdownOpen);

        if (!newDropdownOpen[i]) formProps.setFieldTouched(`raters[${i}].category`, true);
    };

    const removeItems = (itemIndex) => {
        const remaindItems = items.filter((o, i) => i !== itemIndex);
        setItems(remaindItems);
    };

    const addNewRater = (arrayHelpers) => {
        const newData = {
            name: "",
            email: "",
            category: "",
            description: "",
        };
        const newItems = [...items];
        newItems.push(newData);
        arrayHelpers.push(newData);
        setItems(newItems);
    };
    const togglePopup = () => {
        setPopup((prevModal) => !prevModal);
    };

    const onHandleSubmit = (values) => {
        if (
            formikRef.current.values.raters.filter((o) => o.category.value === "BOSS").length === 1 &&
            formikRef.current.values.raters.filter(
                (o) =>
                    o.category.value === "PEER" ||
                    o.category.value === "DIRECT_REPORT" ||
                    o.category.value === "CUSTOMER" ||
                    o.category.value === "OTHER"
            ).length < 1
        ) {
            // setMessage(
            //   `Requires minimum of two bosess,a boss and "others" or a boss and two raters(excluding "others")`
            // );

            togglePopup();
        }  else if (
            formikRef.current.values.raters.filter((o) => o.category.value === "OTHER").length > 0
        ) {
            props.setRaters(values.raters);
            history.push("rate-yourself");
            console.log("pass");
        } else if (
            formikRef.current.values.raters.filter(
                (o) => o.category.value === "PEER" || o.category.value === "DIRECT_REPORT" || o.category.value === "CUSTOMER"
            ).length > 0 &&
            formikRef.current.values.raters.filter(
                (o) =>
                    o.category.value === "PEER" ||
                    o.category.value === "DIRECT_REPORT" ||
                    o.category.value === "CUSTOMER" ||
                    o.category.value === "OTHER"
            ).length < 2
        ) {
            togglePopup();
        } else {
            props.setRaters(values.raters);
            history.push("rate-yourself");
            console.log("pass");
        }

        // if (filter === PEER,DIRECT_REPORT ,CUSTOMER&&filter PEER,DIRECT_REPORT ,CUSTOMER>2)
        // {true}
        // else
        //   if filter ===bosss && raters.length>1{
        //     true

        //   }
        //   else if(filter ===OTHER>0){
        //     true
        //   }

        // if(formikRef.current.values.raters.filter(o => o.category.value === "BOSS")
        // .length>1)

        // if (
        //   formikRef.current.values.raters.find(o => o.category.value === "BOSS")
        // ) {
        //   console.log("true");
        //   props.setRaters(values.raters);
        //   history.push("rate-yourself");
        // } else {
        //   console.log("Oi");
        // }
    };

    const saveAsDraft = async () => {
        const { values, isValid } = formikRef.current;
        if (!isValid) return;
        const raters = values.raters.map((o) => {
            return {
                email: o.email,
                name: o.name,
                otherDescription: o.description || null,
                roleId: o.category.id,
                roleName: o.category.value,
            };
        });
        const payload = {
            projectId: projDetails.projectId,
            rateSelf: null,
            raters: raters,
        };
        try {
            const resp = await api_Req.user.post.participantSaveDraft(payload);
            if (resp) props.alertStatus({ msg: "Saved!" });

            if (resp) {
                props.setRaters(raters);
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.exceptionMessage) {
                props.alertStatus({
                    failed: true,
                    msg: err.response.data.exceptionMessage,
                });
            } else {
                props.alertStatus({
                    failed: true,
                    msg: "Failed to save!",
                });
            }
        }
    };

    const GridItem = SortableElement(({ value, formProps, ...rest }) => {
        console.log(formProps.values.raters, "formProps.values.raters[rest.i]");
        const categoryIsOther =
            formProps.values &&
            formProps.values.raters &&
            formProps.values.raters[rest.i] &&
            formProps.values.raters[rest.i].category &&
            formProps.values.raters[rest.i].category.value.toLowerCase() === "other";
        const hasError = formProps.errors && formProps.errors.raters && formProps.errors.raters[rest.i];
        const hasTouched = formProps.touched && formProps.touched.raters && formProps.touched.raters[rest.i];
        const emailError =
            hasError && hasError.email
                ? // && hasTouched && hasTouched.email
                  formProps.errors.raters[rest.i].email
                : "";
        const nameError =
            hasError && hasError.name
                ? // &&
                  // hasTouched && hasTouched.name
                  formProps.errors.raters[rest.i].name
                : "";
        const categoryError =
            hasError &&
            hasError.category &&
            // hasTouched &&
            // hasTouched.category &&
            !formProps.values.raters[rest.i].category
                ? formProps.errors.raters[rest.i].category
                : "";
        const categoryDescError =
            hasError &&
            hasError.description &&
            // hasTouched &&
            // hasTouched.description &&
            !formProps.values.raters[rest.i].description
                ? formProps.errors.raters[rest.i].description
                : "";

        return (
            <Fragment>
                <div className="participant-grid-item">
                    <PopUp
                        className="rater-validation-pop-up"
                        visible={popup}
                        toggle={togglePopup}
                        buttonT1="OK"
                        buttonT1Click={() => {
                            togglePopup();
                        }}
                    >
                        <br />
                        <h3>Minimum must have 2 raters</h3>

                        <br />
                        <h3>
                            Peer, customer, direct reports must have minimum 2, if only have 1 please place it to the
                            category "others".
                        </h3>
                        <br />
                        {/* <h3>
              1. Minimum 2 peers required
              <br />
              Please add minimum of 2 peers to proceed. If you have only 1 peer,
              please place it to category "others". Thanks.
              <br />
              <br />
              2. Minimum 2 raters required
              <br />
              Please add minimum of 2 raters to proceed. Thank you.
            </h3> */}
                    </PopUp>

                    {items.length > 1 && !submitted ? (
                        <div
                            className="close-container"
                            onClick={() => {
                                rest.arrayHelpers.remove(rest.i);
                                removeItems(rest.i);
                            }}
                        >
                            <Icon icon={iosCloseEmpty} size={25} />
                        </div>
                    ) : null}
                    <div className="participant-grid-column">
                        <div className="participant-grid-no">{rest.i + 1}</div>
                        <div className={`participant-grid-dropdown-container ${!categoryIsOther ? "not-other" : ""}`}>
                            <p className="label">Category</p>
                            <Field name={`raters[${rest.i}].category`}>
                                {({ field }) => (
                                    <Dropdown
                                        isOpen={dropdownOpen[rest.i]}
                                        toggle={() => {
                                            toggleDropdownOpen(rest.i, formProps);
                                        }}
                                        style={{ width: "100%" }}
                                    >
                                        <DropdownToggle
                                            onClick={() => formProps.validateForm().then(() => console.log("blahe"))}
                                            disabled={submitted ? true : false}
                                            className={`participant-grid-dropdown-toggle ${categoryError ? "error" : ""} `}
                                        >
                                            {field.value ? field.value.description : "Select Category"}
                                            <Icon icon={iosArrowDown} />
                                        </DropdownToggle>
                                        <DropdownMenu className="participant-grid-dropdown-menu">
                                            {categoryList.length &&
                                                categoryList.map((o, i) => {
                                                    return (
                                                        <DropdownItem
                                                            key={`${i}_dropdown_item`}
                                                            value={JSON.stringify(o)}
                                                            onClick={(e) => {
                                                                formProps.setFieldValue(
                                                                    `raters[${rest.i}].category`,
                                                                    JSON.parse(e.currentTarget.getAttribute("value")),
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {o.description}
                                                        </DropdownItem>
                                                    );
                                                })}
                                        </DropdownMenu>
                                    </Dropdown>
                                )}
                            </Field>
                            {categoryError ? <div className="error-msg">{categoryError}</div> : null}
                        </div>
                        {categoryIsOther ? (
                            <div className="grid-input-container grid-input-container-desc">
                                <p className="label" />
                                <Field
                                    disabled={submitted}
                                    placeholder="Description"
                                    name={`raters[${rest.i}].description`}
                                    className={`participant-input ${categoryDescError ? "error" : ""}`}
                                />
                                {categoryDescError ? <div className="error-msg">{categoryDescError}</div> : null}
                            </div>
                        ) : null}
                    </div>
                    <div className="participant-grid-column participant-grid-column-2">
                        <div className="grid-input-container">
                            <p className="label">Name</p>
                            <Field
                                disabled={submitted}
                                name={`raters[${rest.i}].name`}
                                placeholder="Name"
                                className={`participant-input ${nameError ? "error" : ""}`}
                            />
                            {nameError ? <div className="error-msg">{nameError}</div> : null}
                        </div>
                        <div className="grid-input-container">
                            <p className="label">Email</p>
                            <Field
                                disabled={submitted}
                                name={`raters[${rest.i}].email`}
                                placeholder="Email"
                                className={`participant-input ${emailError ? "error" : ""}`}
                            />
                            {emailError ? <div className="error-msg">{emailError}</div> : null}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    });

    const Grid = SortableContainer(({ items, ...props }) => {
        return (
            <div className="grid-container" style={{ pointerEvents: submitted ? "none" : "auto" }}>
                {items.map((value, index) => (
                    <FieldArray
                        key={`${index}_field_array`}
                        name="raters"
                        render={(arrayHelpers) => (
                            <Fragment>
                                <GridItem
                                    key={`item-${index}`}
                                    index={index}
                                    value={value}
                                    formProps={props}
                                    arrayHelpers={arrayHelpers}
                                    i={index}
                                />
                                {items.length === index + 1 && !submitted && (
                                    <div className="participant-grid-item add-participant-grid-item">
                                        {console.log(props, "props")}
                                        {/* <button type="button" onClick={() => props.validateForm().then(() => console.log("blah"))}>
                      validateform</button> */}
                                        {console.log(props.errors, "props.errors", props.isValid && "isValid")}
                                        <img
                                            alt="empty-icon"
                                            src={empty_icon}
                                            onClick={() => {
                                                props.validateForm().then(props.isValid ? addNewRater(arrayHelpers) : null);
                                            }}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )}
                    />
                ))}
            </div>
        );
    });

    return (
        <Layout>
            {projDetails ? (
                <div className="participant-add-raters-container">
                    <div className="header-container">
                        <BreadCrumb data={BreadCrumbData} />
                        <div className="header-btn-container">
                            <Button title="Save Draft" onClick={saveAsDraft} disabled={submitted} />
                        </div>
                    </div>
                    <Card>
                        <div className="card-row card-row-1">
                            <div className="column">
                                <h3 className="title">Nomination Validity Period</h3>
                                <p className="date">
                                    {formatDate(projDetails.startNominationPeriod)} -{" "}
                                    {formatDate(projDetails.endNomiationPeriod)}
                                </p>
                            </div>
                            <div className="column">
                                <ul className={`step-container ${submitted}`}>
                                    <li className="active">Step 1</li>
                                    <div className="diviver" />
                                    <li>Step 2</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-row">
                            <div className="column">
                                <h3 className="title">Rater</h3>
                                <p className="desc">
                                    The priority of raters will be follow the sequence you added. (1 = Most prioritized)
                                </p>
                            </div>
                        </div>
                        <div className="card-row">
                            <div className="column full-column">
                                <div className="sort-container">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => onHandleSubmit(values)}
                                    >
                                        {(props) => {
                                            formikRef.current = props;
                                            return (
                                                <Form>
                                                    <Grid
                                                        {...props}
                                                        pressDelay={150}
                                                        items={items}
                                                        onSortEnd={(e) => onSortEnd(e, props)}
                                                        axis="xy"
                                                    />

                                                    <div className="footer-btn-container">
                                                        {submitted ? (
                                                            <Button
                                                                className="view-rating-btn"
                                                                type="button"
                                                                title="View Self Ratings"
                                                                onClick={() => history.push("rate-yourself")}
                                                            />
                                                        ) : (
                                                            <Button type="submit" title="Next" />
                                                        )}
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            ) : null}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        projDetails: state.participantReducer.projDetails,
        projRaters: state.participantReducer.raters,
        selectProj: state.participantReducer.results,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setRaters: (data) => dispatch({ type: actions.SET_RATERS, data }),
    setProjDetails: (data) => dispatch({ type: actions.SET_PROJ_DETAILS, data }),
    alertStatus: (data) => dispatch({ type: actions.TOGGLE_ALERT, data }),
});

export const ParticipantAddRatersPage = connect(mapStateToProps, mapDispatchToProps)(WithInterceptor(AddRatersConnectPage));
