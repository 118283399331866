import { actions } from "../actions/actions";

const initialState = {};

export const raterRatingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_RATER_RATING:
      return initialState;
    case actions.RATER_SELECT_PROJ:
      return { ...state, project: action.data };
    default:
      return state;
  }
};
