import { actions } from "../actions/actions";

const initialState = {
  raters: [],
  competency: {},
  projDetails: {},
  results: {},
  resultsDetails: {}
};

export const participantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INIT_ADD_RATERS:
      return initialState;
    case actions.SET_RATERS:
      return {
        ...state,
        raters: action.data
      };
    case actions.SET_PROJ_DETAILS:
      return {
        ...state,
        projDetails: action.data
      };
   case actions.SET_PROJ_DETAILS_RATESELF:
        return {
          ...state,
          projDetails:{ ...state.projDetails,rateSelf: action.data}
 
        };
   
      
    case actions.SET_COMPENTENCY:
      return {
        ...state,
        competency: action.data
      };
    case actions.SELECT_COMPLETED_RESULT:
      return {
        ...state,
        results: action.data
      };
    case actions.SET_COMPLETED_RESULT:
      return {
        ...state,
        resultsDetails: action.data
      };
    default:
      return state;
  }
};
