import React, { Fragment, useEffect, useState } from "react";
import { Card } from "reactstrap";
import { HorizontalBar } from "react-chartjs-2";
import _ from "lodash";
import { connect } from "react-redux";

import { actions } from "../../../../redux/actions/actions";
import { Button, BreadCrumb, Layout, TabSwitch } from "../../../../components";
import { formatDate } from "../../../../common/helper/helpers";
import { api_Req, WithInterceptor } from "../../../../common/services";
import "./result-chart.scss";

const InitialBreadCrumbData = [
  {
    title: "Project",
    routes: "/dashboard"
  },
  {
    title: "Organisation Name",
    routes: "back"
  },
  {
    title: "",
    routes: "",
    active: true
  }
];

export const ParticipantResultChartsPage = ({
  history,
  resultDetails,
  selectProj,
  ...props
}) => {
  const [BreadCrumbData, setBreadCrumbData] = useState(InitialBreadCrumbData);
  const [result, setResult] = useState();
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  useEffect(() => {
    if (_.isEmpty(selectProj)) {
      history.push("/dashboard");
    } else {
      const breadCrumb = [...BreadCrumbData];
      breadCrumb[1].title = selectProj.projectName;
      breadCrumb[2].title = `Result (${formatDate(selectProj.endDate)})`;
      setBreadCrumbData(breadCrumb);
      initialData(resultDetails);
    }
    return;
  }, []);

  const exportResult = async () => {
    const fileName = `${selectProj.projectName}-${selectProj.endDate}.xlsx`;
    try {
      const resp = await api_Req.user.get.participantExportResult(
        selectProj.id
      );
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      props.alertStatus({ failed: true, msg: err.response.data.error });
    }
  };

  const initialData = data => {
    console.log(data);
    const respData = { ...data };
    const bossComments =
      respData.comments && respData.comments.filter(o => o.roleName === "Boss");
    const otherComments =
      respData.comments && respData.comments.filter(o => o.roleName !== "Boss");
    const comments = [...bossComments, ...otherComments];
    const highestCompentencyLvl = respData.competencyModels
      ? _.maxBy(respData.competencyModels, o => o.totalLevel)
      : 0;
    const chartLabel = [];
    const chartLevel = [];
    const chartDesc = [];

    if (respData.competencyModels) {
      respData.competencyModels.map(o => {
        chartLabel.push(o.name);
        chartLevel.push(o.score);
        chartDesc.push(o.definition);
      });
    }

    respData.comments = comments;
    respData.highestCompentencyLvl = highestCompentencyLvl.totalLevel;
    respData.chartLabel = chartLabel;
    respData.chartLevel = chartLevel;
    respData.chartDesc = chartDesc;
    setResult(respData);
  };

  useEffect(() => {
    console.log(result, " AFTER MASSAGE");
  });

  const toggleActiveTab = currentActiveTab => {
    setCurrentActiveTab(currentActiveTab);
  };
  return (
    <Layout>
      <div className="participant-chart-result-container">
        <div className="header-container">
          <BreadCrumb data={InitialBreadCrumbData} />
          <Button title="Export" onClick={window.print} />
        </div>
        <TabSwitch
          displayCurrentActiveTab={toggleActiveTab}
          customTabName={["Average Result", "Rater Comments"]}
        />
        <Card>
          {result &&
            (currentActiveTab === 0 ? (
              <Fragment>
                <div className="graph-legend-row">
                  <div className="left">
                    <p>Total of raters: {resultDetails.totalRaters}</p>
                  </div>
                  <div className="header">
                    <div className="legend-green" />
                    <p>At or above target score</p>
                    <div className="legend-red" />
                    <p>Below target score</p>
                    <div className="legend-dotted" />
                    <p>Target Rating</p>
                    <div className="legend-diamond" />
                    <p>Self Rating</p>
                  </div>
                </div>
                <div className="card-row">
                  <div className="graph-component">
                    <div className="header">
                      <div className="row1">
                        {" "}
                        <div className="left">
                          {" "}
                          <p>No.</p> <p>Competency Model</p>
                          <p>Raters</p>
                          <p>Average Rating</p>{" "}
                        </div>
                        <div className="right">Ratings Scale</div>{" "}
                      </div>
                      <div className="row2">
                        <div className="left"></div>
                        <div className="right">
                          {[...Array(resultDetails.maxScore + 1).keys()]
                            // .slice(1)
                            .map((level, index) => {
                              return <p>{index }</p>;
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="graph-table">
                      {resultDetails.competencyModels.length > 0 &&
                        resultDetails.competencyModels.map(
                          (tableRow, indexx) => {
                            return (
                              <div className="table-row">
                                <div className="table-left">
                                  <p>{indexx + 1}</p> <p>{tableRow.name}</p>{" "}
                                  <p>
                                    <p className="rater-average">Average</p>
                                    {tableRow.raters.map((raters, indexxx) => {
                                      return (
                                        <p className="raters">
                                          {raters.roleName}
                                        </p>
                                      );
                                    })}
                                  </p>
                                  <p>
                                    <p
                                      style={{
                                        color:
                                          tableRow.average < tableRow.target
                                            ? "#F05751"
                                            : "black"
                                      }}
                                      className="rater-average"
                                    >
                                      {tableRow.average}
                                    </p>
                                    {tableRow.raters.map(
                                      (raters, indexxxxx) => {
                                        return (
                                          <p
                                            className="raters"
                                            style={{
                                              color:
                                                raters.averageRating <
                                                tableRow.target
                                                  ? "#F05751"
                                                  : "black"
                                            }}
                                          >
                                            {raters.averageRating}
                                          </p>
                                        );
                                      }
                                    )}
                                  </p>
                                </div>
                                <div className="table-right">
                                  <div
                                    className="dotted-below-target"
                                    style={{
                                      width: `calc( ${tableRow.target}/
                                         ${resultDetails.maxScore+1} *
                                        100%)`
                                    }}
                                  />
                                  <p
                                    className="logo-rater-average"
                                    style={{
                                      left: `calc( ${tableRow.selfRater.score}/
                                         ${resultDetails.maxScore+1} *
                                        100% )`,
                                      marginLeft: "-5px"
                                    }}
                                  >
                                    {" "}
                                  </p>
                                  <p
                                    className="graph"
                                    style={{
                                      // position: "relative",
                                      backgroundColor:
                                        tableRow.average >= tableRow.target
                                          ? "#91BE5E"
                                          : "#F05751",

                                      width: `calc( ${tableRow.average}/
                                         ${resultDetails.maxScore+1} *
                                        100%)`
                                    }}
                                  >
                                    {/* {tableRow.average} */}
                                  </p>
                                  {tableRow.raters.map((raters, indexxxx) => {
                                    return (
                                      <p
                                        className="graph"
                                        style={{
                                          backgroundColor:
                                            raters.averageRating >=
                                            tableRow.target
                                              ? "#91BE5E"
                                              : "#F05751",
                                          // width:
                                          //   (raters.averageRating /
                                          //     dataTest.maxScale) *
                                          //   0.5
                                          width: `calc( ${raters.averageRating}/
                                         ${resultDetails.maxScore+1} *
                                        100%)`
                                        }}
                                      >
                                        {/* {raters.averageRating} */}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        )}{" "}
                    </div>
                  </div>
                  {/* targetArray=scoretarget
                averageTarget=averagescore

                scoreArray=selfrating */}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="card-comments">
                  <div className="top">
                    <h4>Remarks/Comments from :</h4>
                  </div>
                  <div className="bottom">
                    {resultDetails.comments.map((comment, index) => {
                      return (
                        <div className="comments-container">
                          <h5>
                            {comment.roleName}{" "}
                            {comment.roleName === "Boss" && comment.name}
                          </h5>
                          <h6>{comment.verbatimStrength}</h6>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Fragment>
            ))}
        </Card>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    resultDetails: state.participantReducer.resultsDetails,
    selectProj: state.participantReducer.results
  };
};

const mapDispatchToProps = dispatch => ({
  alertStatus: data => dispatch({ type: actions.TOGGLE_ALERT, data })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithInterceptor(ParticipantResultChartsPage));
