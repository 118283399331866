const base = {
    host: process.env.REACT_APP_API_ENDPOINT,
};

const nha_url = base.host + "/nha-service/v1/";

const templates_URL = {
    PARTICIPANT: nha_url + "file/public/download/template?fileType=ParticipantTemplate",
    COMPETENCY: nha_url + "file/public/download/template?fileType=CompentencyTemplate",
};

const api_URL = {
    /* ADMIN */
    ADMIN_LOGIN: nha_url + "admin/resource/login",
    ADMIN_PROFILE: nha_url + "admin/getProfile",
    ADMIN_UPDATE_PROFILE: nha_url + "admin/setting/update",
    ADMIN_GET_UPDATED_PROFILE: nha_url + "admin/setting/get",
    ADMIN_PROJECT_LIST: nha_url + "admin/project/pages",
    ADMIN_PROJECT_LIST_COMPLETED: nha_url + "admin/project/completed-pages",
    ADMIN_PIN_PROJ: nha_url + "admin/project/pin-or-unpin",
    ADMIN_CREATE_PROJ: nha_url + "admin/project/create",
    ADMIN_UPLOAD_PROJ_IMG: nha_url + "file/upload",
    ADMIN_GET_PROJ_IMG_URL: nha_url + "file/view",
    ADMIN_EDIT_PROJ: nha_url + "admin/project/update",

    /* USER */
    LOGIN: nha_url + "user/resource/login",
    PROFILE: nha_url + "user/getProfile",
    REQUEST_RESET_PASSWORD: nha_url + "user/resource/sendPasswordResetEmail",
    RESET_PASSWORD: nha_url + "user/resource/resetPassword",
    SIGNUP: nha_url + "user/resource/resetPassword",
    CLIENT_DELETE: nha_url + "user/client/project",
    CLIENT_PROJ_LIST: nha_url + "user/client/project/pages",
    CLIENT_PROJ_RESULT_GAP: nha_url + "user/client/project/result/gap",
    CLIENT_PROJ_RESULT: nha_url + "user/client/project/result",
    CLIENT_PROJ_DETAILS: nha_url + "user/client/project",
    CLIENT_PAGINATED_PARTICIPANTS: nha_url + "user/client/project",
    CLIENT_UPLOAD_PARTICIPANT_FILE: nha_url + "user/client/project/upload-participants-xlsx",
    CLIENT_UPLOAD_COMPETENCY_FILE: nha_url + "user/client/project/upload-competency-xlsx",
    CLIENT_DISABLE: nha_url + "user/client/project/disable",
    CLIENT_PUBLISH: nha_url + "user/client/project/publish",
    CLIENT_START: nha_url + "user/client/project/start",
    CLIENT_SAVE_DRAFT: nha_url + "user/client/project/saveAsDraft",
    CLIENT_CLEAN_EXCEL: nha_url + "user/client/project/delete/participant-and-competency",
    FIRST_TIME_LOGIN: nha_url + "user/setFirstLoginFalse",
    FIRST_TIME_UPDATE: nha_url + "user/participant/project/message",
    DROPDOWN_CATEGORY: nha_url + "config/raterRoles",
    /* PARTICIPANT */
    PARTI_PROJ_LIST: nha_url + "user/participant/project/pages",
    PARTI_PROJ_DETAILS: nha_url + "user/participant/project",
    PARTI_PROJ_RESULT: nha_url + "user/participant/project/result",
    PARTI_PROJ_SAVE_DRAFT: nha_url + "user/participant/project/saveAsDraft",
    PARTI_PROJ_SUBMIT: nha_url + "user/participant/project/submit",
    PARTI_PROJ_EXPORT: nha_url + "user/participant/project/result/export",
    /* RATER */
    RATER_PROJ_LIST: nha_url + "rater/pages",
    RATER_RATING_LIST: nha_url + "rater/rate",
    RATER_SAVE_AS_DRAFT: nha_url + "rater/rate/submit",
    RATER_SUBMIT: nha_url + "rater/rate/publish",
    RATER_PUBLIC_LIST: nha_url + "rater/public/rate",
    RATER_PUBLIC_SAVE_AS_DRAFT: nha_url + "rater/public/rate/submit",
    RATER_PUBLIC_SUBMIT: nha_url + "rater/public/rate/publish",
};

export { api_URL, templates_URL };
