import React from "react";
import { Card } from "reactstrap";
import { formatDate } from "../../../../common/helper/helpers";
import nomination_icon from "../../../../assets/icons/add-empty.png";
import "./projCard.scss";

const projStatusData = {
    rater: [
        { class: "new", val: "New" },
        { class: "continue", val: "Continue" },
        { class: "done", val: "Done" },
    ],
    participant: [
        { class: "new", val: "New" },
        { class: "ongoing", val: "On going" },
        { class: "completed", val: "Completed" },
    ],
};

export const ResultCard = ({ viewResult, addRaters, rateNow, role, ...props }) => {
    const rater = role === "rater";
    const emptyState = rater ? "Start to rate" : "Add-raters";
    const total = rater ? `${props.numberOfRequests} Requests` : `${props.totalRaters} Raters`;
    const status = props.projectStatus || 0;
    const logostatus = props.projectStatus === 0 ? (props.finishSetupRater !== 1 ? false : 1) : 1;
    const projStatus = projStatusData[role][status];

    // const navigateTo = () => {
    //   rater
    //     ? rateNow(props)
    //     : !props.projectStatus||
    //     ? addRaters(props)
    //     : viewResult(props);
    // };
    const navigateTo = () => {
        rater ? rateNow(props) : props.isOverallCompleted === 1 ? viewResult(props) : addRaters(props);
    };
    // projectStatus (0 = New, 1 = Ongoing, 2 = Completed)

    return (
        <Card className="proj-card" onClick={navigateTo}>
            <div className="proj-card-header">
                <div className="proj-content">
                    <div className="proj-name">{props.projectName}</div>
                    <div className="proj-raters">{total}</div>
                </div>
                {!props.isExpiring ? (
                    <div className={`proj-status ${projStatus.class}`}>{projStatus.val}</div>
                ) : (
                    <div className="expired-container">
                        <div className="expired-status">Continue</div>
                        <div className="expired-date">Rating expired Soon!</div>
                    </div>
                )}
            </div>
            {logostatus ? (
                <div className="proj-card-body">
                    {props.projectPercent === 100 ? (
                        <div className="result-button-container">
                            <div className="result-button">{role === "rater" ? "View" : "Result"}</div>
                        </div>
                    ) : null}
                    <div className="proj-bar-container">
                        <div className="bar" />
                        <div
                            className="bar-progress"
                            style={{
                                width: `${props.projectPercent}%`,
                            }}
                        >
                            <div className="bar-percent">{props.projectPercent}%</div>
                        </div>
                    </div>
                    <div className="proj-bar-content">
                        <div className="proj-content">
                            <p className="content-title">Rating Start</p>
                            <p className="content-date">{formatDate(props.startDate)}</p>
                        </div>
                        <div className="proj-content">
                            <p className="content-title">Rating End</p>
                            <p className="content-date">{formatDate(props.endDate)}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="proj-nomination">
                    <img src={nomination_icon} alt="nomination-icon" />
                    <p>{emptyState}</p>
                </div>
            )}
        </Card>
    );
};
