import React, { PureComponent } from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import "./pagination.scss";

export default class PaginationComponent extends PureComponent {
  render() {
    return (
      <div className="pagination-container">
        <Pagination
          onChange={page => this.props.onChange(page)}
          pageSize={this.props.pageSize}
          current={this.props.current}
          total={this.props.total}
          locale="en_US"
        />
      </div>
    );
  }
}
